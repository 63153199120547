import { Box, Grow, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import ButtonMapMenuClose from "assets/icons/btn_mapmenu_close.svg";
import IconFilter from "assets/icons/filter/ic_filter.svg";
import React from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import styles from "./EstateFilterDialog.module.scss";
import { BldConditionButtons } from "./components/BldConditionButtons";
import { CompletionYMSlider } from "./components/CompletionYMSlider";
import { DateRangePicker } from "./components/DateRangePicker";
import { EstatePriceSlider } from "./components/EstatePriceSlider";
import { OtherConditionButton } from "./components/OtherConditionButton";
import { PurposeSummaryBButton } from "./components/PurposeSummaryBButton";
import { RoomsSlider } from "./components/RoomsSlider";
import { TsuboAreaSlider } from "./components/TsuboAreaSlider";
import { useEstateFilterDialogHooks } from "./hooks";
import { EstateFilterDialogProps } from "./interfaces";

const idPrefix = "estate-filter-dialog";

export const EstateFilterDialog = React.memo(
  (props: EstateFilterDialogProps) => {
    const hooks = useEstateFilterDialogHooks(props);

    return (
      <Dialog
        open={props.open}
        aria-labelledby={`${idPrefix}-title`}
        aria-describedby={`${idPrefix}-description`}
        onClose={props.onClose}
        disableEscapeKeyDown
        slotProps={{ backdrop: { className: styles.backdrop } }}
        PaperProps={{ onClick: hooks.onBackPaperClick }}
        classes={{
          paperScrollPaper: styles.paperScrollPaper,
          paper: styles.paper,
          container: styles.container
        }}
        TransitionComponent={Grow}
        transitionDuration={{ appear: 200, enter: 200, exit: 100 }}
      >
        <Box className={`${styles.popup_map} twbasic01 ${styles.on}`}>
          <Box className={styles.scroll}>
            <Box className={styles.tit} ref={hooks.titRef}>
              <Box component="span">
                <img src={IconFilter} alt="フィルタ" />
              </Box>
              <Box className={styles.btn_sub_close} onClick={props.onClose}>
                <img src={ButtonMapMenuClose} alt="閉じる" />
                <Typography component="b">閉じる</Typography>
              </Box>
            </Box>
            <Box className={styles.box} ref={hooks.boxRef}>
              <Box className={styles.box2}>
                <Box className={styles.ovfl}>
                  <Box className={styles.popup_map_filter}>
                    <Box className={styles.ctt}>
                      <Box
                        className={`${styles.func_v2} ${styles.slider_v2} ${
                          styles.price
                        } ${
                          isMobileOnly
                            ? styles.isMobileOnly
                            : isMobile
                            ? styles.isMobile
                            : ""
                        }`}
                      >
                        <EstatePriceSlider
                          sliderTheme={hooks.sliderTheme}
                          id="price-slider"
                          valueFrom={hooks.filterConditions.estatePriceFrom}
                          valueTo={hooks.filterConditions.estatePriceTo}
                          formattedEstatePriceFrom={
                            hooks.formattedEstatePriceFrom
                          }
                          formattedEstatePriceTo={hooks.formattedEstatePriceTo}
                          onChange={hooks.onEstatePriceChange}
                        />
                      </Box>
                      <Box
                        className={`${styles.func_v2} ${styles.slider_v2} ${
                          styles.size
                        } ${
                          isMobileOnly
                            ? styles.isMobileOnly
                            : isMobile
                            ? styles.isMobile
                            : ""
                        }`}
                      >
                        <TsuboAreaSlider
                          sliderTheme={hooks.sliderTheme}
                          id="tsubo-area-slider"
                          valueFrom={hooks.tsuboAreaFromIndex}
                          valueTo={hooks.tsuboAreaToIndex}
                          formattedTsuboAreaFrom={hooks.formattedTsuboAreaFrom}
                          formattedTsuboAreaTo={hooks.formattedTsuboAreaTo}
                          onChange={hooks.onTsuboAreaChange}
                        />
                      </Box>
                      <Box
                        className={`${styles.func_v2} ${styles.slider_v2} ${
                          styles.age
                        } ${
                          isMobileOnly
                            ? styles.isMobileOnly
                            : isMobile
                            ? styles.isMobile
                            : ""
                        }`}
                      >
                        <CompletionYMSlider
                          sliderTheme={hooks.sliderTheme}
                          id="completion-ym-slider"
                          valueFrom={hooks.completionYMFromIndex}
                          valueTo={hooks.completionYMToIndex}
                          formattedCompletionYMFrom={
                            hooks.formattedCompletionYMFrom
                          }
                          formattedCompletionYMTo={
                            hooks.formattedCompletionYMTo
                          }
                          onChange={hooks.onCompletionYMIndexChange}
                        />
                      </Box>
                      <Box
                        className={`${styles.func_v2} ${styles.slider_v2} ${
                          styles.size
                        } ${
                          isMobileOnly
                            ? styles.isMobileOnly
                            : isMobile
                            ? styles.isMobile
                            : ""
                        }`}
                      >
                        <RoomsSlider
                          sliderTheme={hooks.sliderTheme}
                          id="rooms-slider"
                          valueFrom={hooks.roomsFromIndex}
                          valueTo={hooks.roomsToIndex}
                          formattedRoomsFrom={hooks.formattedRoomsFrom}
                          formattedRoomsTo={hooks.formattedRoomsTo}
                          onChange={hooks.onRoomsChange}
                        />
                      </Box>
                    </Box>
                    <Box className={styles.ctt}>
                      <Box
                        className={`${styles.func} ${styles.select} ${styles.rule}`}
                      >
                        <Box className={styles.tit}>
                          <Box component="span">建築条件</Box>
                        </Box>
                        <Box className={styles.dtl}>
                          <Box className={styles.setting}>
                            <BldConditionButtons
                              bldConditionSelectionsMaster={
                                hooks.bldConditionSelections
                              }
                              bldConditionSelections={
                                hooks.filterConditions.bldConditionSelections
                              }
                              onBldConditionSelectionsChange={
                                hooks.onBldConditionSelectionsChange
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={styles.ctt}>
                      <Box
                        className={`${styles.func} ${styles.select} ${styles.other}`}
                      >
                        <Box className={styles.tit}>
                          <Box component="span">条件選択</Box>
                        </Box>
                        <Box className={styles.dtl}>
                          <Box className={styles.setting}>
                            <OtherConditionButton
                              petOk={hooks.filterConditions.petOk}
                              flat={hooks.filterConditions.flat}
                              myCompanyOnly={
                                hooks.filterConditions.companyRecNo !== 0
                              }
                              onPetOkChange={hooks.onPetOkChange}
                              onFlatChange={hooks.onFlatChange}
                              onMyCompanyOnlyChange={
                                hooks.onMyCompanyOnlyChange
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={styles.ctt}>
                      <Box
                        className={`${styles.func} ${styles.select} ${styles.other}`}
                      >
                        <Box className={styles.tit}>
                          <Box component="span">事業用</Box>
                        </Box>
                        <Box className={styles.dtl}>
                          <Box className={styles.setting}>
                            <PurposeSummaryBButton
                              purposeSummaryBsMaster={hooks.purposeSummaryBs}
                              purposeSummaryBs={
                                hooks.filterConditions.purposeSummaryBs
                              }
                              onPurposeSummaryBsChange={
                                hooks.onPurposeSummaryBsChange
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={styles.ctt}>
                      <Box
                        className={`${styles.func} ${styles.select} ${styles.other}`}
                      >
                        <Box className={styles.tit}>
                          <Box component="span">登録日</Box>
                        </Box>
                        <Box className={styles.dtl}>
                          <Box className={styles.setting}>
                            <DateRangePicker
                              dateFrom={
                                hooks.filterConditions.alsoCreatedAtFrom
                              }
                              dateTo={hooks.filterConditions.alsoCreatedAtTo}
                              onDateFromChange={hooks.onAlsoCreatedAtFromChange}
                              onDateToChange={hooks.onAlsoCreatedAtToChange}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={styles.ctt}>
                      <Box
                        className={`${styles.func} ${styles.select} ${styles.other}`}
                      >
                        <Box className={styles.tit}>
                          <Box component="span">価格変更日</Box>
                        </Box>
                        <Box className={styles.dtl}>
                          <Box className={styles.setting}>
                            <DateRangePicker
                              dateFrom={hooks.filterConditions.alsoPricedAtFrom}
                              dateTo={hooks.filterConditions.alsoPricedAtTo}
                              onDateFromChange={hooks.onAlsoPricedAtFromChange}
                              onDateToChange={hooks.onAlsoPricedAtToChange}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={`${styles.popup_map_btn} ${styles.btn_3ko}`}>
                <Box
                  className={`${styles.btn} ${styles.cancel}`}
                  onClick={props.onClose}
                >
                  閉じる
                </Box>
                <Box
                  className={`${styles.btn} ${styles.reset} ${
                    hooks.resetButtonOn ? styles.on : styles.off
                  }`}
                  onClick={hooks.onResetButtonClick}
                >
                  リセット
                </Box>
                <Box
                  className={`${styles.btn} ${styles.arrow} ${
                    hooks.okButtonOn ? styles.on : styles.off
                  }`}
                  onClick={hooks.onOkButtonClick}
                >
                  絞り込む
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    );
  }
);
