import { createTheme } from "@mui/material";
import {
  completionYMMarks,
  completionYMs,
  dateMax,
  dateMin,
  estatePriceMax,
  estatePriceMin,
  roomsMarks,
  roomsMax,
  roomsMin,
  tsuboAreaMarks,
  tsuboAreaMax,
  tsuboAreaMin
} from "common/consts/estates";
import { useAutoReducer } from "common/functions/reducers";
import { BldConditionSelection } from "common/interfaces/BldConditionSelection";
import { EstateFilterConditions } from "common/interfaces/EstateFilterConditions";
import { PurposeSummaryB } from "common/interfaces/PurposeSummaryB";
import { useJsonMasterHooks } from "hooks/jsonMasterHooks";
import { MouseEvent, useCallback, useEffect, useMemo, useRef } from "react";
import { EstateFilterDialogProps } from "./interfaces";

export const useEstateFilterDialogHooks = (props: EstateFilterDialogProps) => {
  const sliderTheme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          thumb: {
            width: "24px",
            height: "24px",
            boxShadow: "0 3px 6px rgba(0, 0, 0, 0.2)",
            border: "4px solid black",
            backgroundColor: "white"
          },

          rail: {
            border: "4px solid #ededed"
          },

          track: {
            border: "4px solid rgb(255, 240, 0)",
            boxShadow: "0 3px 6px rgba(0, 0, 0, 0.2)"
          },

          mark: {
            color: "#aaaaaa",
            width: "3px",
            height: "3px",
            borderRadius: "1.5px"
          },

          markLabel: {
            fontFamily: "din-2014, zen-maru-gothic, sans-serif",
            opacity: 0.8,
            color: "rgba(125, 125, 125, 1)",
            fontSize: "0.75rem",
            fontWeight: "400",
            letterSpacing: "0.05em"
          },

          markActive: {
            backgroundColor: "#a49b0a",
            width: "3px",
            height: "3px",
            borderRadius: "1.5px"
          },

          valueLabel: {
            fontFamily: "din-2014, zen-maru-gothic, sans-serif",
            fontWeight: "700",
            padding: "0.35em 0.5em 0.3em",
            lineHeight: 1,
            color: "white",
            backgroundColor: "black",
            borderRadius: "6px"
          }
        }
      }
    }
  });

  const titRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  const [filterConditions, , forceFilterConditions, setFilterConditions] =
    useAutoReducer<EstateFilterConditions>(props.filterConditions);

  const { jsonMasters: bldConditionSelections } =
    useJsonMasterHooks<BldConditionSelection>(
      "bld_condition_selections",
      props.setFatal
    );

  const { jsonMasters: purposeSummaryBs } = useJsonMasterHooks<PurposeSummaryB>(
    "purpose_summary_bs",
    props.setFatal
  );

  const formattedEstatePriceFrom = useMemo(
    () =>
      filterConditions.estatePriceFrom === estatePriceMin
        ? "下限なし"
        : filterConditions.estatePriceFrom.toLocaleString(),
    [filterConditions.estatePriceFrom]
  );

  const formattedEstatePriceTo = useMemo(
    () =>
      filterConditions.estatePriceTo === estatePriceMax
        ? "上限なし"
        : filterConditions.estatePriceTo.toLocaleString(),
    [filterConditions.estatePriceTo]
  );

  const { tsuboAreaFromIndex, tsuboAreaToIndex } = useMemo(
    () =>
      ({
        tsuboAreaFromIndex: tsuboAreaMarks.findIndex(
          (x) => x.value === filterConditions.tsuboAreaFrom
        ),
        tsuboAreaToIndex: tsuboAreaMarks.findIndex(
          (x) => x.value === filterConditions.tsuboAreaTo
        )
      } as const),
    [filterConditions.tsuboAreaFrom, filterConditions.tsuboAreaTo]
  );

  const formattedTsuboAreaFrom = useMemo(
    () =>
      filterConditions.tsuboAreaFrom === tsuboAreaMin
        ? "下限なし"
        : filterConditions.tsuboAreaFrom.toLocaleString(),
    [filterConditions.tsuboAreaFrom]
  );

  const formattedTsuboAreaTo = useMemo(
    () =>
      filterConditions.tsuboAreaTo === tsuboAreaMax
        ? "上限なし"
        : filterConditions.tsuboAreaTo.toLocaleString(),
    [filterConditions.tsuboAreaTo]
  );

  const { completionYMFromIndex, completionYMToIndex } = useMemo(
    () =>
      ({
        completionYMFromIndex: completionYMs.findIndex(
          (x) => x === filterConditions.completionYMTo
        ),
        completionYMToIndex: completionYMs.findIndex(
          (x) => x === filterConditions.completionYMFrom
        )
      } as const),
    [filterConditions.completionYMFrom, filterConditions.completionYMTo]
  );

  const formattedCompletionYMFrom = useMemo(
    () =>
      completionYMFromIndex === 0
        ? "新築"
        : completionYMMarks.at(completionYMFromIndex)?.label ?? "",
    [completionYMFromIndex]
  );

  const formattedCompletionYMTo = useMemo(
    () =>
      completionYMToIndex === completionYMs.length - 1
        ? "築年指定なし"
        : completionYMMarks.at(completionYMToIndex)?.label ?? "",
    [completionYMToIndex]
  );

  const { roomsFromIndex, roomsToIndex } = useMemo(
    () =>
      ({
        roomsFromIndex: roomsMarks.findIndex(
          (x) => x.value === filterConditions.roomsFrom
        ),
        roomsToIndex: roomsMarks.findIndex(
          (x) => x.value === filterConditions.roomsTo
        )
      } as const),
    [filterConditions.roomsFrom, filterConditions.roomsTo]
  );

  const formattedRoomsFrom = useMemo(
    () =>
      filterConditions.roomsFrom === roomsMin
        ? "下限なし"
        : filterConditions.roomsFrom.toLocaleString(),
    [filterConditions.roomsFrom]
  );

  const formattedRoomsTo = useMemo(
    () =>
      filterConditions.roomsTo === roomsMax
        ? "上限なし"
        : filterConditions.roomsTo.toLocaleString(),
    [filterConditions.roomsTo]
  );

  const resetButtonOn = useMemo(
    () =>
      filterConditions.estatePriceFrom !== estatePriceMin ||
      filterConditions.estatePriceTo !== estatePriceMax ||
      filterConditions.tsuboAreaFrom !== tsuboAreaMin ||
      filterConditions.tsuboAreaTo !== tsuboAreaMax ||
      filterConditions.completionYMFrom !==
        completionYMs[completionYMs.length - 1] ||
      filterConditions.completionYMTo !== completionYMs[0] ||
      (filterConditions.bldConditionSelections.length ===
        props.defaultFilterConditions.bldConditionSelections.length &&
        props.defaultFilterConditions.bldConditionSelections
          .map((x) => x.id)
          .every((x) =>
            filterConditions.bldConditionSelections.map((x) => x.id).includes(x)
          )) === false ||
      filterConditions.petOk !== false ||
      filterConditions.flat !== false ||
      filterConditions.companyRecNo !== 0 ||
      filterConditions.purposeSummaryBs.length !== purposeSummaryBs.length ||
      filterConditions.alsoCreatedAtFrom !== dateMin ||
      filterConditions.alsoCreatedAtTo !== dateMax ||
      filterConditions.alsoPricedAtFrom !== dateMin ||
      filterConditions.alsoPricedAtTo !== dateMax ||
      filterConditions.roomsFrom !== roomsMin ||
      filterConditions.roomsTo !== roomsMax,
    [
      filterConditions.alsoCreatedAtFrom,
      filterConditions.alsoCreatedAtTo,
      filterConditions.alsoPricedAtFrom,
      filterConditions.alsoPricedAtTo,
      filterConditions.bldConditionSelections,
      filterConditions.companyRecNo,
      filterConditions.completionYMFrom,
      filterConditions.completionYMTo,
      filterConditions.estatePriceFrom,
      filterConditions.estatePriceTo,
      filterConditions.flat,
      filterConditions.petOk,
      filterConditions.purposeSummaryBs.length,
      filterConditions.roomsFrom,
      filterConditions.roomsTo,
      filterConditions.tsuboAreaFrom,
      filterConditions.tsuboAreaTo,
      props.defaultFilterConditions.bldConditionSelections,
      purposeSummaryBs.length
    ]
  );

  const okButtonOn = useMemo(
    () =>
      filterConditions.estatePriceFrom !==
        props.filterConditions.estatePriceFrom ||
      filterConditions.estatePriceTo !== props.filterConditions.estatePriceTo ||
      filterConditions.tsuboAreaFrom !== props.filterConditions.tsuboAreaFrom ||
      filterConditions.tsuboAreaTo !== props.filterConditions.tsuboAreaTo ||
      filterConditions.completionYMFrom !==
        props.filterConditions.completionYMFrom ||
      filterConditions.completionYMTo !==
        props.filterConditions.completionYMTo ||
      filterConditions.bldConditionSelections.length !==
        props.filterConditions.bldConditionSelections.length ||
      filterConditions.petOk !== props.filterConditions.petOk ||
      filterConditions.flat !== props.filterConditions.flat ||
      filterConditions.companyRecNo !== props.filterConditions.companyRecNo ||
      filterConditions.purposeSummaryBs.length !==
        props.filterConditions.purposeSummaryBs.length ||
      filterConditions.alsoCreatedAtFrom !==
        props.filterConditions.alsoCreatedAtFrom ||
      filterConditions.alsoCreatedAtTo !==
        props.filterConditions.alsoCreatedAtTo ||
      filterConditions.alsoPricedAtFrom !==
        props.filterConditions.alsoPricedAtFrom ||
      filterConditions.alsoPricedAtTo !== props.filterConditions.alsoPricedAtTo,
    [
      filterConditions.alsoCreatedAtFrom,
      filterConditions.alsoCreatedAtTo,
      filterConditions.alsoPricedAtFrom,
      filterConditions.alsoPricedAtTo,
      filterConditions.bldConditionSelections.length,
      filterConditions.companyRecNo,
      filterConditions.completionYMFrom,
      filterConditions.completionYMTo,
      filterConditions.estatePriceFrom,
      filterConditions.estatePriceTo,
      filterConditions.flat,
      filterConditions.petOk,
      filterConditions.purposeSummaryBs.length,
      filterConditions.tsuboAreaFrom,
      filterConditions.tsuboAreaTo,
      props.filterConditions.alsoCreatedAtFrom,
      props.filterConditions.alsoCreatedAtTo,
      props.filterConditions.alsoPricedAtFrom,
      props.filterConditions.alsoPricedAtTo,
      props.filterConditions.bldConditionSelections.length,
      props.filterConditions.companyRecNo,
      props.filterConditions.completionYMFrom,
      props.filterConditions.completionYMTo,
      props.filterConditions.estatePriceFrom,
      props.filterConditions.estatePriceTo,
      props.filterConditions.flat,
      props.filterConditions.petOk,
      props.filterConditions.purposeSummaryBs.length,
      props.filterConditions.tsuboAreaFrom,
      props.filterConditions.tsuboAreaTo
    ]
  );

  const onEstatePriceChange: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void = useCallback(
    (_1, value, _2) => {
      const estatePrice = value as number[];
      setFilterConditions.estatePriceFrom(estatePrice[0]);
      setFilterConditions.estatePriceTo(estatePrice[1]);
    },
    [setFilterConditions]
  );

  const onTsuboAreaChange: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void = useCallback(
    (_1, value, _2) => {
      const tsuboAreaIndices = value as number[];

      setFilterConditions.tsuboAreaFrom(
        tsuboAreaMarks[tsuboAreaIndices[0]].value
      );
      setFilterConditions.tsuboAreaTo(
        tsuboAreaMarks[tsuboAreaIndices[1]].value
      );
    },
    [setFilterConditions]
  );

  const onCompletionYMIndexChange: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void = useCallback(
    (_1, value, _2) => {
      const completionYMIndices = value as number[];

      setFilterConditions.completionYMFrom(
        completionYMs[completionYMIndices[1]]
      );
      setFilterConditions.completionYMTo(completionYMs[completionYMIndices[0]]);
    },
    [setFilterConditions]
  );

  const onRoomsChange: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void = useCallback(
    (_1, value, _2) => {
      const roomsIndices = value as number[];

      setFilterConditions.roomsFrom(roomsMarks[roomsIndices[0]].value);
      setFilterConditions.roomsTo(roomsMarks[roomsIndices[1]].value);
    },
    [setFilterConditions]
  );

  const onBldConditionSelectionsChange = useCallback(
    (values: BldConditionSelection[]) => {
      setFilterConditions.bldConditionSelections(values);
    },
    [setFilterConditions]
  );

  const onPetOkChange: (checked: boolean) => void = useCallback(
    (checked) => {
      setFilterConditions.petOk(checked);
    },
    [setFilterConditions]
  );

  const onFlatChange: (checked: boolean) => void = useCallback(
    (checked) => {
      setFilterConditions.flat(checked);
    },
    [setFilterConditions]
  );

  const onMyCompanyOnlyChange: (checked: boolean) => void = useCallback(
    (checked) => {
      setFilterConditions.companyRecNo(
        checked && props.companyRecNo ? props.companyRecNo : 0
      );
    },
    [props.companyRecNo, setFilterConditions]
  );

  const onPurposeSummaryBsChange: (
    purposeSummaryBs: PurposeSummaryB[]
  ) => void = useCallback(
    (purposeSummaryBs) => {
      setFilterConditions.purposeSummaryBs(purposeSummaryBs);
    },
    [setFilterConditions]
  );

  const onAlsoCreatedAtFromChange = useCallback(
    (value: number | null | undefined) => {
      setFilterConditions.alsoCreatedAtFrom(value ?? dateMin);
    },
    [setFilterConditions]
  );

  const onAlsoCreatedAtToChange = useCallback(
    (value: number | null | undefined) => {
      setFilterConditions.alsoCreatedAtTo(value ?? dateMax);
    },
    [setFilterConditions]
  );

  const onAlsoPricedAtFromChange = useCallback(
    (value: number | null | undefined) => {
      setFilterConditions.alsoPricedAtFrom(value ?? dateMin);
    },
    [setFilterConditions]
  );

  const onAlsoPricedAtToChange = useCallback(
    (value: number | null | undefined) => {
      setFilterConditions.alsoPricedAtTo(value ?? dateMax);
    },
    [setFilterConditions]
  );

  const onResetButtonClick = useCallback(() => {
    forceFilterConditions({
      ...props.filterConditions,
      estatePriceFrom: estatePriceMin,
      estatePriceTo: estatePriceMax,
      tsuboAreaFrom: tsuboAreaMin,
      tsuboAreaTo: tsuboAreaMax,
      completionYMFrom: completionYMs[completionYMs.length - 1],
      completionYMTo: completionYMs[0],
      bldConditionSelections:
        props.defaultFilterConditions.bldConditionSelections,
      petOk: false,
      flat: false,
      companyRecNo: 0,
      purposeSummaryBs: purposeSummaryBs,
      alsoCreatedAtFrom: dateMin,
      alsoCreatedAtTo: dateMax,
      alsoPricedAtFrom: dateMin,
      alsoPricedAtTo: dateMax,
      roomsFrom: roomsMin,
      roomsTo: roomsMax
    });
  }, [
    forceFilterConditions,
    props.defaultFilterConditions.bldConditionSelections,
    props.filterConditions,
    purposeSummaryBs
  ]);

  const onOkButtonClick = useCallback(() => {
    props.onOkButtonClick(filterConditions);
    if (props.onClose) {
      props.onClose();
    }
  }, [filterConditions, props]);

  const onBackPaperClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (titRef.current && boxRef.current) {
        if (
          titRef.current.contains(event.target as Node) ||
          boxRef.current.contains(event.target as Node)
        ) {
          return;
        }

        if (props.onClose) {
          props.onClose();
        }
      }
    },
    [props]
  );

  useEffect(() => {
    forceFilterConditions(props.filterConditions);
  }, [forceFilterConditions, props.filterConditions, props.open]);

  return {
    sliderTheme,

    titRef,
    boxRef,

    filterConditions,
    bldConditionSelections,
    purposeSummaryBs,

    formattedEstatePriceFrom,
    formattedEstatePriceTo,

    formattedTsuboAreaFrom,
    formattedTsuboAreaTo,

    tsuboAreaFromIndex,
    tsuboAreaToIndex,

    formattedCompletionYMFrom,
    formattedCompletionYMTo,

    completionYMFromIndex,
    completionYMToIndex,

    formattedRoomsFrom,
    formattedRoomsTo,

    roomsFromIndex,
    roomsToIndex,

    resetButtonOn,
    okButtonOn,

    onEstatePriceChange,

    onTsuboAreaChange,

    onCompletionYMIndexChange,

    onRoomsChange,

    onBldConditionSelectionsChange,

    onPetOkChange,
    onFlatChange,
    onMyCompanyOnlyChange,
    onPurposeSummaryBsChange,

    onAlsoCreatedAtFromChange,
    onAlsoCreatedAtToChange,
    onAlsoPricedAtFromChange,
    onAlsoPricedAtToChange,

    onResetButtonClick,
    onOkButtonClick,
    onBackPaperClick
  } as const;
};

export type UseEstateFilterDialogHooksType = ReturnType<
  typeof useEstateFilterDialogHooks
>;
