import { Box } from "@mui/material";
import { memo, useCallback } from "react";
import styles from "../SearchMenu.module.scss";

export const OftenMenuButtonWithoutInput = memo(
  (props: {
    title: string;
    imgSrc: string;
    additionalClassName: string;
    onClick: () => void;
  }) => {
    const onClick = useCallback(() => {
      if (props.onClick) {
        props.onClick();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onClick]);

    return (
      <Box
        component="div"
        className={`${styles.btn} ${props.additionalClassName}`}
      >
        <Box component="figure" onClick={onClick}>
          <Box component="img" src={props.imgSrc} alt={props.title} />
        </Box>
      </Box>
    );
  }
);
