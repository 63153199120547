import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import { dateMax, dateMin } from "common/consts/estates";
import React, { useCallback, useMemo } from "react";
import { DatePickerDialog } from "../../DatePickerDialog";
import styles from "../EstateFilterDialog.module.scss";

export const DateRangePicker = React.memo(
  (props: {
    dateFrom: number | null | undefined;
    dateTo: number | null | undefined;
    onDateFromChange: (value: number | null | undefined) => void;
    onDateToChange: (value: number | null | undefined) => void;
  }) => {
    const [openStartDateDialog, setOpenStartDateDialog] = React.useState(false);
    const [openEndDateDialog, setOpenEndDateDialog] = React.useState(false);

    const startDate = useMemo(
      () =>
        props.dateFrom && props.dateFrom !== dateMin
          ? new Date(props.dateFrom)
          : undefined,
      [props.dateFrom]
    );

    const endDate = useMemo(
      () =>
        props.dateTo && props.dateTo !== dateMax
          ? new Date(props.dateTo)
          : undefined,
      [props.dateTo]
    );

    const startDateString = useMemo(
      () => (startDate ? startDate.toLocaleDateString() : "指定なし"),
      [startDate]
    );

    const endDateString = useMemo(
      () => (endDate ? endDate.toLocaleDateString() : "指定なし"),
      [endDate]
    );

    const onStartDateClick = useCallback(
      () => setOpenStartDateDialog(true),
      []
    );

    const onEndDateClick = useCallback(() => setOpenEndDateDialog(true), []);

    const onStartDateChange = useCallback(
      (value: number | null | undefined) => {
        props.onDateFromChange(value);
        setOpenStartDateDialog(false);
      },
      [props]
    );

    const onClearStartDate = useCallback(() => {
      props.onDateFromChange(undefined);
    }, [props]);

    const onStartDateDialogClose = useCallback(
      () => setOpenStartDateDialog(false),
      []
    );

    const onEndDateChange = useCallback(
      (value: number | null | undefined) => {
        props.onDateToChange(value);
        setOpenEndDateDialog(false);
      },
      [props]
    );

    const onClearEndDate = useCallback(() => {
      props.onDateToChange(undefined);
    }, [props]);

    const onEndDateDialogClose = useCallback(
      () => setOpenEndDateDialog(false),
      []
    );

    return (
      <>
        <Box
          className={`${styles.dateButton} ${styles.btn_switch01} ${
            styles.click_act01
          } ${startDate ? styles.on : styles.off}`}
          onClick={onStartDateClick}
        >
          {startDateString}
        </Box>
        <Box
          className={`${styles.clearButton} ${styles.btn_switch01} ${
            styles.click_act01
          } ${startDate ? styles.on : styles.off}`}
          onClick={onClearStartDate}
        >
          <Close />
        </Box>
        <Box className={styles.between}>〜</Box>
        <Box
          className={`${styles.dateButton} ${styles.btn_switch01} ${
            styles.click_act01
          } ${endDate ? styles.on : styles.off}`}
          onClick={onEndDateClick}
        >
          {endDateString}
        </Box>
        <Box
          className={`${styles.clearButton} ${styles.btn_switch01} ${
            styles.click_act01
          } ${endDate ? styles.on : styles.off}`}
          onClick={onClearEndDate}
        >
          <Close />
        </Box>
        {openStartDateDialog && (
          <DatePickerDialog
            open={openStartDateDialog}
            date={startDate?.getTime()}
            onOkClick={onStartDateChange}
            onClose={onStartDateDialogClose}
            onCloseClick={onStartDateDialogClose}
          />
        )}
        {openEndDateDialog && (
          <DatePickerDialog
            open={openEndDateDialog}
            date={endDate?.getTime()}
            onOkClick={onEndDateChange}
            onClose={onEndDateDialogClose}
            onCloseClick={onEndDateDialogClose}
          />
        )}
      </>
    );
  }
);
