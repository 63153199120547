import { TileSize } from "common/consts/googleMaps";

export class HazardMapType {
  id = "";
  infowindow = new google.maps.InfoWindow();
  map: google.maps.Map | null = null;
  baseUrls: string[] = [];
  maxZoom: number;
  tileSize = new google.maps.Size(TileSize, TileSize);
  opacity = 0.3;

  constructor(
    id: string,
    map: google.maps.Map,
    baseUrls: string[],
    maxZoom: number,
    opacity: number = 0.3
  ) {
    this.id = id;
    this.map = map;
    this.baseUrls = baseUrls;
    this.maxZoom = maxZoom;
    this.opacity = opacity;
  }

  getTile = (coord: google.maps.Point, zoom: number, ownerDocument: any) => {
    const div = ownerDocument.createElement("div") as HTMLElement;

    let calcTileInfo = {
      zoom: zoom,
      x: coord.x,
      y: coord.y,
      width: this.tileSize.width,
      height: this.tileSize.height,
      positionX: 0,
      positionY: 0
    };

    if (zoom > this.maxZoom) {
      const zoomGap = zoom - this.maxZoom;
      const multiply = Math.pow(2, zoomGap);

      calcTileInfo.zoom = this.maxZoom;
      calcTileInfo.x = Math.trunc(coord.x / multiply);
      calcTileInfo.y = Math.trunc(coord.y / multiply);
      calcTileInfo.width = this.tileSize.width * multiply;
      calcTileInfo.height = this.tileSize.height * multiply;
      calcTileInfo.positionX = this.tileSize.width * (coord.x % multiply) * -1;
      calcTileInfo.positionY = this.tileSize.height * (coord.y % multiply) * -1;
    }

    // div.innerHTML = `${calcTileInfo.x}, ${calcTileInfo.y}`;
    div.style.width = `${this.tileSize.width}px`;
    div.style.height = `${this.tileSize.height}px`;

    for (const baseUrl of this.baseUrls) {
      const replacedBaseUrl = baseUrl
        .replace("{z}", `${calcTileInfo.zoom}`)
        .replace("{x}", `${calcTileInfo.x}`)
        .replace("{y}", `${calcTileInfo.y}`);

      const imgDiv = document.createElement("div");

      imgDiv.style.position = "absolute";
      imgDiv.style.width = `${this.tileSize.width}px`;
      imgDiv.style.height = `${this.tileSize.height}px`;
      imgDiv.style.backgroundImage = `url(${replacedBaseUrl})`;
      imgDiv.style.backgroundSize = `${calcTileInfo.width}px ${calcTileInfo.height}px`;
      imgDiv.style.backgroundPositionX = `${calcTileInfo.positionX}px`;
      imgDiv.style.backgroundPositionY = `${calcTileInfo.positionY}px`;
      imgDiv.style.opacity = this.opacity.toString();

      div.appendChild(imgDiv);
    }

    return div;
  };

  releaseTile = (tile: any) => {
    if (tile.parentElement) {
      tile.parentElement.removeChild(tile);
    }
  };
}
