/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type AtomicCounter = {
  __typename: "AtomicCounter",
  counterName: string,
  sequence: number,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type CognitoUserResponse = {
  __typename: "CognitoUserResponse",
  item?: CognitoUser | null,
  errorMessage?: string | null,
};

export type CognitoUser = {
  __typename: "CognitoUser",
  Username?: string | null,
  Attributes?:  Array<CognitoUserAttribute | null > | null,
  UserCreateDate?: string | null,
  UserLastModifiedDate?: string | null,
  Enabled?: boolean | null,
  UserStatus?: UserStatusEnum | null,
  MFAOptions?:  Array<CognitoUserMFAOption | null > | null,
  Groups?:  Array<CognitoGroup | null > | null,
  User?: User | null,
  Company?: Company | null,
};

export type CognitoUserAttribute = {
  __typename: "CognitoUserAttribute",
  Name?: string | null,
  Value?: string | null,
};

export enum UserStatusEnum {
  UNCONFIRMED = "UNCONFIRMED",
  CONFIRMED = "CONFIRMED",
  ARCHIVED = "ARCHIVED",
  COMPROMISED = "COMPROMISED",
  UNKNOWN = "UNKNOWN",
  RESET_REQUIRED = "RESET_REQUIRED",
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
}


export type CognitoUserMFAOption = {
  __typename: "CognitoUserMFAOption",
  DeliveryMedium?: DeliveryMediumEnum | null,
  AttributeName?: string | null,
};

export enum DeliveryMediumEnum {
  SMS = "SMS",
  EMAIL = "EMAIL",
}


export type CognitoGroup = {
  __typename: "CognitoGroup",
  GroupName?: string | null,
  UserPoolId?: string | null,
  Description?: string | null,
  RoleArn?: string | null,
  Precedence?: number | null,
  LastModifiedDate?: string | null,
  CreationDate?: string | null,
};

export type User = {
  __typename: "User",
  username: string,
  infoName: string,
  name?: string | null,
  nameRuby?: string | null,
  tel?: string | null,
  role?: string | null,
  agreement?: boolean | null,
  trial?: boolean | null,
  companyId?: string | null,
  defaultCondition?: DefaultCondition | null,
  group?: string | null,
  email?: string | null,
  searchInfo?: string | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  authenticatedAt?: string | null,
  companyName?: string | null,
  companyBranchName?: string | null,
};

export type DefaultCondition = {
  __typename: "DefaultCondition",
  estatePriceFrom?: number | null,
  estatePriceTo?: number | null,
  tsuboAreaFrom?: number | null,
  tsuboAreaTo?: number | null,
  completionYearFrom?: string | null,
  completionYearTo?: string | null,
  roomsFrom?: number | null,
  roomsTo?: number | null,
  bldConditionSelections?: Array< string | null > | null,
  petOk?: boolean | null,
  flat?: boolean | null,
  inMyCompany?: boolean | null,
  purposeSummaryBs?: Array< string | null > | null,
  alsoCreatedAtFrom?: number | null,
  alsoCreatedAtTo?: number | null,
};

export type Company = {
  __typename: "Company",
  id: string,
  infoName: string,
  startMark?: string | null,
  recNo?: number | null,
  name?: string | null,
  branchId?: number | null,
  branchName?: string | null,
  branchTel?: string | null,
  branchFax?: string | null,
  branchUrl?: string | null,
  branchAddress?: string | null,
  address?: string | null,
  tel?: string | null,
  fax?: string | null,
  url?: string | null,
  remarks?: string | null,
  license?: string | null,
  licenser?: string | null,
  licenseCount?: number | null,
  licenseNo?: string | null,
  guaranter?: string | null,
  job?: string | null,
  email?: string | null,
  logoImg?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  areaPrefs?: Array< string | null > | null,
  areas?: Array< string | null > | null,
  areaNames?: Array< string | null > | null,
  eAreas?: Array< string | null > | null,
  eAreaNames?: Array< string | null > | null,
  estateTypes?: Array< string | null > | null,
  bldConditionSelections?: Array< string | null > | null,
  remarks2?: string | null,
  searchInfo: string,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DefaultConditionInput = {
  estatePriceFrom?: number | null,
  estatePriceTo?: number | null,
  tsuboAreaFrom?: number | null,
  tsuboAreaTo?: number | null,
  completionYearFrom?: string | null,
  completionYearTo?: string | null,
  roomsFrom?: number | null,
  roomsTo?: number | null,
  bldConditionSelections?: Array< string | null > | null,
  petOk?: boolean | null,
  flat?: boolean | null,
  inMyCompany?: boolean | null,
  purposeSummaryBs?: Array< string | null > | null,
  alsoCreatedAtFrom?: number | null,
  alsoCreatedAtTo?: number | null,
};

export type UpdateCompanyInput = {
  id: string,
  infoName: string,
  email?: string | null,
  logoImg?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  areaPrefs?: Array< string | null > | null,
  areas?: Array< string | null > | null,
  areaNames?: Array< string | null > | null,
  eAreas?: Array< string | null > | null,
  eAreaNames?: Array< string | null > | null,
  estateTypes?: Array< string | null > | null,
  bldConditionSelections?: Array< string | null > | null,
  remarks2?: string | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type CompanyMutationResponse = {
  __typename: "CompanyMutationResponse",
  item?: Company | null,
  errorMessage?: string | null,
};

export type DeleteCompanyInput = {
  id: string,
  infoName: string,
};

export type CreateEstateInput = {
  id: string,
  infoName: string,
  condominiumId?: string | null,
  confirmed?: boolean | null,
  status?: string | null,
  estateType?: string | null,
  name?: string | null,
  fullAddress?: string | null,
  prefecture?: string | null,
  city?: string | null,
  area?: string | null,
  address?: string | null,
  mediationKind?: string | null,
  currentStatus?: string | null,
  currentStatusFree?: string | null,
  traffic?: string | null,
  eSchool?: Array< string | null > | null,
  jSchool?: Array< string | null > | null,
  remarks?: string | null,
  landAreaType?: string | null,
  squareArea?: number | null,
  tsuboArea?: number | null,
  estatePrice?: number | null,
  unitPrice?: number | null,
  pricedAt?: string | null,
  completionSchMonth?: number | null,
  completionMonth?: number | null,
  clearingType?: string | null,
  cleaningMonth?: number | null,
  bldConditionType?: string | null,
  landRights?: string | null,
  cityPlanningType?: string | null,
  useDistrict?: Array< string | null > | null,
  floorAreaRatio?: number | null,
  bcr?: number | null,
  roadType?: string | null,
  drivewayPay?: string | null,
  roadDirection?: string | null,
  roadWidth?: number | null,
  water?: string | null,
  sewer?: string | null,
  gas?: string | null,
  groundType?: Array< string | null > | null,
  groundTypeFree?: string | null,
  layout?: string | null,
  totalArea?: number | null,
  structure?: string | null,
  structureFree?: string | null,
  buildingFloors?: string | null,
  balconyArea?: number | null,
  managementType?: string | null,
  pet?: string | null,
  floor?: number | null,
  room?: string | null,
  managementFee?: number | null,
  repairCost?: number | null,
  otherCost?: number | null,
  parking?: string | null,
  carsInParkingN?: number | null,
  parkingTakeOver?: string | null,
  parkingTakeOverFree?: string | null,
  parkingMechOrPlane?: string | null,
  parkingHeight?: number | null,
  parkingFee?: number | null,
  purposeB?: string | null,
  purposeSummaryB?: string | null,
  companyId?: string | null,
  companyName?: string | null,
  companyAddress?: string | null,
  companyTel?: string | null,
  companyFax?: string | null,
  companyUrl?: string | null,
  companyLicense?: string | null,
  companyGuaranter?: string | null,
  subCompanyId?: string | null,
  subCompanyName?: string | null,
  subCompanyAddress?: string | null,
  subCompanyTel?: string | null,
  subCompanyFax?: string | null,
  subCompanyUrl?: string | null,
  subCompanyLicense?: string | null,
  subCompanyGuaranter?: string | null,
  subCompany2Id?: string | null,
  subCompany2Name?: string | null,
  subCompany2Address?: string | null,
  subCompany2Tel?: string | null,
  subCompany2Fax?: string | null,
  subCompany2Url?: string | null,
  subCompany2License?: string | null,
  subCompany2Guaranter?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  tileOnZoom12?: string | null,
  tileOnZoom14?: string | null,
  tileOnZoom16?: string | null,
  layoutImgs?: Array< string | null > | null,
  appearanceImgs?: Array< string | null > | null,
  mapImg?: string | null,
  waterPdf?: string | null,
  sewerPdf?: string | null,
  gasPdf?: string | null,
  cadastralMapPdf?: string | null,
  groundPdf?: string | null,
  url?: string | null,
  license?: string | null,
  recNo?: number | null,
  media?: string | null,
  location?: string | null,
  collectedAt?: string | null,
  floorStructure?: string | null,
  buildingCompany?: string | null,
  notFoundAt?: string | null,
  exactLocation?: number | null,
  nearlyLocation?: number | null,
  unsettledLocation?: number | null,
  isLot?: number | null,
  currentStatusB?: string | null,
  currentStatusG?: string | null,
  companyRecNo?: number | null,
  companyBranchId?: number | null,
  subCompanyRecNo?: number | null,
  completionMonthS?: string | null,
  condominiumName?: string | null,
  condominiumRecNo?: number | null,
  totalHouses?: number | null,
  manager?: string | null,
  seller?: string | null,
  estateUrl?: string | null,
  searchInfo: string,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type EstateMutationResponse = {
  __typename: "EstateMutationResponse",
  item?: Estate | null,
  errorMessage?: string | null,
};

export type Estate = {
  __typename: "Estate",
  id: string,
  infoName: string,
  condominiumId?: string | null,
  confirmed?: boolean | null,
  status?: string | null,
  estateType?: string | null,
  name?: string | null,
  fullAddress?: string | null,
  prefecture?: string | null,
  city?: string | null,
  area?: string | null,
  address?: string | null,
  mediationKind?: string | null,
  currentStatus?: string | null,
  currentStatusFree?: string | null,
  traffic?: string | null,
  eSchool?: Array< string | null > | null,
  jSchool?: Array< string | null > | null,
  remarks?: string | null,
  landAreaType?: string | null,
  squareArea?: number | null,
  tsuboArea?: number | null,
  estatePrice?: number | null,
  unitPrice?: number | null,
  pricedAt?: string | null,
  priceHistory?:  Array<Price | null > | null,
  completionSchMonth?: number | null,
  completionMonth?: number | null,
  clearingType?: string | null,
  cleaningMonth?: number | null,
  bldConditionType?: string | null,
  landRights?: string | null,
  cityPlanningType?: string | null,
  useDistrict?: Array< string | null > | null,
  floorAreaRatio?: number | null,
  bcr?: number | null,
  roadType?: string | null,
  drivewayPay?: string | null,
  roadDirection?: string | null,
  roadWidth?: number | null,
  water?: string | null,
  sewer?: string | null,
  gas?: string | null,
  groundType?: Array< string | null > | null,
  groundTypeFree?: string | null,
  layout?: string | null,
  totalArea?: number | null,
  structure?: string | null,
  structureFree?: string | null,
  buildingFloors?: string | null,
  balconyArea?: number | null,
  managementType?: string | null,
  pet?: string | null,
  floor?: number | null,
  room?: string | null,
  managementFee?: number | null,
  repairCost?: number | null,
  otherCost?: number | null,
  parking?: string | null,
  carsInParkingN?: number | null,
  parkingTakeOver?: string | null,
  parkingTakeOverFree?: string | null,
  parkingMechOrPlane?: string | null,
  parkingHeight?: number | null,
  parkingFee?: number | null,
  purposeB?: string | null,
  purposeSummaryB?: string | null,
  companyId?: string | null,
  companyName?: string | null,
  companyAddress?: string | null,
  companyTel?: string | null,
  companyFax?: string | null,
  companyUrl?: string | null,
  companyLicense?: string | null,
  companyGuaranter?: string | null,
  subCompanyId?: string | null,
  subCompanyName?: string | null,
  subCompanyAddress?: string | null,
  subCompanyTel?: string | null,
  subCompanyFax?: string | null,
  subCompanyUrl?: string | null,
  subCompanyLicense?: string | null,
  subCompanyGuaranter?: string | null,
  subCompany2Id?: string | null,
  subCompany2Name?: string | null,
  subCompany2Address?: string | null,
  subCompany2Tel?: string | null,
  subCompany2Fax?: string | null,
  subCompany2Url?: string | null,
  subCompany2License?: string | null,
  subCompany2Guaranter?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  tileOnZoom12?: string | null,
  tileOnZoom14?: string | null,
  tileOnZoom16?: string | null,
  layoutImgs?: Array< string | null > | null,
  appearanceImgs?: Array< string | null > | null,
  mapImg?: string | null,
  waterPdf?: string | null,
  sewerPdf?: string | null,
  gasPdf?: string | null,
  cadastralMapPdf?: string | null,
  groundPdf?: string | null,
  url?: string | null,
  license?: string | null,
  recNo?: number | null,
  media?: string | null,
  location?: string | null,
  collectedAt?: string | null,
  floorStructure?: string | null,
  buildingCompany?: string | null,
  notFoundAt?: string | null,
  exactLocation?: number | null,
  nearlyLocation?: number | null,
  unsettledLocation?: number | null,
  isLot?: number | null,
  currentStatusB?: string | null,
  currentStatusG?: string | null,
  companyRecNo?: number | null,
  companyBranchId?: number | null,
  subCompanyRecNo?: number | null,
  completionMonthS?: string | null,
  condominiumName?: string | null,
  condominiumRecNo?: number | null,
  totalHouses?: number | null,
  manager?: string | null,
  seller?: string | null,
  estateUrl?: string | null,
  searchInfo: string,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sold?: boolean | null,
};

export type Price = {
  __typename: "Price",
  price?: number | null,
  pricedAt?: string | null,
};

export type UpdateEstateInput = {
  id: string,
  infoName: string,
  condominiumId?: string | null,
  confirmed?: boolean | null,
  status?: string | null,
  estateType?: string | null,
  name?: string | null,
  fullAddress?: string | null,
  prefecture?: string | null,
  city?: string | null,
  area?: string | null,
  address?: string | null,
  mediationKind?: string | null,
  currentStatus?: string | null,
  currentStatusFree?: string | null,
  traffic?: string | null,
  eSchool?: Array< string | null > | null,
  jSchool?: Array< string | null > | null,
  remarks?: string | null,
  landAreaType?: string | null,
  squareArea?: number | null,
  tsuboArea?: number | null,
  estatePrice?: number | null,
  unitPrice?: number | null,
  pricedAt?: string | null,
  completionSchMonth?: number | null,
  completionMonth?: number | null,
  clearingType?: string | null,
  cleaningMonth?: number | null,
  bldConditionType?: string | null,
  landRights?: string | null,
  cityPlanningType?: string | null,
  useDistrict?: Array< string | null > | null,
  floorAreaRatio?: number | null,
  bcr?: number | null,
  roadType?: string | null,
  drivewayPay?: string | null,
  roadDirection?: string | null,
  roadWidth?: number | null,
  water?: string | null,
  sewer?: string | null,
  gas?: string | null,
  groundType?: Array< string | null > | null,
  groundTypeFree?: string | null,
  layout?: string | null,
  totalArea?: number | null,
  structure?: string | null,
  structureFree?: string | null,
  buildingFloors?: string | null,
  balconyArea?: number | null,
  managementType?: string | null,
  pet?: string | null,
  floor?: number | null,
  room?: string | null,
  managementFee?: number | null,
  repairCost?: number | null,
  otherCost?: number | null,
  parking?: string | null,
  carsInParkingN?: number | null,
  parkingTakeOver?: string | null,
  parkingTakeOverFree?: string | null,
  parkingMechOrPlane?: string | null,
  parkingHeight?: number | null,
  parkingFee?: number | null,
  purposeB?: string | null,
  purposeSummaryB?: string | null,
  companyId?: string | null,
  companyName?: string | null,
  companyAddress?: string | null,
  companyTel?: string | null,
  companyFax?: string | null,
  companyUrl?: string | null,
  companyLicense?: string | null,
  companyGuaranter?: string | null,
  subCompanyId?: string | null,
  subCompanyName?: string | null,
  subCompanyAddress?: string | null,
  subCompanyTel?: string | null,
  subCompanyFax?: string | null,
  subCompanyUrl?: string | null,
  subCompanyLicense?: string | null,
  subCompanyGuaranter?: string | null,
  subCompany2Id?: string | null,
  subCompany2Name?: string | null,
  subCompany2Address?: string | null,
  subCompany2Tel?: string | null,
  subCompany2Fax?: string | null,
  subCompany2Url?: string | null,
  subCompany2License?: string | null,
  subCompany2Guaranter?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  tileOnZoom12?: string | null,
  tileOnZoom14?: string | null,
  tileOnZoom16?: string | null,
  layoutImgs?: Array< string | null > | null,
  appearanceImgs?: Array< string | null > | null,
  mapImg?: string | null,
  waterPdf?: string | null,
  sewerPdf?: string | null,
  gasPdf?: string | null,
  cadastralMapPdf?: string | null,
  groundPdf?: string | null,
  url?: string | null,
  license?: string | null,
  recNo?: number | null,
  media?: string | null,
  location?: string | null,
  collectedAt?: string | null,
  floorStructure?: string | null,
  buildingCompany?: string | null,
  notFoundAt?: string | null,
  exactLocation?: number | null,
  nearlyLocation?: number | null,
  unsettledLocation?: number | null,
  isLot?: number | null,
  currentStatusB?: string | null,
  currentStatusG?: string | null,
  companyRecNo?: number | null,
  companyBranchId?: number | null,
  subCompanyRecNo?: number | null,
  completionMonthS?: string | null,
  condominiumName?: string | null,
  condominiumRecNo?: number | null,
  totalHouses?: number | null,
  manager?: string | null,
  seller?: string | null,
  estateUrl?: string | null,
  searchInfo?: string | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteEstateInput = {
  id: string,
  infoName: string,
  companyId?: string | null,
};

export type NotifyEstateInput = {
  id: string,
  infoName: string,
  condominiumId?: string | null,
  confirmed?: boolean | null,
  status?: string | null,
  estateType?: string | null,
  name?: string | null,
  fullAddress?: string | null,
  prefecture?: string | null,
  city?: string | null,
  area?: string | null,
  address?: string | null,
  mediationKind?: string | null,
  currentStatus?: string | null,
  currentStatusFree?: string | null,
  traffic?: string | null,
  eSchool?: Array< string | null > | null,
  jSchool?: Array< string | null > | null,
  remarks?: string | null,
  landAreaType?: string | null,
  squareArea?: number | null,
  tsuboArea?: number | null,
  estatePrice?: number | null,
  unitPrice?: number | null,
  pricedAt?: string | null,
  priceHistory?: Array< PriceInput | null > | null,
  completionSchMonth?: number | null,
  completionMonth?: number | null,
  clearingType?: string | null,
  cleaningMonth?: number | null,
  bldConditionType?: string | null,
  landRights?: string | null,
  cityPlanningType?: string | null,
  useDistrict?: Array< string | null > | null,
  floorAreaRatio?: number | null,
  bcr?: number | null,
  roadType?: string | null,
  drivewayPay?: string | null,
  roadDirection?: string | null,
  roadWidth?: number | null,
  water?: string | null,
  sewer?: string | null,
  gas?: string | null,
  groundType?: Array< string | null > | null,
  groundTypeFree?: string | null,
  layout?: string | null,
  totalArea?: number | null,
  structure?: string | null,
  structureFree?: string | null,
  buildingFloors?: string | null,
  balconyArea?: number | null,
  managementType?: string | null,
  pet?: string | null,
  floor?: number | null,
  room?: string | null,
  managementFee?: number | null,
  repairCost?: number | null,
  otherCost?: number | null,
  parking?: string | null,
  carsInParkingN?: number | null,
  parkingTakeOver?: string | null,
  parkingTakeOverFree?: string | null,
  parkingMechOrPlane?: string | null,
  parkingHeight?: number | null,
  parkingFee?: number | null,
  purposeB?: string | null,
  purposeSummaryB?: string | null,
  companyId?: string | null,
  companyName?: string | null,
  companyAddress?: string | null,
  companyTel?: string | null,
  companyFax?: string | null,
  companyUrl?: string | null,
  companyLicense?: string | null,
  companyGuaranter?: string | null,
  subCompanyId?: string | null,
  subCompanyName?: string | null,
  subCompanyAddress?: string | null,
  subCompanyTel?: string | null,
  subCompanyFax?: string | null,
  subCompanyUrl?: string | null,
  subCompanyLicense?: string | null,
  subCompanyGuaranter?: string | null,
  subCompany2Id?: string | null,
  subCompany2Name?: string | null,
  subCompany2Address?: string | null,
  subCompany2Tel?: string | null,
  subCompany2Fax?: string | null,
  subCompany2Url?: string | null,
  subCompany2License?: string | null,
  subCompany2Guaranter?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  tileOnZoom12?: string | null,
  tileOnZoom14?: string | null,
  tileOnZoom16?: string | null,
  layoutImgs?: Array< string | null > | null,
  appearanceImgs?: Array< string | null > | null,
  mapImg?: string | null,
  waterPdf?: string | null,
  sewerPdf?: string | null,
  gasPdf?: string | null,
  cadastralMapPdf?: string | null,
  groundPdf?: string | null,
  url?: string | null,
  license?: string | null,
  recNo?: number | null,
  media?: string | null,
  location?: string | null,
  collectedAt?: string | null,
  floorStructure?: string | null,
  buildingCompany?: string | null,
  notFoundAt?: string | null,
  exactLocation?: number | null,
  nearlyLocation?: number | null,
  unsettledLocation?: number | null,
  isLot?: number | null,
  currentStatusB?: string | null,
  currentStatusG?: string | null,
  companyRecNo?: number | null,
  companyBranchId?: number | null,
  subCompanyRecNo?: number | null,
  completionMonthS?: string | null,
  condominiumName?: string | null,
  condominiumRecNo?: number | null,
  totalHouses?: number | null,
  manager?: string | null,
  seller?: string | null,
  estateUrl?: string | null,
  searchInfo: string,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sold?: boolean | null,
};

export type PriceInput = {
  price?: number | null,
  pricedAt?: string | null,
};

export type EstateNotifyResponse = {
  __typename: "EstateNotifyResponse",
  id?: string | null,
  infoName?: string | null,
  condominiumId?: string | null,
  confirmed?: boolean | null,
  status?: string | null,
  estateType?: string | null,
  name?: string | null,
  fullAddress?: string | null,
  prefecture?: string | null,
  city?: string | null,
  area?: string | null,
  address?: string | null,
  mediationKind?: string | null,
  currentStatus?: string | null,
  currentStatusFree?: string | null,
  traffic?: string | null,
  eSchool?: Array< string | null > | null,
  jSchool?: Array< string | null > | null,
  remarks?: string | null,
  landAreaType?: string | null,
  squareArea?: number | null,
  tsuboArea?: number | null,
  estatePrice?: number | null,
  unitPrice?: number | null,
  pricedAt?: string | null,
  priceHistory?:  Array<Price | null > | null,
  completionSchMonth?: number | null,
  completionMonth?: number | null,
  clearingType?: string | null,
  cleaningMonth?: number | null,
  bldConditionType?: string | null,
  landRights?: string | null,
  cityPlanningType?: string | null,
  useDistrict?: Array< string | null > | null,
  floorAreaRatio?: number | null,
  bcr?: number | null,
  roadType?: string | null,
  drivewayPay?: string | null,
  roadDirection?: string | null,
  roadWidth?: number | null,
  water?: string | null,
  sewer?: string | null,
  gas?: string | null,
  groundType?: Array< string | null > | null,
  groundTypeFree?: string | null,
  layout?: string | null,
  totalArea?: number | null,
  structure?: string | null,
  structureFree?: string | null,
  buildingFloors?: string | null,
  balconyArea?: number | null,
  managementType?: string | null,
  pet?: string | null,
  floor?: number | null,
  room?: string | null,
  managementFee?: number | null,
  repairCost?: number | null,
  otherCost?: number | null,
  parking?: string | null,
  carsInParkingN?: number | null,
  parkingTakeOver?: string | null,
  parkingTakeOverFree?: string | null,
  parkingMechOrPlane?: string | null,
  parkingHeight?: number | null,
  parkingFee?: number | null,
  purposeB?: string | null,
  purposeSummaryB?: string | null,
  companyId?: string | null,
  companyName?: string | null,
  companyAddress?: string | null,
  companyTel?: string | null,
  companyFax?: string | null,
  companyUrl?: string | null,
  companyLicense?: string | null,
  companyGuaranter?: string | null,
  subCompanyId?: string | null,
  subCompanyName?: string | null,
  subCompanyAddress?: string | null,
  subCompanyTel?: string | null,
  subCompanyFax?: string | null,
  subCompanyUrl?: string | null,
  subCompanyLicense?: string | null,
  subCompanyGuaranter?: string | null,
  subCompany2Id?: string | null,
  subCompany2Name?: string | null,
  subCompany2Address?: string | null,
  subCompany2Tel?: string | null,
  subCompany2Fax?: string | null,
  subCompany2Url?: string | null,
  subCompany2License?: string | null,
  subCompany2Guaranter?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  tileOnZoom12?: string | null,
  tileOnZoom14?: string | null,
  tileOnZoom16?: string | null,
  layoutImgs?: Array< string | null > | null,
  appearanceImgs?: Array< string | null > | null,
  mapImg?: string | null,
  waterPdf?: string | null,
  sewerPdf?: string | null,
  gasPdf?: string | null,
  cadastralMapPdf?: string | null,
  groundPdf?: string | null,
  url?: string | null,
  license?: string | null,
  recNo?: number | null,
  media?: string | null,
  location?: string | null,
  collectedAt?: string | null,
  floorStructure?: string | null,
  buildingCompany?: string | null,
  notFoundAt?: string | null,
  exactLocation?: number | null,
  nearlyLocation?: number | null,
  unsettledLocation?: number | null,
  isLot?: number | null,
  currentStatusB?: string | null,
  currentStatusG?: string | null,
  companyRecNo?: number | null,
  companyBranchId?: number | null,
  subCompanyRecNo?: number | null,
  completionMonthS?: string | null,
  condominiumName?: string | null,
  condominiumRecNo?: number | null,
  totalHouses?: number | null,
  manager?: string | null,
  seller?: string | null,
  estateUrl?: string | null,
  searchInfo?: string | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sold?: boolean | null,
};

export type CreateStickyNoteInput = {
  id?: string | null,
  infoName: string,
  memoKind: string,
  openLevel: string,
  icon?: StickyNoteIcon | null,
  color?: StickyNoteColor | null,
  description?: string | null,
  companyId: string,
  latitude: number,
  longitude: number,
  tileOnZoom12: string,
  tileOnZoom14: string,
  tileOnZoom16: string,
  searchInfo: string,
  owner: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum StickyNoteIcon {
  normal = "normal",
  parking = "parking",
  key = "key",
  cone = "cone",
  caution = "caution",
  secret = "secret",
  sale = "sale",
  cart = "cart",
  person = "person",
}


export enum StickyNoteColor {
  white = "white",
  blue = "blue",
  yellow = "yellow",
  red = "red",
}


export type StickyNoteMutationResponse = {
  __typename: "StickyNoteMutationResponse",
  item?: StickyNote | null,
  errorMessage?: string | null,
};

export type StickyNote = {
  __typename: "StickyNote",
  id: string,
  infoName: string,
  memoKind: string,
  openLevel: string,
  icon?: StickyNoteIcon | null,
  color?: StickyNoteColor | null,
  description?: string | null,
  companyId: string,
  latitude: number,
  longitude: number,
  tileOnZoom12: string,
  tileOnZoom14: string,
  tileOnZoom16: string,
  searchInfo: string,
  owner: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateStickyNoteInput = {
  id: string,
  infoName: string,
  memoKind?: string | null,
  openLevel?: string | null,
  icon?: StickyNoteIcon | null,
  color?: StickyNoteColor | null,
  description?: string | null,
  companyId?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  tileOnZoom12?: string | null,
  tileOnZoom14?: string | null,
  tileOnZoom16?: string | null,
  searchInfo?: string | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteStickyNoteInput = {
  id: string,
  infoName: string,
  companyId?: string | null,
};

export type CreatePublicEstateInput = {
  estateId: string,
  companyId: string,
};

export type PublicEstateMutationResponse = {
  __typename: "PublicEstateMutationResponse",
  id: string,
};

export type CreatePublicEstatePdfInput = {
  url: string,
};

export type PublicEstatePdfMutationResponse = {
  __typename: "PublicEstatePdfMutationResponse",
  pdf: string,
};

export type CreateAnnounceInput = {
  id?: string | null,
  level: AnnounceLevel,
  subject: string,
  body: string,
  ym: string,
  owner: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  expiredAt?: number | null,
};

export enum AnnounceLevel {
  INFO = "INFO",
  WARN = "WARN",
  IMPORTANT = "IMPORTANT",
}


export type ModelAnnounceConditionInput = {
  level?: ModelAnnounceLevelInput | null,
  subject?: ModelStringInput | null,
  body?: ModelStringInput | null,
  ym?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expiredAt?: ModelIntInput | null,
  and?: Array< ModelAnnounceConditionInput | null > | null,
  or?: Array< ModelAnnounceConditionInput | null > | null,
  not?: ModelAnnounceConditionInput | null,
};

export type ModelAnnounceLevelInput = {
  eq?: AnnounceLevel | null,
  ne?: AnnounceLevel | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Announce = {
  __typename: "Announce",
  id: string,
  level: AnnounceLevel,
  subject: string,
  body: string,
  ym: string,
  owner: string,
  createdAt: string,
  updatedAt?: string | null,
  expiredAt?: number | null,
};

export type UpdateAnnounceInput = {
  id: string,
  level?: AnnounceLevel | null,
  subject?: string | null,
  body?: string | null,
  ym?: string | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expiredAt?: number | null,
};

export type DeleteAnnounceInput = {
  id: string,
};

export type CreateBatchJobInput = {
  id?: string | null,
  jobName: string,
  remarks: string,
  status: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  expiredAt?: number | null,
};

export type ModelBatchJobConditionInput = {
  jobName?: ModelStringInput | null,
  remarks?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expiredAt?: ModelIntInput | null,
  and?: Array< ModelBatchJobConditionInput | null > | null,
  or?: Array< ModelBatchJobConditionInput | null > | null,
  not?: ModelBatchJobConditionInput | null,
};

export type BatchJob = {
  __typename: "BatchJob",
  id: string,
  jobName: string,
  remarks: string,
  status: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  expiredAt?: number | null,
};

export type CreateBatchJobConfInput = {
  jobName: string,
  remarks: string,
  allowedGroups?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelBatchJobConfConditionInput = {
  remarks?: ModelStringInput | null,
  allowedGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBatchJobConfConditionInput | null > | null,
  or?: Array< ModelBatchJobConfConditionInput | null > | null,
  not?: ModelBatchJobConfConditionInput | null,
};

export type BatchJobConf = {
  __typename: "BatchJobConf",
  jobName: string,
  remarks: string,
  allowedGroups?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateBatchJobConfInput = {
  jobName: string,
  remarks?: string | null,
  allowedGroups?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteBatchJobConfInput = {
  jobName: string,
};

export type CreateReportInput = {
  id?: string | null,
  estateId: string,
  estateName?: string | null,
  reportKind: string,
  description?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  companyId: string,
  companyName: string,
  userName: string,
  owner: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  expiredAt?: number | null,
};

export type ModelReportConditionInput = {
  estateId?: ModelStringInput | null,
  estateName?: ModelStringInput | null,
  reportKind?: ModelStringInput | null,
  description?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  companyId?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expiredAt?: ModelIntInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Report = {
  __typename: "Report",
  id: string,
  estateId: string,
  estateName?: string | null,
  reportKind: string,
  description?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  companyId: string,
  companyName: string,
  userName: string,
  owner: string,
  createdAt: string,
  updatedAt?: string | null,
  expiredAt?: number | null,
};

export type UpdateReportInput = {
  id: string,
  estateId?: string | null,
  estateName?: string | null,
  reportKind?: string | null,
  description?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  companyId?: string | null,
  companyName?: string | null,
  userName?: string | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expiredAt?: number | null,
};

export type DeleteReportInput = {
  id: string,
};

export type CreateScrapingInput = {
  baseUrl: string,
  prefName: string,
  status: string,
  operation?: string | null,
  input?: string | null,
  startedAt?: string | null,
  totalCount?: number | null,
  updating?: ScrapingUpdateDetailInput | null,
  updated?: ScrapingUpdateDetailInput | null,
  resultKey?: string | null,
  endedAt?: string | null,
  output?: string | null,
  remarks?: string | null,
  sortKey: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ScrapingUpdateDetailInput = {
  newRecord?: number | null,
  updatePrice?: number | null,
  expired?: number | null,
  return?: number | null,
  returnAndUpdatePrice?: number | null,
  scrapingFailed?: number | null,
  requiresAuth?: number | null,
  blocked?: number | null,
  updateFailed?: number | null,
};

export type ModelScrapingConditionInput = {
  prefName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  operation?: ModelStringInput | null,
  input?: ModelStringInput | null,
  startedAt?: ModelStringInput | null,
  totalCount?: ModelIntInput | null,
  resultKey?: ModelStringInput | null,
  endedAt?: ModelStringInput | null,
  output?: ModelStringInput | null,
  remarks?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelScrapingConditionInput | null > | null,
  or?: Array< ModelScrapingConditionInput | null > | null,
  not?: ModelScrapingConditionInput | null,
};

export type Scraping = {
  __typename: "Scraping",
  baseUrl: string,
  prefName: string,
  status: string,
  operation?: string | null,
  input?: string | null,
  startedAt?: string | null,
  totalCount?: number | null,
  updating?: ScrapingUpdateDetail | null,
  updated?: ScrapingUpdateDetail | null,
  resultKey?: string | null,
  endedAt?: string | null,
  output?: string | null,
  remarks?: string | null,
  sortKey: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ScrapingUpdateDetail = {
  __typename: "ScrapingUpdateDetail",
  newRecord?: number | null,
  updatePrice?: number | null,
  expired?: number | null,
  return?: number | null,
  returnAndUpdatePrice?: number | null,
  scrapingFailed?: number | null,
  requiresAuth?: number | null,
  blocked?: number | null,
  updateFailed?: number | null,
};

export type UpdateScrapingInput = {
  baseUrl: string,
  prefName?: string | null,
  status?: string | null,
  operation?: string | null,
  input?: string | null,
  startedAt?: string | null,
  totalCount?: number | null,
  updating?: ScrapingUpdateDetailInput | null,
  updated?: ScrapingUpdateDetailInput | null,
  resultKey?: string | null,
  endedAt?: string | null,
  output?: string | null,
  remarks?: string | null,
  sortKey: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCognitoUserConnection = {
  __typename: "ModelCognitoUserConnection",
  items?:  Array<CognitoUser | null > | null,
  nextToken?: string | null,
  errorMessage?: string | null,
};

export type PresignedUrlResponse = {
  __typename: "PresignedUrlResponse",
  url?: string | null,
  errorMessage?: string | null,
};

export type ModelAnnounceFilterInput = {
  id?: ModelIDInput | null,
  level?: ModelAnnounceLevelInput | null,
  subject?: ModelStringInput | null,
  body?: ModelStringInput | null,
  ym?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expiredAt?: ModelIntInput | null,
  and?: Array< ModelAnnounceFilterInput | null > | null,
  or?: Array< ModelAnnounceFilterInput | null > | null,
  not?: ModelAnnounceFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAnnounceConnection = {
  __typename: "ModelAnnounceConnection",
  items:  Array<Announce | null >,
  nextToken?: string | null,
};

export type ModelBatchJobFilterInput = {
  id?: ModelIDInput | null,
  jobName?: ModelStringInput | null,
  remarks?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expiredAt?: ModelIntInput | null,
  and?: Array< ModelBatchJobFilterInput | null > | null,
  or?: Array< ModelBatchJobFilterInput | null > | null,
  not?: ModelBatchJobFilterInput | null,
};

export type ModelBatchJobConnection = {
  __typename: "ModelBatchJobConnection",
  items:  Array<BatchJob | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelBatchJobConfFilterInput = {
  jobName?: ModelStringInput | null,
  remarks?: ModelStringInput | null,
  allowedGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelBatchJobConfFilterInput | null > | null,
  or?: Array< ModelBatchJobConfFilterInput | null > | null,
  not?: ModelBatchJobConfFilterInput | null,
};

export type ModelBatchJobConfConnection = {
  __typename: "ModelBatchJobConfConnection",
  items:  Array<BatchJobConf | null >,
  nextToken?: string | null,
};

export type ModelCompanyFilterInput = {
  id?: ModelStringInput | null,
  infoName?: ModelStringInput | null,
  startMark?: ModelStringInput | null,
  recNo?: ModelIntInput | null,
  name?: ModelStringInput | null,
  branchId?: ModelIntInput | null,
  branchName?: ModelStringInput | null,
  branchTel?: ModelStringInput | null,
  branchFax?: ModelStringInput | null,
  branchUrl?: ModelStringInput | null,
  branchAddress?: ModelStringInput | null,
  address?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  url?: ModelStringInput | null,
  remarks?: ModelStringInput | null,
  license?: ModelStringInput | null,
  licenser?: ModelStringInput | null,
  licenseCount?: ModelIntInput | null,
  licenseNo?: ModelStringInput | null,
  guaranter?: ModelStringInput | null,
  job?: ModelStringInput | null,
  email?: ModelStringInput | null,
  logoImg?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  areaPrefs?: ModelStringInput | null,
  areas?: ModelStringInput | null,
  areaNames?: ModelStringInput | null,
  eAreas?: ModelStringInput | null,
  eAreaNames?: ModelStringInput | null,
  estateTypes?: ModelStringInput | null,
  bldConditionSelections?: ModelStringInput | null,
  remarks2?: ModelStringInput | null,
  searchInfo?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items:  Array<Company | null >,
  nextToken?: string | null,
};

export type ModelEstateFilterInput = {
  id?: ModelStringInput | null,
  infoName?: ModelStringInput | null,
  condominiumId?: ModelStringInput | null,
  confirmed?: ModelBooleanInput | null,
  status?: ModelStringInput | null,
  estateType?: ModelStringInput | null,
  name?: ModelStringInput | null,
  fullAddress?: ModelStringInput | null,
  prefecture?: ModelStringInput | null,
  city?: ModelStringInput | null,
  area?: ModelStringInput | null,
  address?: ModelStringInput | null,
  mediationKind?: ModelStringInput | null,
  currentStatus?: ModelStringInput | null,
  currentStatusFree?: ModelStringInput | null,
  traffic?: ModelStringInput | null,
  eSchool?: ModelStringInput | null,
  jSchool?: ModelStringInput | null,
  remarks?: ModelStringInput | null,
  landAreaType?: ModelStringInput | null,
  squareArea?: ModelFloatInput | null,
  tsuboArea?: ModelFloatInput | null,
  estatePrice?: ModelFloatInput | null,
  unitPrice?: ModelFloatInput | null,
  pricedAt?: ModelStringInput | null,
  completionSchMonth?: ModelIntInput | null,
  completionMonth?: ModelIntInput | null,
  clearingType?: ModelStringInput | null,
  cleaningMonth?: ModelIntInput | null,
  bldConditionType?: ModelStringInput | null,
  landRights?: ModelStringInput | null,
  cityPlanningType?: ModelStringInput | null,
  useDistrict?: ModelStringInput | null,
  floorAreaRatio?: ModelIntInput | null,
  bcr?: ModelIntInput | null,
  roadType?: ModelStringInput | null,
  drivewayPay?: ModelStringInput | null,
  roadDirection?: ModelStringInput | null,
  roadWidth?: ModelFloatInput | null,
  water?: ModelStringInput | null,
  sewer?: ModelStringInput | null,
  gas?: ModelStringInput | null,
  groundType?: ModelStringInput | null,
  groundTypeFree?: ModelStringInput | null,
  layout?: ModelStringInput | null,
  totalArea?: ModelFloatInput | null,
  structure?: ModelStringInput | null,
  structureFree?: ModelStringInput | null,
  buildingFloors?: ModelStringInput | null,
  balconyArea?: ModelFloatInput | null,
  managementType?: ModelStringInput | null,
  pet?: ModelStringInput | null,
  floor?: ModelIntInput | null,
  room?: ModelStringInput | null,
  managementFee?: ModelIntInput | null,
  repairCost?: ModelIntInput | null,
  otherCost?: ModelIntInput | null,
  parking?: ModelStringInput | null,
  carsInParkingN?: ModelIntInput | null,
  parkingTakeOver?: ModelStringInput | null,
  parkingTakeOverFree?: ModelStringInput | null,
  parkingMechOrPlane?: ModelStringInput | null,
  parkingHeight?: ModelFloatInput | null,
  parkingFee?: ModelIntInput | null,
  purposeB?: ModelStringInput | null,
  purposeSummaryB?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  companyAddress?: ModelStringInput | null,
  companyTel?: ModelStringInput | null,
  companyFax?: ModelStringInput | null,
  companyUrl?: ModelStringInput | null,
  companyLicense?: ModelStringInput | null,
  companyGuaranter?: ModelStringInput | null,
  subCompanyId?: ModelStringInput | null,
  subCompanyName?: ModelStringInput | null,
  subCompanyAddress?: ModelStringInput | null,
  subCompanyTel?: ModelStringInput | null,
  subCompanyFax?: ModelStringInput | null,
  subCompanyUrl?: ModelStringInput | null,
  subCompanyLicense?: ModelStringInput | null,
  subCompanyGuaranter?: ModelStringInput | null,
  subCompany2Id?: ModelStringInput | null,
  subCompany2Name?: ModelStringInput | null,
  subCompany2Address?: ModelStringInput | null,
  subCompany2Tel?: ModelStringInput | null,
  subCompany2Fax?: ModelStringInput | null,
  subCompany2Url?: ModelStringInput | null,
  subCompany2License?: ModelStringInput | null,
  subCompany2Guaranter?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  tileOnZoom12?: ModelStringInput | null,
  tileOnZoom14?: ModelStringInput | null,
  tileOnZoom16?: ModelStringInput | null,
  layoutImgs?: ModelStringInput | null,
  appearanceImgs?: ModelStringInput | null,
  mapImg?: ModelStringInput | null,
  waterPdf?: ModelStringInput | null,
  sewerPdf?: ModelStringInput | null,
  gasPdf?: ModelStringInput | null,
  cadastralMapPdf?: ModelStringInput | null,
  groundPdf?: ModelStringInput | null,
  url?: ModelStringInput | null,
  license?: ModelStringInput | null,
  recNo?: ModelIntInput | null,
  media?: ModelStringInput | null,
  location?: ModelStringInput | null,
  collectedAt?: ModelStringInput | null,
  floorStructure?: ModelStringInput | null,
  buildingCompany?: ModelStringInput | null,
  notFoundAt?: ModelStringInput | null,
  exactLocation?: ModelIntInput | null,
  nearlyLocation?: ModelIntInput | null,
  unsettledLocation?: ModelIntInput | null,
  isLot?: ModelIntInput | null,
  currentStatusB?: ModelStringInput | null,
  currentStatusG?: ModelStringInput | null,
  companyRecNo?: ModelIntInput | null,
  companyBranchId?: ModelIntInput | null,
  subCompanyRecNo?: ModelIntInput | null,
  completionMonthS?: ModelStringInput | null,
  condominiumName?: ModelStringInput | null,
  condominiumRecNo?: ModelIntInput | null,
  totalHouses?: ModelIntInput | null,
  manager?: ModelStringInput | null,
  seller?: ModelStringInput | null,
  estateUrl?: ModelStringInput | null,
  searchInfo?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  sold?: ModelBooleanInput | null,
  and?: Array< ModelEstateFilterInput | null > | null,
  or?: Array< ModelEstateFilterInput | null > | null,
  not?: ModelEstateFilterInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelEstateConnection = {
  __typename: "ModelEstateConnection",
  items:  Array<Estate | null >,
  nextToken?: string | null,
};

export type PublicEstate = {
  __typename: "PublicEstate",
  id: string,
  distributer: Company,
  estateId: string,
  estate: Estate,
  createdAt: string,
  updatedAt?: string | null,
  expiredAt: number,
};

export type ModelReportFilterInput = {
  id?: ModelIDInput | null,
  estateId?: ModelStringInput | null,
  estateName?: ModelStringInput | null,
  reportKind?: ModelStringInput | null,
  description?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  companyId?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expiredAt?: ModelIntInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items:  Array<Report | null >,
  nextToken?: string | null,
};

export type ModelScrapingFilterInput = {
  baseUrl?: ModelStringInput | null,
  prefName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  operation?: ModelStringInput | null,
  input?: ModelStringInput | null,
  startedAt?: ModelStringInput | null,
  totalCount?: ModelIntInput | null,
  resultKey?: ModelStringInput | null,
  endedAt?: ModelStringInput | null,
  output?: ModelStringInput | null,
  remarks?: ModelStringInput | null,
  sortKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelScrapingFilterInput | null > | null,
  or?: Array< ModelScrapingFilterInput | null > | null,
  not?: ModelScrapingFilterInput | null,
};

export type ModelScrapingConnection = {
  __typename: "ModelScrapingConnection",
  items:  Array<Scraping | null >,
  nextToken?: string | null,
};

export type ModelStickyNoteFilterInput = {
  id?: ModelIDInput | null,
  infoName?: ModelStringInput | null,
  memoKind?: ModelStringInput | null,
  openLevel?: ModelStringInput | null,
  icon?: ModelStickyNoteIconInput | null,
  color?: ModelStickyNoteColorInput | null,
  description?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  tileOnZoom12?: ModelStringInput | null,
  tileOnZoom14?: ModelStringInput | null,
  tileOnZoom16?: ModelStringInput | null,
  searchInfo?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStickyNoteFilterInput | null > | null,
  or?: Array< ModelStickyNoteFilterInput | null > | null,
  not?: ModelStickyNoteFilterInput | null,
};

export type ModelStickyNoteIconInput = {
  eq?: StickyNoteIcon | null,
  ne?: StickyNoteIcon | null,
};

export type ModelStickyNoteColorInput = {
  eq?: StickyNoteColor | null,
  ne?: StickyNoteColor | null,
};

export type ModelStickyNoteConnection = {
  __typename: "ModelStickyNoteConnection",
  items:  Array<StickyNote | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  username?: ModelStringInput | null,
  infoName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  nameRuby?: ModelStringInput | null,
  tel?: ModelStringInput | null,
  role?: ModelStringInput | null,
  agreement?: ModelBooleanInput | null,
  trial?: ModelBooleanInput | null,
  companyId?: ModelStringInput | null,
  group?: ModelStringInput | null,
  email?: ModelStringInput | null,
  searchInfo?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  authenticatedAt?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  companyBranchName?: ModelStringInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionBatchJobFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  jobName?: ModelSubscriptionStringInput | null,
  remarks?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  expiredAt?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionBatchJobFilterInput | null > | null,
  or?: Array< ModelSubscriptionBatchJobFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionScrapingFilterInput = {
  baseUrl?: ModelSubscriptionStringInput | null,
  prefName?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  operation?: ModelSubscriptionStringInput | null,
  input?: ModelSubscriptionStringInput | null,
  startedAt?: ModelSubscriptionStringInput | null,
  totalCount?: ModelSubscriptionIntInput | null,
  resultKey?: ModelSubscriptionStringInput | null,
  endedAt?: ModelSubscriptionStringInput | null,
  output?: ModelSubscriptionStringInput | null,
  remarks?: ModelSubscriptionStringInput | null,
  sortKey?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionScrapingFilterInput | null > | null,
  or?: Array< ModelSubscriptionScrapingFilterInput | null > | null,
};

export type IncrementSearchAtomicCounterCMutationVariables = {
};

export type IncrementSearchAtomicCounterCMutation = {
  incrementSearchAtomicCounterC?:  {
    __typename: "AtomicCounter",
    counterName: string,
    sequence: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type IncrementEstateAtomicCounterCMutationVariables = {
  id: string,
};

export type IncrementEstateAtomicCounterCMutation = {
  incrementEstateAtomicCounterC?:  {
    __typename: "AtomicCounter",
    counterName: string,
    sequence: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type IncrementRecNoAtomicCounterCMutationVariables = {
};

export type IncrementRecNoAtomicCounterCMutation = {
  incrementRecNoAtomicCounterC?:  {
    __typename: "AtomicCounter",
    counterName: string,
    sequence: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateCognitoUserFMutationVariables = {
  group: string,
  username: string,
  companyId: string,
  email: string,
  password: string,
  name: string,
  nameRuby: string,
  tel?: string | null,
  role?: string | null,
  agreement?: boolean | null,
  trial?: boolean | null,
};

export type CreateCognitoUserFMutation = {
  createCognitoUserF?:  {
    __typename: "CognitoUserResponse",
    item?:  {
      __typename: "CognitoUser",
      Username?: string | null,
      Attributes?:  Array< {
        __typename: "CognitoUserAttribute",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: UserStatusEnum | null,
      MFAOptions?:  Array< {
        __typename: "CognitoUserMFAOption",
        DeliveryMedium?: DeliveryMediumEnum | null,
        AttributeName?: string | null,
      } | null > | null,
      Groups?:  Array< {
        __typename: "CognitoGroup",
        GroupName?: string | null,
        UserPoolId?: string | null,
        Description?: string | null,
        RoleArn?: string | null,
        Precedence?: number | null,
        LastModifiedDate?: string | null,
        CreationDate?: string | null,
      } | null > | null,
      User?:  {
        __typename: "User",
        username: string,
        infoName: string,
        name?: string | null,
        nameRuby?: string | null,
        tel?: string | null,
        role?: string | null,
        agreement?: boolean | null,
        trial?: boolean | null,
        companyId?: string | null,
        defaultCondition?:  {
          __typename: "DefaultCondition",
          estatePriceFrom?: number | null,
          estatePriceTo?: number | null,
          tsuboAreaFrom?: number | null,
          tsuboAreaTo?: number | null,
          completionYearFrom?: string | null,
          completionYearTo?: string | null,
          roomsFrom?: number | null,
          roomsTo?: number | null,
          bldConditionSelections?: Array< string | null > | null,
          petOk?: boolean | null,
          flat?: boolean | null,
          inMyCompany?: boolean | null,
          purposeSummaryBs?: Array< string | null > | null,
          alsoCreatedAtFrom?: number | null,
          alsoCreatedAtTo?: number | null,
        } | null,
        group?: string | null,
        email?: string | null,
        searchInfo?: string | null,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        authenticatedAt?: string | null,
        companyName?: string | null,
        companyBranchName?: string | null,
      } | null,
      Company?:  {
        __typename: "Company",
        id: string,
        infoName: string,
        startMark?: string | null,
        recNo?: number | null,
        name?: string | null,
        branchId?: number | null,
        branchName?: string | null,
        branchTel?: string | null,
        branchFax?: string | null,
        branchUrl?: string | null,
        branchAddress?: string | null,
        address?: string | null,
        tel?: string | null,
        fax?: string | null,
        url?: string | null,
        remarks?: string | null,
        license?: string | null,
        licenser?: string | null,
        licenseCount?: number | null,
        licenseNo?: string | null,
        guaranter?: string | null,
        job?: string | null,
        email?: string | null,
        logoImg?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        areaPrefs?: Array< string | null > | null,
        areas?: Array< string | null > | null,
        areaNames?: Array< string | null > | null,
        eAreas?: Array< string | null > | null,
        eAreaNames?: Array< string | null > | null,
        estateTypes?: Array< string | null > | null,
        bldConditionSelections?: Array< string | null > | null,
        remarks2?: string | null,
        searchInfo: string,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type UpdateCognitoUserFMutationVariables = {
  group: string,
  username: string,
  companyId: string,
  email: string,
  name: string,
  nameRuby: string,
  tel?: string | null,
  role?: string | null,
  agreement?: boolean | null,
  trial?: boolean | null,
};

export type UpdateCognitoUserFMutation = {
  updateCognitoUserF?:  {
    __typename: "CognitoUserResponse",
    item?:  {
      __typename: "CognitoUser",
      Username?: string | null,
      Attributes?:  Array< {
        __typename: "CognitoUserAttribute",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: UserStatusEnum | null,
      MFAOptions?:  Array< {
        __typename: "CognitoUserMFAOption",
        DeliveryMedium?: DeliveryMediumEnum | null,
        AttributeName?: string | null,
      } | null > | null,
      Groups?:  Array< {
        __typename: "CognitoGroup",
        GroupName?: string | null,
        UserPoolId?: string | null,
        Description?: string | null,
        RoleArn?: string | null,
        Precedence?: number | null,
        LastModifiedDate?: string | null,
        CreationDate?: string | null,
      } | null > | null,
      User?:  {
        __typename: "User",
        username: string,
        infoName: string,
        name?: string | null,
        nameRuby?: string | null,
        tel?: string | null,
        role?: string | null,
        agreement?: boolean | null,
        trial?: boolean | null,
        companyId?: string | null,
        defaultCondition?:  {
          __typename: "DefaultCondition",
          estatePriceFrom?: number | null,
          estatePriceTo?: number | null,
          tsuboAreaFrom?: number | null,
          tsuboAreaTo?: number | null,
          completionYearFrom?: string | null,
          completionYearTo?: string | null,
          roomsFrom?: number | null,
          roomsTo?: number | null,
          bldConditionSelections?: Array< string | null > | null,
          petOk?: boolean | null,
          flat?: boolean | null,
          inMyCompany?: boolean | null,
          purposeSummaryBs?: Array< string | null > | null,
          alsoCreatedAtFrom?: number | null,
          alsoCreatedAtTo?: number | null,
        } | null,
        group?: string | null,
        email?: string | null,
        searchInfo?: string | null,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        authenticatedAt?: string | null,
        companyName?: string | null,
        companyBranchName?: string | null,
      } | null,
      Company?:  {
        __typename: "Company",
        id: string,
        infoName: string,
        startMark?: string | null,
        recNo?: number | null,
        name?: string | null,
        branchId?: number | null,
        branchName?: string | null,
        branchTel?: string | null,
        branchFax?: string | null,
        branchUrl?: string | null,
        branchAddress?: string | null,
        address?: string | null,
        tel?: string | null,
        fax?: string | null,
        url?: string | null,
        remarks?: string | null,
        license?: string | null,
        licenser?: string | null,
        licenseCount?: number | null,
        licenseNo?: string | null,
        guaranter?: string | null,
        job?: string | null,
        email?: string | null,
        logoImg?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        areaPrefs?: Array< string | null > | null,
        areas?: Array< string | null > | null,
        areaNames?: Array< string | null > | null,
        eAreas?: Array< string | null > | null,
        eAreaNames?: Array< string | null > | null,
        estateTypes?: Array< string | null > | null,
        bldConditionSelections?: Array< string | null > | null,
        remarks2?: string | null,
        searchInfo: string,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type DeleteCognitoUserFMutationVariables = {
  username: string,
};

export type DeleteCognitoUserFMutation = {
  deleteCognitoUserF?:  {
    __typename: "CognitoUserResponse",
    item?:  {
      __typename: "CognitoUser",
      Username?: string | null,
      Attributes?:  Array< {
        __typename: "CognitoUserAttribute",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: UserStatusEnum | null,
      MFAOptions?:  Array< {
        __typename: "CognitoUserMFAOption",
        DeliveryMedium?: DeliveryMediumEnum | null,
        AttributeName?: string | null,
      } | null > | null,
      Groups?:  Array< {
        __typename: "CognitoGroup",
        GroupName?: string | null,
        UserPoolId?: string | null,
        Description?: string | null,
        RoleArn?: string | null,
        Precedence?: number | null,
        LastModifiedDate?: string | null,
        CreationDate?: string | null,
      } | null > | null,
      User?:  {
        __typename: "User",
        username: string,
        infoName: string,
        name?: string | null,
        nameRuby?: string | null,
        tel?: string | null,
        role?: string | null,
        agreement?: boolean | null,
        trial?: boolean | null,
        companyId?: string | null,
        defaultCondition?:  {
          __typename: "DefaultCondition",
          estatePriceFrom?: number | null,
          estatePriceTo?: number | null,
          tsuboAreaFrom?: number | null,
          tsuboAreaTo?: number | null,
          completionYearFrom?: string | null,
          completionYearTo?: string | null,
          roomsFrom?: number | null,
          roomsTo?: number | null,
          bldConditionSelections?: Array< string | null > | null,
          petOk?: boolean | null,
          flat?: boolean | null,
          inMyCompany?: boolean | null,
          purposeSummaryBs?: Array< string | null > | null,
          alsoCreatedAtFrom?: number | null,
          alsoCreatedAtTo?: number | null,
        } | null,
        group?: string | null,
        email?: string | null,
        searchInfo?: string | null,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        authenticatedAt?: string | null,
        companyName?: string | null,
        companyBranchName?: string | null,
      } | null,
      Company?:  {
        __typename: "Company",
        id: string,
        infoName: string,
        startMark?: string | null,
        recNo?: number | null,
        name?: string | null,
        branchId?: number | null,
        branchName?: string | null,
        branchTel?: string | null,
        branchFax?: string | null,
        branchUrl?: string | null,
        branchAddress?: string | null,
        address?: string | null,
        tel?: string | null,
        fax?: string | null,
        url?: string | null,
        remarks?: string | null,
        license?: string | null,
        licenser?: string | null,
        licenseCount?: number | null,
        licenseNo?: string | null,
        guaranter?: string | null,
        job?: string | null,
        email?: string | null,
        logoImg?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        areaPrefs?: Array< string | null > | null,
        areas?: Array< string | null > | null,
        areaNames?: Array< string | null > | null,
        eAreas?: Array< string | null > | null,
        eAreaNames?: Array< string | null > | null,
        estateTypes?: Array< string | null > | null,
        bldConditionSelections?: Array< string | null > | null,
        remarks2?: string | null,
        searchInfo: string,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type AgreeCognitoUserFMutationVariables = {
  username: string,
  agreement?: boolean | null,
};

export type AgreeCognitoUserFMutation = {
  agreeCognitoUserF?:  {
    __typename: "CognitoUserResponse",
    item?:  {
      __typename: "CognitoUser",
      Username?: string | null,
      Attributes?:  Array< {
        __typename: "CognitoUserAttribute",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: UserStatusEnum | null,
      MFAOptions?:  Array< {
        __typename: "CognitoUserMFAOption",
        DeliveryMedium?: DeliveryMediumEnum | null,
        AttributeName?: string | null,
      } | null > | null,
      Groups?:  Array< {
        __typename: "CognitoGroup",
        GroupName?: string | null,
        UserPoolId?: string | null,
        Description?: string | null,
        RoleArn?: string | null,
        Precedence?: number | null,
        LastModifiedDate?: string | null,
        CreationDate?: string | null,
      } | null > | null,
      User?:  {
        __typename: "User",
        username: string,
        infoName: string,
        name?: string | null,
        nameRuby?: string | null,
        tel?: string | null,
        role?: string | null,
        agreement?: boolean | null,
        trial?: boolean | null,
        companyId?: string | null,
        defaultCondition?:  {
          __typename: "DefaultCondition",
          estatePriceFrom?: number | null,
          estatePriceTo?: number | null,
          tsuboAreaFrom?: number | null,
          tsuboAreaTo?: number | null,
          completionYearFrom?: string | null,
          completionYearTo?: string | null,
          roomsFrom?: number | null,
          roomsTo?: number | null,
          bldConditionSelections?: Array< string | null > | null,
          petOk?: boolean | null,
          flat?: boolean | null,
          inMyCompany?: boolean | null,
          purposeSummaryBs?: Array< string | null > | null,
          alsoCreatedAtFrom?: number | null,
          alsoCreatedAtTo?: number | null,
        } | null,
        group?: string | null,
        email?: string | null,
        searchInfo?: string | null,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        authenticatedAt?: string | null,
        companyName?: string | null,
        companyBranchName?: string | null,
      } | null,
      Company?:  {
        __typename: "Company",
        id: string,
        infoName: string,
        startMark?: string | null,
        recNo?: number | null,
        name?: string | null,
        branchId?: number | null,
        branchName?: string | null,
        branchTel?: string | null,
        branchFax?: string | null,
        branchUrl?: string | null,
        branchAddress?: string | null,
        address?: string | null,
        tel?: string | null,
        fax?: string | null,
        url?: string | null,
        remarks?: string | null,
        license?: string | null,
        licenser?: string | null,
        licenseCount?: number | null,
        licenseNo?: string | null,
        guaranter?: string | null,
        job?: string | null,
        email?: string | null,
        logoImg?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        areaPrefs?: Array< string | null > | null,
        areas?: Array< string | null > | null,
        areaNames?: Array< string | null > | null,
        eAreas?: Array< string | null > | null,
        eAreaNames?: Array< string | null > | null,
        estateTypes?: Array< string | null > | null,
        bldConditionSelections?: Array< string | null > | null,
        remarks2?: string | null,
        searchInfo: string,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type SaveDefaultConditionOfUserFMutationVariables = {
  username: string,
  defaultCondition: DefaultConditionInput,
};

export type SaveDefaultConditionOfUserFMutation = {
  saveDefaultConditionOfUserF?:  {
    __typename: "CognitoUserResponse",
    item?:  {
      __typename: "CognitoUser",
      Username?: string | null,
      Attributes?:  Array< {
        __typename: "CognitoUserAttribute",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: UserStatusEnum | null,
      MFAOptions?:  Array< {
        __typename: "CognitoUserMFAOption",
        DeliveryMedium?: DeliveryMediumEnum | null,
        AttributeName?: string | null,
      } | null > | null,
      Groups?:  Array< {
        __typename: "CognitoGroup",
        GroupName?: string | null,
        UserPoolId?: string | null,
        Description?: string | null,
        RoleArn?: string | null,
        Precedence?: number | null,
        LastModifiedDate?: string | null,
        CreationDate?: string | null,
      } | null > | null,
      User?:  {
        __typename: "User",
        username: string,
        infoName: string,
        name?: string | null,
        nameRuby?: string | null,
        tel?: string | null,
        role?: string | null,
        agreement?: boolean | null,
        trial?: boolean | null,
        companyId?: string | null,
        defaultCondition?:  {
          __typename: "DefaultCondition",
          estatePriceFrom?: number | null,
          estatePriceTo?: number | null,
          tsuboAreaFrom?: number | null,
          tsuboAreaTo?: number | null,
          completionYearFrom?: string | null,
          completionYearTo?: string | null,
          roomsFrom?: number | null,
          roomsTo?: number | null,
          bldConditionSelections?: Array< string | null > | null,
          petOk?: boolean | null,
          flat?: boolean | null,
          inMyCompany?: boolean | null,
          purposeSummaryBs?: Array< string | null > | null,
          alsoCreatedAtFrom?: number | null,
          alsoCreatedAtTo?: number | null,
        } | null,
        group?: string | null,
        email?: string | null,
        searchInfo?: string | null,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        authenticatedAt?: string | null,
        companyName?: string | null,
        companyBranchName?: string | null,
      } | null,
      Company?:  {
        __typename: "Company",
        id: string,
        infoName: string,
        startMark?: string | null,
        recNo?: number | null,
        name?: string | null,
        branchId?: number | null,
        branchName?: string | null,
        branchTel?: string | null,
        branchFax?: string | null,
        branchUrl?: string | null,
        branchAddress?: string | null,
        address?: string | null,
        tel?: string | null,
        fax?: string | null,
        url?: string | null,
        remarks?: string | null,
        license?: string | null,
        licenser?: string | null,
        licenseCount?: number | null,
        licenseNo?: string | null,
        guaranter?: string | null,
        job?: string | null,
        email?: string | null,
        logoImg?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        areaPrefs?: Array< string | null > | null,
        areas?: Array< string | null > | null,
        areaNames?: Array< string | null > | null,
        eAreas?: Array< string | null > | null,
        eAreaNames?: Array< string | null > | null,
        estateTypes?: Array< string | null > | null,
        bldConditionSelections?: Array< string | null > | null,
        remarks2?: string | null,
        searchInfo: string,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type ResetTemporaryPasswordOfCognitoUserFMutationVariables = {
  username: string,
};

export type ResetTemporaryPasswordOfCognitoUserFMutation = {
  resetTemporaryPasswordOfCognitoUserF?:  {
    __typename: "CognitoUserResponse",
    item?:  {
      __typename: "CognitoUser",
      Username?: string | null,
      Attributes?:  Array< {
        __typename: "CognitoUserAttribute",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: UserStatusEnum | null,
      MFAOptions?:  Array< {
        __typename: "CognitoUserMFAOption",
        DeliveryMedium?: DeliveryMediumEnum | null,
        AttributeName?: string | null,
      } | null > | null,
      Groups?:  Array< {
        __typename: "CognitoGroup",
        GroupName?: string | null,
        UserPoolId?: string | null,
        Description?: string | null,
        RoleArn?: string | null,
        Precedence?: number | null,
        LastModifiedDate?: string | null,
        CreationDate?: string | null,
      } | null > | null,
      User?:  {
        __typename: "User",
        username: string,
        infoName: string,
        name?: string | null,
        nameRuby?: string | null,
        tel?: string | null,
        role?: string | null,
        agreement?: boolean | null,
        trial?: boolean | null,
        companyId?: string | null,
        defaultCondition?:  {
          __typename: "DefaultCondition",
          estatePriceFrom?: number | null,
          estatePriceTo?: number | null,
          tsuboAreaFrom?: number | null,
          tsuboAreaTo?: number | null,
          completionYearFrom?: string | null,
          completionYearTo?: string | null,
          roomsFrom?: number | null,
          roomsTo?: number | null,
          bldConditionSelections?: Array< string | null > | null,
          petOk?: boolean | null,
          flat?: boolean | null,
          inMyCompany?: boolean | null,
          purposeSummaryBs?: Array< string | null > | null,
          alsoCreatedAtFrom?: number | null,
          alsoCreatedAtTo?: number | null,
        } | null,
        group?: string | null,
        email?: string | null,
        searchInfo?: string | null,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        authenticatedAt?: string | null,
        companyName?: string | null,
        companyBranchName?: string | null,
      } | null,
      Company?:  {
        __typename: "Company",
        id: string,
        infoName: string,
        startMark?: string | null,
        recNo?: number | null,
        name?: string | null,
        branchId?: number | null,
        branchName?: string | null,
        branchTel?: string | null,
        branchFax?: string | null,
        branchUrl?: string | null,
        branchAddress?: string | null,
        address?: string | null,
        tel?: string | null,
        fax?: string | null,
        url?: string | null,
        remarks?: string | null,
        license?: string | null,
        licenser?: string | null,
        licenseCount?: number | null,
        licenseNo?: string | null,
        guaranter?: string | null,
        job?: string | null,
        email?: string | null,
        logoImg?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        areaPrefs?: Array< string | null > | null,
        areas?: Array< string | null > | null,
        areaNames?: Array< string | null > | null,
        eAreas?: Array< string | null > | null,
        eAreaNames?: Array< string | null > | null,
        estateTypes?: Array< string | null > | null,
        bldConditionSelections?: Array< string | null > | null,
        remarks2?: string | null,
        searchInfo: string,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type UpdateCompanyFMutationVariables = {
  input: UpdateCompanyInput,
};

export type UpdateCompanyFMutation = {
  updateCompanyF?:  {
    __typename: "CompanyMutationResponse",
    item?:  {
      __typename: "Company",
      id: string,
      infoName: string,
      startMark?: string | null,
      recNo?: number | null,
      name?: string | null,
      branchId?: number | null,
      branchName?: string | null,
      branchTel?: string | null,
      branchFax?: string | null,
      branchUrl?: string | null,
      branchAddress?: string | null,
      address?: string | null,
      tel?: string | null,
      fax?: string | null,
      url?: string | null,
      remarks?: string | null,
      license?: string | null,
      licenser?: string | null,
      licenseCount?: number | null,
      licenseNo?: string | null,
      guaranter?: string | null,
      job?: string | null,
      email?: string | null,
      logoImg?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      areaPrefs?: Array< string | null > | null,
      areas?: Array< string | null > | null,
      areaNames?: Array< string | null > | null,
      eAreas?: Array< string | null > | null,
      eAreaNames?: Array< string | null > | null,
      estateTypes?: Array< string | null > | null,
      bldConditionSelections?: Array< string | null > | null,
      remarks2?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type DeleteCompanyFMutationVariables = {
  input: DeleteCompanyInput,
};

export type DeleteCompanyFMutation = {
  deleteCompanyF?:  {
    __typename: "CompanyMutationResponse",
    item?:  {
      __typename: "Company",
      id: string,
      infoName: string,
      startMark?: string | null,
      recNo?: number | null,
      name?: string | null,
      branchId?: number | null,
      branchName?: string | null,
      branchTel?: string | null,
      branchFax?: string | null,
      branchUrl?: string | null,
      branchAddress?: string | null,
      address?: string | null,
      tel?: string | null,
      fax?: string | null,
      url?: string | null,
      remarks?: string | null,
      license?: string | null,
      licenser?: string | null,
      licenseCount?: number | null,
      licenseNo?: string | null,
      guaranter?: string | null,
      job?: string | null,
      email?: string | null,
      logoImg?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      areaPrefs?: Array< string | null > | null,
      areas?: Array< string | null > | null,
      areaNames?: Array< string | null > | null,
      eAreas?: Array< string | null > | null,
      eAreaNames?: Array< string | null > | null,
      estateTypes?: Array< string | null > | null,
      bldConditionSelections?: Array< string | null > | null,
      remarks2?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type CreateEstateFMutationVariables = {
  input: CreateEstateInput,
};

export type CreateEstateFMutation = {
  createEstateF?:  {
    __typename: "EstateMutationResponse",
    item?:  {
      __typename: "Estate",
      id: string,
      infoName: string,
      condominiumId?: string | null,
      confirmed?: boolean | null,
      status?: string | null,
      estateType?: string | null,
      name?: string | null,
      fullAddress?: string | null,
      prefecture?: string | null,
      city?: string | null,
      area?: string | null,
      address?: string | null,
      mediationKind?: string | null,
      currentStatus?: string | null,
      currentStatusFree?: string | null,
      traffic?: string | null,
      eSchool?: Array< string | null > | null,
      jSchool?: Array< string | null > | null,
      remarks?: string | null,
      landAreaType?: string | null,
      squareArea?: number | null,
      tsuboArea?: number | null,
      estatePrice?: number | null,
      unitPrice?: number | null,
      pricedAt?: string | null,
      priceHistory?:  Array< {
        __typename: "Price",
        price?: number | null,
        pricedAt?: string | null,
      } | null > | null,
      completionSchMonth?: number | null,
      completionMonth?: number | null,
      clearingType?: string | null,
      cleaningMonth?: number | null,
      bldConditionType?: string | null,
      landRights?: string | null,
      cityPlanningType?: string | null,
      useDistrict?: Array< string | null > | null,
      floorAreaRatio?: number | null,
      bcr?: number | null,
      roadType?: string | null,
      drivewayPay?: string | null,
      roadDirection?: string | null,
      roadWidth?: number | null,
      water?: string | null,
      sewer?: string | null,
      gas?: string | null,
      groundType?: Array< string | null > | null,
      groundTypeFree?: string | null,
      layout?: string | null,
      totalArea?: number | null,
      structure?: string | null,
      structureFree?: string | null,
      buildingFloors?: string | null,
      balconyArea?: number | null,
      managementType?: string | null,
      pet?: string | null,
      floor?: number | null,
      room?: string | null,
      managementFee?: number | null,
      repairCost?: number | null,
      otherCost?: number | null,
      parking?: string | null,
      carsInParkingN?: number | null,
      parkingTakeOver?: string | null,
      parkingTakeOverFree?: string | null,
      parkingMechOrPlane?: string | null,
      parkingHeight?: number | null,
      parkingFee?: number | null,
      purposeB?: string | null,
      purposeSummaryB?: string | null,
      companyId?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      companyTel?: string | null,
      companyFax?: string | null,
      companyUrl?: string | null,
      companyLicense?: string | null,
      companyGuaranter?: string | null,
      subCompanyId?: string | null,
      subCompanyName?: string | null,
      subCompanyAddress?: string | null,
      subCompanyTel?: string | null,
      subCompanyFax?: string | null,
      subCompanyUrl?: string | null,
      subCompanyLicense?: string | null,
      subCompanyGuaranter?: string | null,
      subCompany2Id?: string | null,
      subCompany2Name?: string | null,
      subCompany2Address?: string | null,
      subCompany2Tel?: string | null,
      subCompany2Fax?: string | null,
      subCompany2Url?: string | null,
      subCompany2License?: string | null,
      subCompany2Guaranter?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      tileOnZoom12?: string | null,
      tileOnZoom14?: string | null,
      tileOnZoom16?: string | null,
      layoutImgs?: Array< string | null > | null,
      appearanceImgs?: Array< string | null > | null,
      mapImg?: string | null,
      waterPdf?: string | null,
      sewerPdf?: string | null,
      gasPdf?: string | null,
      cadastralMapPdf?: string | null,
      groundPdf?: string | null,
      url?: string | null,
      license?: string | null,
      recNo?: number | null,
      media?: string | null,
      location?: string | null,
      collectedAt?: string | null,
      floorStructure?: string | null,
      buildingCompany?: string | null,
      notFoundAt?: string | null,
      exactLocation?: number | null,
      nearlyLocation?: number | null,
      unsettledLocation?: number | null,
      isLot?: number | null,
      currentStatusB?: string | null,
      currentStatusG?: string | null,
      companyRecNo?: number | null,
      companyBranchId?: number | null,
      subCompanyRecNo?: number | null,
      completionMonthS?: string | null,
      condominiumName?: string | null,
      condominiumRecNo?: number | null,
      totalHouses?: number | null,
      manager?: string | null,
      seller?: string | null,
      estateUrl?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sold?: boolean | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type UpdateEstateFMutationVariables = {
  input: UpdateEstateInput,
};

export type UpdateEstateFMutation = {
  updateEstateF?:  {
    __typename: "EstateMutationResponse",
    item?:  {
      __typename: "Estate",
      id: string,
      infoName: string,
      condominiumId?: string | null,
      confirmed?: boolean | null,
      status?: string | null,
      estateType?: string | null,
      name?: string | null,
      fullAddress?: string | null,
      prefecture?: string | null,
      city?: string | null,
      area?: string | null,
      address?: string | null,
      mediationKind?: string | null,
      currentStatus?: string | null,
      currentStatusFree?: string | null,
      traffic?: string | null,
      eSchool?: Array< string | null > | null,
      jSchool?: Array< string | null > | null,
      remarks?: string | null,
      landAreaType?: string | null,
      squareArea?: number | null,
      tsuboArea?: number | null,
      estatePrice?: number | null,
      unitPrice?: number | null,
      pricedAt?: string | null,
      priceHistory?:  Array< {
        __typename: "Price",
        price?: number | null,
        pricedAt?: string | null,
      } | null > | null,
      completionSchMonth?: number | null,
      completionMonth?: number | null,
      clearingType?: string | null,
      cleaningMonth?: number | null,
      bldConditionType?: string | null,
      landRights?: string | null,
      cityPlanningType?: string | null,
      useDistrict?: Array< string | null > | null,
      floorAreaRatio?: number | null,
      bcr?: number | null,
      roadType?: string | null,
      drivewayPay?: string | null,
      roadDirection?: string | null,
      roadWidth?: number | null,
      water?: string | null,
      sewer?: string | null,
      gas?: string | null,
      groundType?: Array< string | null > | null,
      groundTypeFree?: string | null,
      layout?: string | null,
      totalArea?: number | null,
      structure?: string | null,
      structureFree?: string | null,
      buildingFloors?: string | null,
      balconyArea?: number | null,
      managementType?: string | null,
      pet?: string | null,
      floor?: number | null,
      room?: string | null,
      managementFee?: number | null,
      repairCost?: number | null,
      otherCost?: number | null,
      parking?: string | null,
      carsInParkingN?: number | null,
      parkingTakeOver?: string | null,
      parkingTakeOverFree?: string | null,
      parkingMechOrPlane?: string | null,
      parkingHeight?: number | null,
      parkingFee?: number | null,
      purposeB?: string | null,
      purposeSummaryB?: string | null,
      companyId?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      companyTel?: string | null,
      companyFax?: string | null,
      companyUrl?: string | null,
      companyLicense?: string | null,
      companyGuaranter?: string | null,
      subCompanyId?: string | null,
      subCompanyName?: string | null,
      subCompanyAddress?: string | null,
      subCompanyTel?: string | null,
      subCompanyFax?: string | null,
      subCompanyUrl?: string | null,
      subCompanyLicense?: string | null,
      subCompanyGuaranter?: string | null,
      subCompany2Id?: string | null,
      subCompany2Name?: string | null,
      subCompany2Address?: string | null,
      subCompany2Tel?: string | null,
      subCompany2Fax?: string | null,
      subCompany2Url?: string | null,
      subCompany2License?: string | null,
      subCompany2Guaranter?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      tileOnZoom12?: string | null,
      tileOnZoom14?: string | null,
      tileOnZoom16?: string | null,
      layoutImgs?: Array< string | null > | null,
      appearanceImgs?: Array< string | null > | null,
      mapImg?: string | null,
      waterPdf?: string | null,
      sewerPdf?: string | null,
      gasPdf?: string | null,
      cadastralMapPdf?: string | null,
      groundPdf?: string | null,
      url?: string | null,
      license?: string | null,
      recNo?: number | null,
      media?: string | null,
      location?: string | null,
      collectedAt?: string | null,
      floorStructure?: string | null,
      buildingCompany?: string | null,
      notFoundAt?: string | null,
      exactLocation?: number | null,
      nearlyLocation?: number | null,
      unsettledLocation?: number | null,
      isLot?: number | null,
      currentStatusB?: string | null,
      currentStatusG?: string | null,
      companyRecNo?: number | null,
      companyBranchId?: number | null,
      subCompanyRecNo?: number | null,
      completionMonthS?: string | null,
      condominiumName?: string | null,
      condominiumRecNo?: number | null,
      totalHouses?: number | null,
      manager?: string | null,
      seller?: string | null,
      estateUrl?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sold?: boolean | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type DeleteEstateFMutationVariables = {
  input: DeleteEstateInput,
};

export type DeleteEstateFMutation = {
  deleteEstateF?:  {
    __typename: "EstateMutationResponse",
    item?:  {
      __typename: "Estate",
      id: string,
      infoName: string,
      condominiumId?: string | null,
      confirmed?: boolean | null,
      status?: string | null,
      estateType?: string | null,
      name?: string | null,
      fullAddress?: string | null,
      prefecture?: string | null,
      city?: string | null,
      area?: string | null,
      address?: string | null,
      mediationKind?: string | null,
      currentStatus?: string | null,
      currentStatusFree?: string | null,
      traffic?: string | null,
      eSchool?: Array< string | null > | null,
      jSchool?: Array< string | null > | null,
      remarks?: string | null,
      landAreaType?: string | null,
      squareArea?: number | null,
      tsuboArea?: number | null,
      estatePrice?: number | null,
      unitPrice?: number | null,
      pricedAt?: string | null,
      priceHistory?:  Array< {
        __typename: "Price",
        price?: number | null,
        pricedAt?: string | null,
      } | null > | null,
      completionSchMonth?: number | null,
      completionMonth?: number | null,
      clearingType?: string | null,
      cleaningMonth?: number | null,
      bldConditionType?: string | null,
      landRights?: string | null,
      cityPlanningType?: string | null,
      useDistrict?: Array< string | null > | null,
      floorAreaRatio?: number | null,
      bcr?: number | null,
      roadType?: string | null,
      drivewayPay?: string | null,
      roadDirection?: string | null,
      roadWidth?: number | null,
      water?: string | null,
      sewer?: string | null,
      gas?: string | null,
      groundType?: Array< string | null > | null,
      groundTypeFree?: string | null,
      layout?: string | null,
      totalArea?: number | null,
      structure?: string | null,
      structureFree?: string | null,
      buildingFloors?: string | null,
      balconyArea?: number | null,
      managementType?: string | null,
      pet?: string | null,
      floor?: number | null,
      room?: string | null,
      managementFee?: number | null,
      repairCost?: number | null,
      otherCost?: number | null,
      parking?: string | null,
      carsInParkingN?: number | null,
      parkingTakeOver?: string | null,
      parkingTakeOverFree?: string | null,
      parkingMechOrPlane?: string | null,
      parkingHeight?: number | null,
      parkingFee?: number | null,
      purposeB?: string | null,
      purposeSummaryB?: string | null,
      companyId?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      companyTel?: string | null,
      companyFax?: string | null,
      companyUrl?: string | null,
      companyLicense?: string | null,
      companyGuaranter?: string | null,
      subCompanyId?: string | null,
      subCompanyName?: string | null,
      subCompanyAddress?: string | null,
      subCompanyTel?: string | null,
      subCompanyFax?: string | null,
      subCompanyUrl?: string | null,
      subCompanyLicense?: string | null,
      subCompanyGuaranter?: string | null,
      subCompany2Id?: string | null,
      subCompany2Name?: string | null,
      subCompany2Address?: string | null,
      subCompany2Tel?: string | null,
      subCompany2Fax?: string | null,
      subCompany2Url?: string | null,
      subCompany2License?: string | null,
      subCompany2Guaranter?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      tileOnZoom12?: string | null,
      tileOnZoom14?: string | null,
      tileOnZoom16?: string | null,
      layoutImgs?: Array< string | null > | null,
      appearanceImgs?: Array< string | null > | null,
      mapImg?: string | null,
      waterPdf?: string | null,
      sewerPdf?: string | null,
      gasPdf?: string | null,
      cadastralMapPdf?: string | null,
      groundPdf?: string | null,
      url?: string | null,
      license?: string | null,
      recNo?: number | null,
      media?: string | null,
      location?: string | null,
      collectedAt?: string | null,
      floorStructure?: string | null,
      buildingCompany?: string | null,
      notFoundAt?: string | null,
      exactLocation?: number | null,
      nearlyLocation?: number | null,
      unsettledLocation?: number | null,
      isLot?: number | null,
      currentStatusB?: string | null,
      currentStatusG?: string | null,
      companyRecNo?: number | null,
      companyBranchId?: number | null,
      subCompanyRecNo?: number | null,
      completionMonthS?: string | null,
      condominiumName?: string | null,
      condominiumRecNo?: number | null,
      totalHouses?: number | null,
      manager?: string | null,
      seller?: string | null,
      estateUrl?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sold?: boolean | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type ConvertEstateGroundToLotFMutationVariables = {
  input: DeleteEstateInput,
};

export type ConvertEstateGroundToLotFMutation = {
  convertEstateGroundToLotF?:  {
    __typename: "EstateMutationResponse",
    item?:  {
      __typename: "Estate",
      id: string,
      infoName: string,
      condominiumId?: string | null,
      confirmed?: boolean | null,
      status?: string | null,
      estateType?: string | null,
      name?: string | null,
      fullAddress?: string | null,
      prefecture?: string | null,
      city?: string | null,
      area?: string | null,
      address?: string | null,
      mediationKind?: string | null,
      currentStatus?: string | null,
      currentStatusFree?: string | null,
      traffic?: string | null,
      eSchool?: Array< string | null > | null,
      jSchool?: Array< string | null > | null,
      remarks?: string | null,
      landAreaType?: string | null,
      squareArea?: number | null,
      tsuboArea?: number | null,
      estatePrice?: number | null,
      unitPrice?: number | null,
      pricedAt?: string | null,
      priceHistory?:  Array< {
        __typename: "Price",
        price?: number | null,
        pricedAt?: string | null,
      } | null > | null,
      completionSchMonth?: number | null,
      completionMonth?: number | null,
      clearingType?: string | null,
      cleaningMonth?: number | null,
      bldConditionType?: string | null,
      landRights?: string | null,
      cityPlanningType?: string | null,
      useDistrict?: Array< string | null > | null,
      floorAreaRatio?: number | null,
      bcr?: number | null,
      roadType?: string | null,
      drivewayPay?: string | null,
      roadDirection?: string | null,
      roadWidth?: number | null,
      water?: string | null,
      sewer?: string | null,
      gas?: string | null,
      groundType?: Array< string | null > | null,
      groundTypeFree?: string | null,
      layout?: string | null,
      totalArea?: number | null,
      structure?: string | null,
      structureFree?: string | null,
      buildingFloors?: string | null,
      balconyArea?: number | null,
      managementType?: string | null,
      pet?: string | null,
      floor?: number | null,
      room?: string | null,
      managementFee?: number | null,
      repairCost?: number | null,
      otherCost?: number | null,
      parking?: string | null,
      carsInParkingN?: number | null,
      parkingTakeOver?: string | null,
      parkingTakeOverFree?: string | null,
      parkingMechOrPlane?: string | null,
      parkingHeight?: number | null,
      parkingFee?: number | null,
      purposeB?: string | null,
      purposeSummaryB?: string | null,
      companyId?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      companyTel?: string | null,
      companyFax?: string | null,
      companyUrl?: string | null,
      companyLicense?: string | null,
      companyGuaranter?: string | null,
      subCompanyId?: string | null,
      subCompanyName?: string | null,
      subCompanyAddress?: string | null,
      subCompanyTel?: string | null,
      subCompanyFax?: string | null,
      subCompanyUrl?: string | null,
      subCompanyLicense?: string | null,
      subCompanyGuaranter?: string | null,
      subCompany2Id?: string | null,
      subCompany2Name?: string | null,
      subCompany2Address?: string | null,
      subCompany2Tel?: string | null,
      subCompany2Fax?: string | null,
      subCompany2Url?: string | null,
      subCompany2License?: string | null,
      subCompany2Guaranter?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      tileOnZoom12?: string | null,
      tileOnZoom14?: string | null,
      tileOnZoom16?: string | null,
      layoutImgs?: Array< string | null > | null,
      appearanceImgs?: Array< string | null > | null,
      mapImg?: string | null,
      waterPdf?: string | null,
      sewerPdf?: string | null,
      gasPdf?: string | null,
      cadastralMapPdf?: string | null,
      groundPdf?: string | null,
      url?: string | null,
      license?: string | null,
      recNo?: number | null,
      media?: string | null,
      location?: string | null,
      collectedAt?: string | null,
      floorStructure?: string | null,
      buildingCompany?: string | null,
      notFoundAt?: string | null,
      exactLocation?: number | null,
      nearlyLocation?: number | null,
      unsettledLocation?: number | null,
      isLot?: number | null,
      currentStatusB?: string | null,
      currentStatusG?: string | null,
      companyRecNo?: number | null,
      companyBranchId?: number | null,
      subCompanyRecNo?: number | null,
      completionMonthS?: string | null,
      condominiumName?: string | null,
      condominiumRecNo?: number | null,
      totalHouses?: number | null,
      manager?: string | null,
      seller?: string | null,
      estateUrl?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sold?: boolean | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type ConvertEstateLotToGroundFMutationVariables = {
  input: DeleteEstateInput,
};

export type ConvertEstateLotToGroundFMutation = {
  convertEstateLotToGroundF?:  {
    __typename: "EstateMutationResponse",
    item?:  {
      __typename: "Estate",
      id: string,
      infoName: string,
      condominiumId?: string | null,
      confirmed?: boolean | null,
      status?: string | null,
      estateType?: string | null,
      name?: string | null,
      fullAddress?: string | null,
      prefecture?: string | null,
      city?: string | null,
      area?: string | null,
      address?: string | null,
      mediationKind?: string | null,
      currentStatus?: string | null,
      currentStatusFree?: string | null,
      traffic?: string | null,
      eSchool?: Array< string | null > | null,
      jSchool?: Array< string | null > | null,
      remarks?: string | null,
      landAreaType?: string | null,
      squareArea?: number | null,
      tsuboArea?: number | null,
      estatePrice?: number | null,
      unitPrice?: number | null,
      pricedAt?: string | null,
      priceHistory?:  Array< {
        __typename: "Price",
        price?: number | null,
        pricedAt?: string | null,
      } | null > | null,
      completionSchMonth?: number | null,
      completionMonth?: number | null,
      clearingType?: string | null,
      cleaningMonth?: number | null,
      bldConditionType?: string | null,
      landRights?: string | null,
      cityPlanningType?: string | null,
      useDistrict?: Array< string | null > | null,
      floorAreaRatio?: number | null,
      bcr?: number | null,
      roadType?: string | null,
      drivewayPay?: string | null,
      roadDirection?: string | null,
      roadWidth?: number | null,
      water?: string | null,
      sewer?: string | null,
      gas?: string | null,
      groundType?: Array< string | null > | null,
      groundTypeFree?: string | null,
      layout?: string | null,
      totalArea?: number | null,
      structure?: string | null,
      structureFree?: string | null,
      buildingFloors?: string | null,
      balconyArea?: number | null,
      managementType?: string | null,
      pet?: string | null,
      floor?: number | null,
      room?: string | null,
      managementFee?: number | null,
      repairCost?: number | null,
      otherCost?: number | null,
      parking?: string | null,
      carsInParkingN?: number | null,
      parkingTakeOver?: string | null,
      parkingTakeOverFree?: string | null,
      parkingMechOrPlane?: string | null,
      parkingHeight?: number | null,
      parkingFee?: number | null,
      purposeB?: string | null,
      purposeSummaryB?: string | null,
      companyId?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      companyTel?: string | null,
      companyFax?: string | null,
      companyUrl?: string | null,
      companyLicense?: string | null,
      companyGuaranter?: string | null,
      subCompanyId?: string | null,
      subCompanyName?: string | null,
      subCompanyAddress?: string | null,
      subCompanyTel?: string | null,
      subCompanyFax?: string | null,
      subCompanyUrl?: string | null,
      subCompanyLicense?: string | null,
      subCompanyGuaranter?: string | null,
      subCompany2Id?: string | null,
      subCompany2Name?: string | null,
      subCompany2Address?: string | null,
      subCompany2Tel?: string | null,
      subCompany2Fax?: string | null,
      subCompany2Url?: string | null,
      subCompany2License?: string | null,
      subCompany2Guaranter?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      tileOnZoom12?: string | null,
      tileOnZoom14?: string | null,
      tileOnZoom16?: string | null,
      layoutImgs?: Array< string | null > | null,
      appearanceImgs?: Array< string | null > | null,
      mapImg?: string | null,
      waterPdf?: string | null,
      sewerPdf?: string | null,
      gasPdf?: string | null,
      cadastralMapPdf?: string | null,
      groundPdf?: string | null,
      url?: string | null,
      license?: string | null,
      recNo?: number | null,
      media?: string | null,
      location?: string | null,
      collectedAt?: string | null,
      floorStructure?: string | null,
      buildingCompany?: string | null,
      notFoundAt?: string | null,
      exactLocation?: number | null,
      nearlyLocation?: number | null,
      unsettledLocation?: number | null,
      isLot?: number | null,
      currentStatusB?: string | null,
      currentStatusG?: string | null,
      companyRecNo?: number | null,
      companyBranchId?: number | null,
      subCompanyRecNo?: number | null,
      completionMonthS?: string | null,
      condominiumName?: string | null,
      condominiumRecNo?: number | null,
      totalHouses?: number | null,
      manager?: string | null,
      seller?: string | null,
      estateUrl?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sold?: boolean | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type NotifyEstateCMutationVariables = {
  input: NotifyEstateInput,
};

export type NotifyEstateCMutation = {
  notifyEstateC?:  {
    __typename: "EstateNotifyResponse",
    id?: string | null,
    infoName?: string | null,
    condominiumId?: string | null,
    confirmed?: boolean | null,
    status?: string | null,
    estateType?: string | null,
    name?: string | null,
    fullAddress?: string | null,
    prefecture?: string | null,
    city?: string | null,
    area?: string | null,
    address?: string | null,
    mediationKind?: string | null,
    currentStatus?: string | null,
    currentStatusFree?: string | null,
    traffic?: string | null,
    eSchool?: Array< string | null > | null,
    jSchool?: Array< string | null > | null,
    remarks?: string | null,
    landAreaType?: string | null,
    squareArea?: number | null,
    tsuboArea?: number | null,
    estatePrice?: number | null,
    unitPrice?: number | null,
    pricedAt?: string | null,
    priceHistory?:  Array< {
      __typename: "Price",
      price?: number | null,
      pricedAt?: string | null,
    } | null > | null,
    completionSchMonth?: number | null,
    completionMonth?: number | null,
    clearingType?: string | null,
    cleaningMonth?: number | null,
    bldConditionType?: string | null,
    landRights?: string | null,
    cityPlanningType?: string | null,
    useDistrict?: Array< string | null > | null,
    floorAreaRatio?: number | null,
    bcr?: number | null,
    roadType?: string | null,
    drivewayPay?: string | null,
    roadDirection?: string | null,
    roadWidth?: number | null,
    water?: string | null,
    sewer?: string | null,
    gas?: string | null,
    groundType?: Array< string | null > | null,
    groundTypeFree?: string | null,
    layout?: string | null,
    totalArea?: number | null,
    structure?: string | null,
    structureFree?: string | null,
    buildingFloors?: string | null,
    balconyArea?: number | null,
    managementType?: string | null,
    pet?: string | null,
    floor?: number | null,
    room?: string | null,
    managementFee?: number | null,
    repairCost?: number | null,
    otherCost?: number | null,
    parking?: string | null,
    carsInParkingN?: number | null,
    parkingTakeOver?: string | null,
    parkingTakeOverFree?: string | null,
    parkingMechOrPlane?: string | null,
    parkingHeight?: number | null,
    parkingFee?: number | null,
    purposeB?: string | null,
    purposeSummaryB?: string | null,
    companyId?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    companyTel?: string | null,
    companyFax?: string | null,
    companyUrl?: string | null,
    companyLicense?: string | null,
    companyGuaranter?: string | null,
    subCompanyId?: string | null,
    subCompanyName?: string | null,
    subCompanyAddress?: string | null,
    subCompanyTel?: string | null,
    subCompanyFax?: string | null,
    subCompanyUrl?: string | null,
    subCompanyLicense?: string | null,
    subCompanyGuaranter?: string | null,
    subCompany2Id?: string | null,
    subCompany2Name?: string | null,
    subCompany2Address?: string | null,
    subCompany2Tel?: string | null,
    subCompany2Fax?: string | null,
    subCompany2Url?: string | null,
    subCompany2License?: string | null,
    subCompany2Guaranter?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    tileOnZoom12?: string | null,
    tileOnZoom14?: string | null,
    tileOnZoom16?: string | null,
    layoutImgs?: Array< string | null > | null,
    appearanceImgs?: Array< string | null > | null,
    mapImg?: string | null,
    waterPdf?: string | null,
    sewerPdf?: string | null,
    gasPdf?: string | null,
    cadastralMapPdf?: string | null,
    groundPdf?: string | null,
    url?: string | null,
    license?: string | null,
    recNo?: number | null,
    media?: string | null,
    location?: string | null,
    collectedAt?: string | null,
    floorStructure?: string | null,
    buildingCompany?: string | null,
    notFoundAt?: string | null,
    exactLocation?: number | null,
    nearlyLocation?: number | null,
    unsettledLocation?: number | null,
    isLot?: number | null,
    currentStatusB?: string | null,
    currentStatusG?: string | null,
    companyRecNo?: number | null,
    companyBranchId?: number | null,
    subCompanyRecNo?: number | null,
    completionMonthS?: string | null,
    condominiumName?: string | null,
    condominiumRecNo?: number | null,
    totalHouses?: number | null,
    manager?: string | null,
    seller?: string | null,
    estateUrl?: string | null,
    searchInfo?: string | null,
    owner?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sold?: boolean | null,
  } | null,
};

export type CreateStickyNoteFMutationVariables = {
  input: CreateStickyNoteInput,
};

export type CreateStickyNoteFMutation = {
  createStickyNoteF?:  {
    __typename: "StickyNoteMutationResponse",
    item?:  {
      __typename: "StickyNote",
      id: string,
      infoName: string,
      memoKind: string,
      openLevel: string,
      icon?: StickyNoteIcon | null,
      color?: StickyNoteColor | null,
      description?: string | null,
      companyId: string,
      latitude: number,
      longitude: number,
      tileOnZoom12: string,
      tileOnZoom14: string,
      tileOnZoom16: string,
      searchInfo: string,
      owner: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type UpdateStickyNoteFMutationVariables = {
  input: UpdateStickyNoteInput,
};

export type UpdateStickyNoteFMutation = {
  updateStickyNoteF?:  {
    __typename: "StickyNoteMutationResponse",
    item?:  {
      __typename: "StickyNote",
      id: string,
      infoName: string,
      memoKind: string,
      openLevel: string,
      icon?: StickyNoteIcon | null,
      color?: StickyNoteColor | null,
      description?: string | null,
      companyId: string,
      latitude: number,
      longitude: number,
      tileOnZoom12: string,
      tileOnZoom14: string,
      tileOnZoom16: string,
      searchInfo: string,
      owner: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type DeleteStickyNoteFMutationVariables = {
  input: DeleteStickyNoteInput,
};

export type DeleteStickyNoteFMutation = {
  deleteStickyNoteF?:  {
    __typename: "StickyNoteMutationResponse",
    item?:  {
      __typename: "StickyNote",
      id: string,
      infoName: string,
      memoKind: string,
      openLevel: string,
      icon?: StickyNoteIcon | null,
      color?: StickyNoteColor | null,
      description?: string | null,
      companyId: string,
      latitude: number,
      longitude: number,
      tileOnZoom12: string,
      tileOnZoom14: string,
      tileOnZoom16: string,
      searchInfo: string,
      owner: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type InvokeExportUserCsvMutationVariables = {
  todayOnly?: boolean | null,
};

export type InvokeExportUserCsvMutation = {
  invokeExportUserCsv?: string | null,
};

export type CreatePublicEstateFMutationVariables = {
  input: CreatePublicEstateInput,
};

export type CreatePublicEstateFMutation = {
  createPublicEstateF?:  {
    __typename: "PublicEstateMutationResponse",
    id: string,
  } | null,
};

export type CreatePublicEstatePdfFMutationVariables = {
  input: CreatePublicEstatePdfInput,
};

export type CreatePublicEstatePdfFMutation = {
  createPublicEstatePdfF?:  {
    __typename: "PublicEstatePdfMutationResponse",
    pdf: string,
  } | null,
};

export type CreateAnnounceMutationVariables = {
  input: CreateAnnounceInput,
  condition?: ModelAnnounceConditionInput | null,
};

export type CreateAnnounceMutation = {
  createAnnounce?:  {
    __typename: "Announce",
    id: string,
    level: AnnounceLevel,
    subject: string,
    body: string,
    ym: string,
    owner: string,
    createdAt: string,
    updatedAt?: string | null,
    expiredAt?: number | null,
  } | null,
};

export type UpdateAnnounceMutationVariables = {
  input: UpdateAnnounceInput,
  condition?: ModelAnnounceConditionInput | null,
};

export type UpdateAnnounceMutation = {
  updateAnnounce?:  {
    __typename: "Announce",
    id: string,
    level: AnnounceLevel,
    subject: string,
    body: string,
    ym: string,
    owner: string,
    createdAt: string,
    updatedAt?: string | null,
    expiredAt?: number | null,
  } | null,
};

export type DeleteAnnounceMutationVariables = {
  input: DeleteAnnounceInput,
  condition?: ModelAnnounceConditionInput | null,
};

export type DeleteAnnounceMutation = {
  deleteAnnounce?:  {
    __typename: "Announce",
    id: string,
    level: AnnounceLevel,
    subject: string,
    body: string,
    ym: string,
    owner: string,
    createdAt: string,
    updatedAt?: string | null,
    expiredAt?: number | null,
  } | null,
};

export type CreateBatchJobMutationVariables = {
  input: CreateBatchJobInput,
  condition?: ModelBatchJobConditionInput | null,
};

export type CreateBatchJobMutation = {
  createBatchJob?:  {
    __typename: "BatchJob",
    id: string,
    jobName: string,
    remarks: string,
    status: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    expiredAt?: number | null,
  } | null,
};

export type CreateBatchJobConfMutationVariables = {
  input: CreateBatchJobConfInput,
  condition?: ModelBatchJobConfConditionInput | null,
};

export type CreateBatchJobConfMutation = {
  createBatchJobConf?:  {
    __typename: "BatchJobConf",
    jobName: string,
    remarks: string,
    allowedGroups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateBatchJobConfMutationVariables = {
  input: UpdateBatchJobConfInput,
  condition?: ModelBatchJobConfConditionInput | null,
};

export type UpdateBatchJobConfMutation = {
  updateBatchJobConf?:  {
    __typename: "BatchJobConf",
    jobName: string,
    remarks: string,
    allowedGroups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteBatchJobConfMutationVariables = {
  input: DeleteBatchJobConfInput,
  condition?: ModelBatchJobConfConditionInput | null,
};

export type DeleteBatchJobConfMutation = {
  deleteBatchJobConf?:  {
    __typename: "BatchJobConf",
    jobName: string,
    remarks: string,
    allowedGroups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    id: string,
    estateId: string,
    estateName?: string | null,
    reportKind: string,
    description?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    companyId: string,
    companyName: string,
    userName: string,
    owner: string,
    createdAt: string,
    updatedAt?: string | null,
    expiredAt?: number | null,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    id: string,
    estateId: string,
    estateName?: string | null,
    reportKind: string,
    description?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    companyId: string,
    companyName: string,
    userName: string,
    owner: string,
    createdAt: string,
    updatedAt?: string | null,
    expiredAt?: number | null,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    id: string,
    estateId: string,
    estateName?: string | null,
    reportKind: string,
    description?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    companyId: string,
    companyName: string,
    userName: string,
    owner: string,
    createdAt: string,
    updatedAt?: string | null,
    expiredAt?: number | null,
  } | null,
};

export type CreateScrapingMutationVariables = {
  input: CreateScrapingInput,
  condition?: ModelScrapingConditionInput | null,
};

export type CreateScrapingMutation = {
  createScraping?:  {
    __typename: "Scraping",
    baseUrl: string,
    prefName: string,
    status: string,
    operation?: string | null,
    input?: string | null,
    startedAt?: string | null,
    totalCount?: number | null,
    updating?:  {
      __typename: "ScrapingUpdateDetail",
      newRecord?: number | null,
      updatePrice?: number | null,
      expired?: number | null,
      return?: number | null,
      returnAndUpdatePrice?: number | null,
      scrapingFailed?: number | null,
      requiresAuth?: number | null,
      blocked?: number | null,
      updateFailed?: number | null,
    } | null,
    updated?:  {
      __typename: "ScrapingUpdateDetail",
      newRecord?: number | null,
      updatePrice?: number | null,
      expired?: number | null,
      return?: number | null,
      returnAndUpdatePrice?: number | null,
      scrapingFailed?: number | null,
      requiresAuth?: number | null,
      blocked?: number | null,
      updateFailed?: number | null,
    } | null,
    resultKey?: string | null,
    endedAt?: string | null,
    output?: string | null,
    remarks?: string | null,
    sortKey: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateScrapingMutationVariables = {
  input: UpdateScrapingInput,
  condition?: ModelScrapingConditionInput | null,
};

export type UpdateScrapingMutation = {
  updateScraping?:  {
    __typename: "Scraping",
    baseUrl: string,
    prefName: string,
    status: string,
    operation?: string | null,
    input?: string | null,
    startedAt?: string | null,
    totalCount?: number | null,
    updating?:  {
      __typename: "ScrapingUpdateDetail",
      newRecord?: number | null,
      updatePrice?: number | null,
      expired?: number | null,
      return?: number | null,
      returnAndUpdatePrice?: number | null,
      scrapingFailed?: number | null,
      requiresAuth?: number | null,
      blocked?: number | null,
      updateFailed?: number | null,
    } | null,
    updated?:  {
      __typename: "ScrapingUpdateDetail",
      newRecord?: number | null,
      updatePrice?: number | null,
      expired?: number | null,
      return?: number | null,
      returnAndUpdatePrice?: number | null,
      scrapingFailed?: number | null,
      requiresAuth?: number | null,
      blocked?: number | null,
      updateFailed?: number | null,
    } | null,
    resultKey?: string | null,
    endedAt?: string | null,
    output?: string | null,
    remarks?: string | null,
    sortKey: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListCognitoUsersFQueryVariables = {
  groups?: Array< string | null > | null,
  username?: string | null,
  name?: string | null,
  nameRuby?: string | null,
  companyIds?: Array< string | null > | null,
  email?: string | null,
  trial?: boolean | null,
  nextToken?: string | null,
};

export type ListCognitoUsersFQuery = {
  listCognitoUsersF?:  {
    __typename: "ModelCognitoUserConnection",
    items?:  Array< {
      __typename: "CognitoUser",
      Username?: string | null,
      Attributes?:  Array< {
        __typename: "CognitoUserAttribute",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: UserStatusEnum | null,
      MFAOptions?:  Array< {
        __typename: "CognitoUserMFAOption",
        DeliveryMedium?: DeliveryMediumEnum | null,
        AttributeName?: string | null,
      } | null > | null,
      Groups?:  Array< {
        __typename: "CognitoGroup",
        GroupName?: string | null,
        UserPoolId?: string | null,
        Description?: string | null,
        RoleArn?: string | null,
        Precedence?: number | null,
        LastModifiedDate?: string | null,
        CreationDate?: string | null,
      } | null > | null,
      User?:  {
        __typename: "User",
        username: string,
        infoName: string,
        name?: string | null,
        nameRuby?: string | null,
        tel?: string | null,
        role?: string | null,
        agreement?: boolean | null,
        trial?: boolean | null,
        companyId?: string | null,
        defaultCondition?:  {
          __typename: "DefaultCondition",
          estatePriceFrom?: number | null,
          estatePriceTo?: number | null,
          tsuboAreaFrom?: number | null,
          tsuboAreaTo?: number | null,
          completionYearFrom?: string | null,
          completionYearTo?: string | null,
          roomsFrom?: number | null,
          roomsTo?: number | null,
          bldConditionSelections?: Array< string | null > | null,
          petOk?: boolean | null,
          flat?: boolean | null,
          inMyCompany?: boolean | null,
          purposeSummaryBs?: Array< string | null > | null,
          alsoCreatedAtFrom?: number | null,
          alsoCreatedAtTo?: number | null,
        } | null,
        group?: string | null,
        email?: string | null,
        searchInfo?: string | null,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        authenticatedAt?: string | null,
        companyName?: string | null,
        companyBranchName?: string | null,
      } | null,
      Company?:  {
        __typename: "Company",
        id: string,
        infoName: string,
        startMark?: string | null,
        recNo?: number | null,
        name?: string | null,
        branchId?: number | null,
        branchName?: string | null,
        branchTel?: string | null,
        branchFax?: string | null,
        branchUrl?: string | null,
        branchAddress?: string | null,
        address?: string | null,
        tel?: string | null,
        fax?: string | null,
        url?: string | null,
        remarks?: string | null,
        license?: string | null,
        licenser?: string | null,
        licenseCount?: number | null,
        licenseNo?: string | null,
        guaranter?: string | null,
        job?: string | null,
        email?: string | null,
        logoImg?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        areaPrefs?: Array< string | null > | null,
        areas?: Array< string | null > | null,
        areaNames?: Array< string | null > | null,
        eAreas?: Array< string | null > | null,
        eAreaNames?: Array< string | null > | null,
        estateTypes?: Array< string | null > | null,
        bldConditionSelections?: Array< string | null > | null,
        remarks2?: string | null,
        searchInfo: string,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type GetCognitoUserFQueryVariables = {
  username?: string | null,
};

export type GetCognitoUserFQuery = {
  getCognitoUserF?:  {
    __typename: "CognitoUserResponse",
    item?:  {
      __typename: "CognitoUser",
      Username?: string | null,
      Attributes?:  Array< {
        __typename: "CognitoUserAttribute",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: UserStatusEnum | null,
      MFAOptions?:  Array< {
        __typename: "CognitoUserMFAOption",
        DeliveryMedium?: DeliveryMediumEnum | null,
        AttributeName?: string | null,
      } | null > | null,
      Groups?:  Array< {
        __typename: "CognitoGroup",
        GroupName?: string | null,
        UserPoolId?: string | null,
        Description?: string | null,
        RoleArn?: string | null,
        Precedence?: number | null,
        LastModifiedDate?: string | null,
        CreationDate?: string | null,
      } | null > | null,
      User?:  {
        __typename: "User",
        username: string,
        infoName: string,
        name?: string | null,
        nameRuby?: string | null,
        tel?: string | null,
        role?: string | null,
        agreement?: boolean | null,
        trial?: boolean | null,
        companyId?: string | null,
        defaultCondition?:  {
          __typename: "DefaultCondition",
          estatePriceFrom?: number | null,
          estatePriceTo?: number | null,
          tsuboAreaFrom?: number | null,
          tsuboAreaTo?: number | null,
          completionYearFrom?: string | null,
          completionYearTo?: string | null,
          roomsFrom?: number | null,
          roomsTo?: number | null,
          bldConditionSelections?: Array< string | null > | null,
          petOk?: boolean | null,
          flat?: boolean | null,
          inMyCompany?: boolean | null,
          purposeSummaryBs?: Array< string | null > | null,
          alsoCreatedAtFrom?: number | null,
          alsoCreatedAtTo?: number | null,
        } | null,
        group?: string | null,
        email?: string | null,
        searchInfo?: string | null,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        authenticatedAt?: string | null,
        companyName?: string | null,
        companyBranchName?: string | null,
      } | null,
      Company?:  {
        __typename: "Company",
        id: string,
        infoName: string,
        startMark?: string | null,
        recNo?: number | null,
        name?: string | null,
        branchId?: number | null,
        branchName?: string | null,
        branchTel?: string | null,
        branchFax?: string | null,
        branchUrl?: string | null,
        branchAddress?: string | null,
        address?: string | null,
        tel?: string | null,
        fax?: string | null,
        url?: string | null,
        remarks?: string | null,
        license?: string | null,
        licenser?: string | null,
        licenseCount?: number | null,
        licenseNo?: string | null,
        guaranter?: string | null,
        job?: string | null,
        email?: string | null,
        logoImg?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        areaPrefs?: Array< string | null > | null,
        areas?: Array< string | null > | null,
        areaNames?: Array< string | null > | null,
        eAreas?: Array< string | null > | null,
        eAreaNames?: Array< string | null > | null,
        estateTypes?: Array< string | null > | null,
        bldConditionSelections?: Array< string | null > | null,
        remarks2?: string | null,
        searchInfo: string,
        owner?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
    errorMessage?: string | null,
  } | null,
};

export type GetPresignedUrlForGetFQueryVariables = {
  key?: string | null,
  companyId?: string | null,
  estateId?: string | null,
};

export type GetPresignedUrlForGetFQuery = {
  getPresignedUrlForGetF?:  {
    __typename: "PresignedUrlResponse",
    url?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type GetPresignedUrlForPutFQueryVariables = {
  key?: string | null,
  companyId?: string | null,
  estateId?: string | null,
};

export type GetPresignedUrlForPutFQuery = {
  getPresignedUrlForPutF?:  {
    __typename: "PresignedUrlResponse",
    url?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type GetPresignedUrlForDeleteFQueryVariables = {
  key?: string | null,
  companyId?: string | null,
  estateId?: string | null,
};

export type GetPresignedUrlForDeleteFQuery = {
  getPresignedUrlForDeleteF?:  {
    __typename: "PresignedUrlResponse",
    url?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type GetAnnounceQueryVariables = {
  id: string,
};

export type GetAnnounceQuery = {
  getAnnounce?:  {
    __typename: "Announce",
    id: string,
    level: AnnounceLevel,
    subject: string,
    body: string,
    ym: string,
    owner: string,
    createdAt: string,
    updatedAt?: string | null,
    expiredAt?: number | null,
  } | null,
};

export type ListAnnouncesQueryVariables = {
  id?: string | null,
  filter?: ModelAnnounceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAnnouncesQuery = {
  listAnnounces?:  {
    __typename: "ModelAnnounceConnection",
    items:  Array< {
      __typename: "Announce",
      id: string,
      level: AnnounceLevel,
      subject: string,
      body: string,
      ym: string,
      owner: string,
      createdAt: string,
      updatedAt?: string | null,
      expiredAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAnnouncesByYmQueryVariables = {
  ym: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnnounceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnnouncesByYmQuery = {
  listAnnouncesByYm?:  {
    __typename: "ModelAnnounceConnection",
    items:  Array< {
      __typename: "Announce",
      id: string,
      level: AnnounceLevel,
      subject: string,
      body: string,
      ym: string,
      owner: string,
      createdAt: string,
      updatedAt?: string | null,
      expiredAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAtomicCounterQueryVariables = {
  counterName: string,
};

export type GetAtomicCounterQuery = {
  getAtomicCounter?:  {
    __typename: "AtomicCounter",
    counterName: string,
    sequence: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListBatchJobsQueryVariables = {
  id?: string | null,
  filter?: ModelBatchJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBatchJobsQuery = {
  listBatchJobs?:  {
    __typename: "ModelBatchJobConnection",
    items:  Array< {
      __typename: "BatchJob",
      id: string,
      jobName: string,
      remarks: string,
      status: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      expiredAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBatchJobsByJobNameQueryVariables = {
  jobName: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBatchJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBatchJobsByJobNameQuery = {
  listBatchJobsByJobName?:  {
    __typename: "ModelBatchJobConnection",
    items:  Array< {
      __typename: "BatchJob",
      id: string,
      jobName: string,
      remarks: string,
      status: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      expiredAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBatchJobConfQueryVariables = {
  jobName: string,
};

export type GetBatchJobConfQuery = {
  getBatchJobConf?:  {
    __typename: "BatchJobConf",
    jobName: string,
    remarks: string,
    allowedGroups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListBatchJobConfsQueryVariables = {
  jobName?: string | null,
  filter?: ModelBatchJobConfFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBatchJobConfsQuery = {
  listBatchJobConfs?:  {
    __typename: "ModelBatchJobConfConnection",
    items:  Array< {
      __typename: "BatchJobConf",
      jobName: string,
      remarks: string,
      allowedGroups?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
  infoName: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    infoName: string,
    startMark?: string | null,
    recNo?: number | null,
    name?: string | null,
    branchId?: number | null,
    branchName?: string | null,
    branchTel?: string | null,
    branchFax?: string | null,
    branchUrl?: string | null,
    branchAddress?: string | null,
    address?: string | null,
    tel?: string | null,
    fax?: string | null,
    url?: string | null,
    remarks?: string | null,
    license?: string | null,
    licenser?: string | null,
    licenseCount?: number | null,
    licenseNo?: string | null,
    guaranter?: string | null,
    job?: string | null,
    email?: string | null,
    logoImg?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    areaPrefs?: Array< string | null > | null,
    areas?: Array< string | null > | null,
    areaNames?: Array< string | null > | null,
    eAreas?: Array< string | null > | null,
    eAreaNames?: Array< string | null > | null,
    estateTypes?: Array< string | null > | null,
    bldConditionSelections?: Array< string | null > | null,
    remarks2?: string | null,
    searchInfo: string,
    owner?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListCompaniesQueryVariables = {
  id?: string | null,
  infoName?: ModelStringKeyConditionInput | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompaniesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      infoName: string,
      startMark?: string | null,
      recNo?: number | null,
      name?: string | null,
      branchId?: number | null,
      branchName?: string | null,
      branchTel?: string | null,
      branchFax?: string | null,
      branchUrl?: string | null,
      branchAddress?: string | null,
      address?: string | null,
      tel?: string | null,
      fax?: string | null,
      url?: string | null,
      remarks?: string | null,
      license?: string | null,
      licenser?: string | null,
      licenseCount?: number | null,
      licenseNo?: string | null,
      guaranter?: string | null,
      job?: string | null,
      email?: string | null,
      logoImg?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      areaPrefs?: Array< string | null > | null,
      areas?: Array< string | null > | null,
      areaNames?: Array< string | null > | null,
      eAreas?: Array< string | null > | null,
      eAreaNames?: Array< string | null > | null,
      estateTypes?: Array< string | null > | null,
      bldConditionSelections?: Array< string | null > | null,
      remarks2?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCompaniesBySearchInfoQueryVariables = {
  infoName: string,
  searchInfo?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompaniesBySearchInfoQuery = {
  listCompaniesBySearchInfo?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      infoName: string,
      startMark?: string | null,
      recNo?: number | null,
      name?: string | null,
      branchId?: number | null,
      branchName?: string | null,
      branchTel?: string | null,
      branchFax?: string | null,
      branchUrl?: string | null,
      branchAddress?: string | null,
      address?: string | null,
      tel?: string | null,
      fax?: string | null,
      url?: string | null,
      remarks?: string | null,
      license?: string | null,
      licenser?: string | null,
      licenseCount?: number | null,
      licenseNo?: string | null,
      guaranter?: string | null,
      job?: string | null,
      email?: string | null,
      logoImg?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      areaPrefs?: Array< string | null > | null,
      areas?: Array< string | null > | null,
      areaNames?: Array< string | null > | null,
      eAreas?: Array< string | null > | null,
      eAreaNames?: Array< string | null > | null,
      estateTypes?: Array< string | null > | null,
      bldConditionSelections?: Array< string | null > | null,
      remarks2?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEstateQueryVariables = {
  id: string,
  infoName: string,
};

export type GetEstateQuery = {
  getEstate?:  {
    __typename: "Estate",
    id: string,
    infoName: string,
    condominiumId?: string | null,
    confirmed?: boolean | null,
    status?: string | null,
    estateType?: string | null,
    name?: string | null,
    fullAddress?: string | null,
    prefecture?: string | null,
    city?: string | null,
    area?: string | null,
    address?: string | null,
    mediationKind?: string | null,
    currentStatus?: string | null,
    currentStatusFree?: string | null,
    traffic?: string | null,
    eSchool?: Array< string | null > | null,
    jSchool?: Array< string | null > | null,
    remarks?: string | null,
    landAreaType?: string | null,
    squareArea?: number | null,
    tsuboArea?: number | null,
    estatePrice?: number | null,
    unitPrice?: number | null,
    pricedAt?: string | null,
    priceHistory?:  Array< {
      __typename: "Price",
      price?: number | null,
      pricedAt?: string | null,
    } | null > | null,
    completionSchMonth?: number | null,
    completionMonth?: number | null,
    clearingType?: string | null,
    cleaningMonth?: number | null,
    bldConditionType?: string | null,
    landRights?: string | null,
    cityPlanningType?: string | null,
    useDistrict?: Array< string | null > | null,
    floorAreaRatio?: number | null,
    bcr?: number | null,
    roadType?: string | null,
    drivewayPay?: string | null,
    roadDirection?: string | null,
    roadWidth?: number | null,
    water?: string | null,
    sewer?: string | null,
    gas?: string | null,
    groundType?: Array< string | null > | null,
    groundTypeFree?: string | null,
    layout?: string | null,
    totalArea?: number | null,
    structure?: string | null,
    structureFree?: string | null,
    buildingFloors?: string | null,
    balconyArea?: number | null,
    managementType?: string | null,
    pet?: string | null,
    floor?: number | null,
    room?: string | null,
    managementFee?: number | null,
    repairCost?: number | null,
    otherCost?: number | null,
    parking?: string | null,
    carsInParkingN?: number | null,
    parkingTakeOver?: string | null,
    parkingTakeOverFree?: string | null,
    parkingMechOrPlane?: string | null,
    parkingHeight?: number | null,
    parkingFee?: number | null,
    purposeB?: string | null,
    purposeSummaryB?: string | null,
    companyId?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    companyTel?: string | null,
    companyFax?: string | null,
    companyUrl?: string | null,
    companyLicense?: string | null,
    companyGuaranter?: string | null,
    subCompanyId?: string | null,
    subCompanyName?: string | null,
    subCompanyAddress?: string | null,
    subCompanyTel?: string | null,
    subCompanyFax?: string | null,
    subCompanyUrl?: string | null,
    subCompanyLicense?: string | null,
    subCompanyGuaranter?: string | null,
    subCompany2Id?: string | null,
    subCompany2Name?: string | null,
    subCompany2Address?: string | null,
    subCompany2Tel?: string | null,
    subCompany2Fax?: string | null,
    subCompany2Url?: string | null,
    subCompany2License?: string | null,
    subCompany2Guaranter?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    tileOnZoom12?: string | null,
    tileOnZoom14?: string | null,
    tileOnZoom16?: string | null,
    layoutImgs?: Array< string | null > | null,
    appearanceImgs?: Array< string | null > | null,
    mapImg?: string | null,
    waterPdf?: string | null,
    sewerPdf?: string | null,
    gasPdf?: string | null,
    cadastralMapPdf?: string | null,
    groundPdf?: string | null,
    url?: string | null,
    license?: string | null,
    recNo?: number | null,
    media?: string | null,
    location?: string | null,
    collectedAt?: string | null,
    floorStructure?: string | null,
    buildingCompany?: string | null,
    notFoundAt?: string | null,
    exactLocation?: number | null,
    nearlyLocation?: number | null,
    unsettledLocation?: number | null,
    isLot?: number | null,
    currentStatusB?: string | null,
    currentStatusG?: string | null,
    companyRecNo?: number | null,
    companyBranchId?: number | null,
    subCompanyRecNo?: number | null,
    completionMonthS?: string | null,
    condominiumName?: string | null,
    condominiumRecNo?: number | null,
    totalHouses?: number | null,
    manager?: string | null,
    seller?: string | null,
    estateUrl?: string | null,
    searchInfo: string,
    owner?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sold?: boolean | null,
  } | null,
};

export type ListEstatesQueryVariables = {
  id?: string | null,
  infoName?: ModelStringKeyConditionInput | null,
  filter?: ModelEstateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEstatesQuery = {
  listEstates?:  {
    __typename: "ModelEstateConnection",
    items:  Array< {
      __typename: "Estate",
      id: string,
      infoName: string,
      condominiumId?: string | null,
      confirmed?: boolean | null,
      status?: string | null,
      estateType?: string | null,
      name?: string | null,
      fullAddress?: string | null,
      prefecture?: string | null,
      city?: string | null,
      area?: string | null,
      address?: string | null,
      mediationKind?: string | null,
      currentStatus?: string | null,
      currentStatusFree?: string | null,
      traffic?: string | null,
      eSchool?: Array< string | null > | null,
      jSchool?: Array< string | null > | null,
      remarks?: string | null,
      landAreaType?: string | null,
      squareArea?: number | null,
      tsuboArea?: number | null,
      estatePrice?: number | null,
      unitPrice?: number | null,
      pricedAt?: string | null,
      priceHistory?:  Array< {
        __typename: "Price",
        price?: number | null,
        pricedAt?: string | null,
      } | null > | null,
      completionSchMonth?: number | null,
      completionMonth?: number | null,
      clearingType?: string | null,
      cleaningMonth?: number | null,
      bldConditionType?: string | null,
      landRights?: string | null,
      cityPlanningType?: string | null,
      useDistrict?: Array< string | null > | null,
      floorAreaRatio?: number | null,
      bcr?: number | null,
      roadType?: string | null,
      drivewayPay?: string | null,
      roadDirection?: string | null,
      roadWidth?: number | null,
      water?: string | null,
      sewer?: string | null,
      gas?: string | null,
      groundType?: Array< string | null > | null,
      groundTypeFree?: string | null,
      layout?: string | null,
      totalArea?: number | null,
      structure?: string | null,
      structureFree?: string | null,
      buildingFloors?: string | null,
      balconyArea?: number | null,
      managementType?: string | null,
      pet?: string | null,
      floor?: number | null,
      room?: string | null,
      managementFee?: number | null,
      repairCost?: number | null,
      otherCost?: number | null,
      parking?: string | null,
      carsInParkingN?: number | null,
      parkingTakeOver?: string | null,
      parkingTakeOverFree?: string | null,
      parkingMechOrPlane?: string | null,
      parkingHeight?: number | null,
      parkingFee?: number | null,
      purposeB?: string | null,
      purposeSummaryB?: string | null,
      companyId?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      companyTel?: string | null,
      companyFax?: string | null,
      companyUrl?: string | null,
      companyLicense?: string | null,
      companyGuaranter?: string | null,
      subCompanyId?: string | null,
      subCompanyName?: string | null,
      subCompanyAddress?: string | null,
      subCompanyTel?: string | null,
      subCompanyFax?: string | null,
      subCompanyUrl?: string | null,
      subCompanyLicense?: string | null,
      subCompanyGuaranter?: string | null,
      subCompany2Id?: string | null,
      subCompany2Name?: string | null,
      subCompany2Address?: string | null,
      subCompany2Tel?: string | null,
      subCompany2Fax?: string | null,
      subCompany2Url?: string | null,
      subCompany2License?: string | null,
      subCompany2Guaranter?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      tileOnZoom12?: string | null,
      tileOnZoom14?: string | null,
      tileOnZoom16?: string | null,
      layoutImgs?: Array< string | null > | null,
      appearanceImgs?: Array< string | null > | null,
      mapImg?: string | null,
      waterPdf?: string | null,
      sewerPdf?: string | null,
      gasPdf?: string | null,
      cadastralMapPdf?: string | null,
      groundPdf?: string | null,
      url?: string | null,
      license?: string | null,
      recNo?: number | null,
      media?: string | null,
      location?: string | null,
      collectedAt?: string | null,
      floorStructure?: string | null,
      buildingCompany?: string | null,
      notFoundAt?: string | null,
      exactLocation?: number | null,
      nearlyLocation?: number | null,
      unsettledLocation?: number | null,
      isLot?: number | null,
      currentStatusB?: string | null,
      currentStatusG?: string | null,
      companyRecNo?: number | null,
      companyBranchId?: number | null,
      subCompanyRecNo?: number | null,
      completionMonthS?: string | null,
      condominiumName?: string | null,
      condominiumRecNo?: number | null,
      totalHouses?: number | null,
      manager?: string | null,
      seller?: string | null,
      estateUrl?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sold?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEstatesBySearchInfoQueryVariables = {
  infoName: string,
  searchInfo?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEstatesBySearchInfoQuery = {
  listEstatesBySearchInfo?:  {
    __typename: "ModelEstateConnection",
    items:  Array< {
      __typename: "Estate",
      id: string,
      infoName: string,
      condominiumId?: string | null,
      confirmed?: boolean | null,
      status?: string | null,
      estateType?: string | null,
      name?: string | null,
      fullAddress?: string | null,
      prefecture?: string | null,
      city?: string | null,
      area?: string | null,
      address?: string | null,
      mediationKind?: string | null,
      currentStatus?: string | null,
      currentStatusFree?: string | null,
      traffic?: string | null,
      eSchool?: Array< string | null > | null,
      jSchool?: Array< string | null > | null,
      remarks?: string | null,
      landAreaType?: string | null,
      squareArea?: number | null,
      tsuboArea?: number | null,
      estatePrice?: number | null,
      unitPrice?: number | null,
      pricedAt?: string | null,
      priceHistory?:  Array< {
        __typename: "Price",
        price?: number | null,
        pricedAt?: string | null,
      } | null > | null,
      completionSchMonth?: number | null,
      completionMonth?: number | null,
      clearingType?: string | null,
      cleaningMonth?: number | null,
      bldConditionType?: string | null,
      landRights?: string | null,
      cityPlanningType?: string | null,
      useDistrict?: Array< string | null > | null,
      floorAreaRatio?: number | null,
      bcr?: number | null,
      roadType?: string | null,
      drivewayPay?: string | null,
      roadDirection?: string | null,
      roadWidth?: number | null,
      water?: string | null,
      sewer?: string | null,
      gas?: string | null,
      groundType?: Array< string | null > | null,
      groundTypeFree?: string | null,
      layout?: string | null,
      totalArea?: number | null,
      structure?: string | null,
      structureFree?: string | null,
      buildingFloors?: string | null,
      balconyArea?: number | null,
      managementType?: string | null,
      pet?: string | null,
      floor?: number | null,
      room?: string | null,
      managementFee?: number | null,
      repairCost?: number | null,
      otherCost?: number | null,
      parking?: string | null,
      carsInParkingN?: number | null,
      parkingTakeOver?: string | null,
      parkingTakeOverFree?: string | null,
      parkingMechOrPlane?: string | null,
      parkingHeight?: number | null,
      parkingFee?: number | null,
      purposeB?: string | null,
      purposeSummaryB?: string | null,
      companyId?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      companyTel?: string | null,
      companyFax?: string | null,
      companyUrl?: string | null,
      companyLicense?: string | null,
      companyGuaranter?: string | null,
      subCompanyId?: string | null,
      subCompanyName?: string | null,
      subCompanyAddress?: string | null,
      subCompanyTel?: string | null,
      subCompanyFax?: string | null,
      subCompanyUrl?: string | null,
      subCompanyLicense?: string | null,
      subCompanyGuaranter?: string | null,
      subCompany2Id?: string | null,
      subCompany2Name?: string | null,
      subCompany2Address?: string | null,
      subCompany2Tel?: string | null,
      subCompany2Fax?: string | null,
      subCompany2Url?: string | null,
      subCompany2License?: string | null,
      subCompany2Guaranter?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      tileOnZoom12?: string | null,
      tileOnZoom14?: string | null,
      tileOnZoom16?: string | null,
      layoutImgs?: Array< string | null > | null,
      appearanceImgs?: Array< string | null > | null,
      mapImg?: string | null,
      waterPdf?: string | null,
      sewerPdf?: string | null,
      gasPdf?: string | null,
      cadastralMapPdf?: string | null,
      groundPdf?: string | null,
      url?: string | null,
      license?: string | null,
      recNo?: number | null,
      media?: string | null,
      location?: string | null,
      collectedAt?: string | null,
      floorStructure?: string | null,
      buildingCompany?: string | null,
      notFoundAt?: string | null,
      exactLocation?: number | null,
      nearlyLocation?: number | null,
      unsettledLocation?: number | null,
      isLot?: number | null,
      currentStatusB?: string | null,
      currentStatusG?: string | null,
      companyRecNo?: number | null,
      companyBranchId?: number | null,
      subCompanyRecNo?: number | null,
      completionMonthS?: string | null,
      condominiumName?: string | null,
      condominiumRecNo?: number | null,
      totalHouses?: number | null,
      manager?: string | null,
      seller?: string | null,
      estateUrl?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sold?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPublicEstateQueryVariables = {
  id: string,
};

export type GetPublicEstateQuery = {
  getPublicEstate?:  {
    __typename: "PublicEstate",
    id: string,
    distributer:  {
      __typename: "Company",
      id: string,
      infoName: string,
      startMark?: string | null,
      recNo?: number | null,
      name?: string | null,
      branchId?: number | null,
      branchName?: string | null,
      branchTel?: string | null,
      branchFax?: string | null,
      branchUrl?: string | null,
      branchAddress?: string | null,
      address?: string | null,
      tel?: string | null,
      fax?: string | null,
      url?: string | null,
      remarks?: string | null,
      license?: string | null,
      licenser?: string | null,
      licenseCount?: number | null,
      licenseNo?: string | null,
      guaranter?: string | null,
      job?: string | null,
      email?: string | null,
      logoImg?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      areaPrefs?: Array< string | null > | null,
      areas?: Array< string | null > | null,
      areaNames?: Array< string | null > | null,
      eAreas?: Array< string | null > | null,
      eAreaNames?: Array< string | null > | null,
      estateTypes?: Array< string | null > | null,
      bldConditionSelections?: Array< string | null > | null,
      remarks2?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    },
    estateId: string,
    estate:  {
      __typename: "Estate",
      id: string,
      infoName: string,
      condominiumId?: string | null,
      confirmed?: boolean | null,
      status?: string | null,
      estateType?: string | null,
      name?: string | null,
      fullAddress?: string | null,
      prefecture?: string | null,
      city?: string | null,
      area?: string | null,
      address?: string | null,
      mediationKind?: string | null,
      currentStatus?: string | null,
      currentStatusFree?: string | null,
      traffic?: string | null,
      eSchool?: Array< string | null > | null,
      jSchool?: Array< string | null > | null,
      remarks?: string | null,
      landAreaType?: string | null,
      squareArea?: number | null,
      tsuboArea?: number | null,
      estatePrice?: number | null,
      unitPrice?: number | null,
      pricedAt?: string | null,
      priceHistory?:  Array< {
        __typename: "Price",
        price?: number | null,
        pricedAt?: string | null,
      } | null > | null,
      completionSchMonth?: number | null,
      completionMonth?: number | null,
      clearingType?: string | null,
      cleaningMonth?: number | null,
      bldConditionType?: string | null,
      landRights?: string | null,
      cityPlanningType?: string | null,
      useDistrict?: Array< string | null > | null,
      floorAreaRatio?: number | null,
      bcr?: number | null,
      roadType?: string | null,
      drivewayPay?: string | null,
      roadDirection?: string | null,
      roadWidth?: number | null,
      water?: string | null,
      sewer?: string | null,
      gas?: string | null,
      groundType?: Array< string | null > | null,
      groundTypeFree?: string | null,
      layout?: string | null,
      totalArea?: number | null,
      structure?: string | null,
      structureFree?: string | null,
      buildingFloors?: string | null,
      balconyArea?: number | null,
      managementType?: string | null,
      pet?: string | null,
      floor?: number | null,
      room?: string | null,
      managementFee?: number | null,
      repairCost?: number | null,
      otherCost?: number | null,
      parking?: string | null,
      carsInParkingN?: number | null,
      parkingTakeOver?: string | null,
      parkingTakeOverFree?: string | null,
      parkingMechOrPlane?: string | null,
      parkingHeight?: number | null,
      parkingFee?: number | null,
      purposeB?: string | null,
      purposeSummaryB?: string | null,
      companyId?: string | null,
      companyName?: string | null,
      companyAddress?: string | null,
      companyTel?: string | null,
      companyFax?: string | null,
      companyUrl?: string | null,
      companyLicense?: string | null,
      companyGuaranter?: string | null,
      subCompanyId?: string | null,
      subCompanyName?: string | null,
      subCompanyAddress?: string | null,
      subCompanyTel?: string | null,
      subCompanyFax?: string | null,
      subCompanyUrl?: string | null,
      subCompanyLicense?: string | null,
      subCompanyGuaranter?: string | null,
      subCompany2Id?: string | null,
      subCompany2Name?: string | null,
      subCompany2Address?: string | null,
      subCompany2Tel?: string | null,
      subCompany2Fax?: string | null,
      subCompany2Url?: string | null,
      subCompany2License?: string | null,
      subCompany2Guaranter?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      tileOnZoom12?: string | null,
      tileOnZoom14?: string | null,
      tileOnZoom16?: string | null,
      layoutImgs?: Array< string | null > | null,
      appearanceImgs?: Array< string | null > | null,
      mapImg?: string | null,
      waterPdf?: string | null,
      sewerPdf?: string | null,
      gasPdf?: string | null,
      cadastralMapPdf?: string | null,
      groundPdf?: string | null,
      url?: string | null,
      license?: string | null,
      recNo?: number | null,
      media?: string | null,
      location?: string | null,
      collectedAt?: string | null,
      floorStructure?: string | null,
      buildingCompany?: string | null,
      notFoundAt?: string | null,
      exactLocation?: number | null,
      nearlyLocation?: number | null,
      unsettledLocation?: number | null,
      isLot?: number | null,
      currentStatusB?: string | null,
      currentStatusG?: string | null,
      companyRecNo?: number | null,
      companyBranchId?: number | null,
      subCompanyRecNo?: number | null,
      completionMonthS?: string | null,
      condominiumName?: string | null,
      condominiumRecNo?: number | null,
      totalHouses?: number | null,
      manager?: string | null,
      seller?: string | null,
      estateUrl?: string | null,
      searchInfo: string,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      sold?: boolean | null,
    },
    createdAt: string,
    updatedAt?: string | null,
    expiredAt: number,
  } | null,
};

export type GetReportQueryVariables = {
  id: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    id: string,
    estateId: string,
    estateName?: string | null,
    reportKind: string,
    description?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    companyId: string,
    companyName: string,
    userName: string,
    owner: string,
    createdAt: string,
    updatedAt?: string | null,
    expiredAt?: number | null,
  } | null,
};

export type ListReportsQueryVariables = {
  id?: string | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      estateId: string,
      estateName?: string | null,
      reportKind: string,
      description?: string | null,
      latitude?: number | null,
      longitude?: number | null,
      companyId: string,
      companyName: string,
      userName: string,
      owner: string,
      createdAt: string,
      updatedAt?: string | null,
      expiredAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListScrapingsQueryVariables = {
  baseUrl?: string | null,
  sortKey?: ModelStringKeyConditionInput | null,
  filter?: ModelScrapingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListScrapingsQuery = {
  listScrapings?:  {
    __typename: "ModelScrapingConnection",
    items:  Array< {
      __typename: "Scraping",
      baseUrl: string,
      prefName: string,
      status: string,
      operation?: string | null,
      input?: string | null,
      startedAt?: string | null,
      totalCount?: number | null,
      updating?:  {
        __typename: "ScrapingUpdateDetail",
        newRecord?: number | null,
        updatePrice?: number | null,
        expired?: number | null,
        return?: number | null,
        returnAndUpdatePrice?: number | null,
        scrapingFailed?: number | null,
        requiresAuth?: number | null,
        blocked?: number | null,
        updateFailed?: number | null,
      } | null,
      updated?:  {
        __typename: "ScrapingUpdateDetail",
        newRecord?: number | null,
        updatePrice?: number | null,
        expired?: number | null,
        return?: number | null,
        returnAndUpdatePrice?: number | null,
        scrapingFailed?: number | null,
        requiresAuth?: number | null,
        blocked?: number | null,
        updateFailed?: number | null,
      } | null,
      resultKey?: string | null,
      endedAt?: string | null,
      output?: string | null,
      remarks?: string | null,
      sortKey: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStickyNoteQueryVariables = {
  id: string,
  infoName: string,
};

export type GetStickyNoteQuery = {
  getStickyNote?:  {
    __typename: "StickyNote",
    id: string,
    infoName: string,
    memoKind: string,
    openLevel: string,
    icon?: StickyNoteIcon | null,
    color?: StickyNoteColor | null,
    description?: string | null,
    companyId: string,
    latitude: number,
    longitude: number,
    tileOnZoom12: string,
    tileOnZoom14: string,
    tileOnZoom16: string,
    searchInfo: string,
    owner: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListStickyNotesQueryVariables = {
  id?: string | null,
  infoName?: ModelStringKeyConditionInput | null,
  filter?: ModelStickyNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStickyNotesQuery = {
  listStickyNotes?:  {
    __typename: "ModelStickyNoteConnection",
    items:  Array< {
      __typename: "StickyNote",
      id: string,
      infoName: string,
      memoKind: string,
      openLevel: string,
      icon?: StickyNoteIcon | null,
      color?: StickyNoteColor | null,
      description?: string | null,
      companyId: string,
      latitude: number,
      longitude: number,
      tileOnZoom12: string,
      tileOnZoom14: string,
      tileOnZoom16: string,
      searchInfo: string,
      owner: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListStickyNotesBySearchInfoQueryVariables = {
  infoName: string,
  searchInfo?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStickyNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStickyNotesBySearchInfoQuery = {
  listStickyNotesBySearchInfo?:  {
    __typename: "ModelStickyNoteConnection",
    items:  Array< {
      __typename: "StickyNote",
      id: string,
      infoName: string,
      memoKind: string,
      openLevel: string,
      icon?: StickyNoteIcon | null,
      color?: StickyNoteColor | null,
      description?: string | null,
      companyId: string,
      latitude: number,
      longitude: number,
      tileOnZoom12: string,
      tileOnZoom14: string,
      tileOnZoom16: string,
      searchInfo: string,
      owner: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  username: string,
  infoName: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    username: string,
    infoName: string,
    name?: string | null,
    nameRuby?: string | null,
    tel?: string | null,
    role?: string | null,
    agreement?: boolean | null,
    trial?: boolean | null,
    companyId?: string | null,
    defaultCondition?:  {
      __typename: "DefaultCondition",
      estatePriceFrom?: number | null,
      estatePriceTo?: number | null,
      tsuboAreaFrom?: number | null,
      tsuboAreaTo?: number | null,
      completionYearFrom?: string | null,
      completionYearTo?: string | null,
      roomsFrom?: number | null,
      roomsTo?: number | null,
      bldConditionSelections?: Array< string | null > | null,
      petOk?: boolean | null,
      flat?: boolean | null,
      inMyCompany?: boolean | null,
      purposeSummaryBs?: Array< string | null > | null,
      alsoCreatedAtFrom?: number | null,
      alsoCreatedAtTo?: number | null,
    } | null,
    group?: string | null,
    email?: string | null,
    searchInfo?: string | null,
    owner?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    authenticatedAt?: string | null,
    companyName?: string | null,
    companyBranchName?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  username?: string | null,
  infoName?: ModelStringKeyConditionInput | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      username: string,
      infoName: string,
      name?: string | null,
      nameRuby?: string | null,
      tel?: string | null,
      role?: string | null,
      agreement?: boolean | null,
      trial?: boolean | null,
      companyId?: string | null,
      defaultCondition?:  {
        __typename: "DefaultCondition",
        estatePriceFrom?: number | null,
        estatePriceTo?: number | null,
        tsuboAreaFrom?: number | null,
        tsuboAreaTo?: number | null,
        completionYearFrom?: string | null,
        completionYearTo?: string | null,
        roomsFrom?: number | null,
        roomsTo?: number | null,
        bldConditionSelections?: Array< string | null > | null,
        petOk?: boolean | null,
        flat?: boolean | null,
        inMyCompany?: boolean | null,
        purposeSummaryBs?: Array< string | null > | null,
        alsoCreatedAtFrom?: number | null,
        alsoCreatedAtTo?: number | null,
      } | null,
      group?: string | null,
      email?: string | null,
      searchInfo?: string | null,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      authenticatedAt?: string | null,
      companyName?: string | null,
      companyBranchName?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersBySearchInfoQueryVariables = {
  infoName: string,
  searchInfo?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersBySearchInfoQuery = {
  listUsersBySearchInfo?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      username: string,
      infoName: string,
      name?: string | null,
      nameRuby?: string | null,
      tel?: string | null,
      role?: string | null,
      agreement?: boolean | null,
      trial?: boolean | null,
      companyId?: string | null,
      defaultCondition?:  {
        __typename: "DefaultCondition",
        estatePriceFrom?: number | null,
        estatePriceTo?: number | null,
        tsuboAreaFrom?: number | null,
        tsuboAreaTo?: number | null,
        completionYearFrom?: string | null,
        completionYearTo?: string | null,
        roomsFrom?: number | null,
        roomsTo?: number | null,
        bldConditionSelections?: Array< string | null > | null,
        petOk?: boolean | null,
        flat?: boolean | null,
        inMyCompany?: boolean | null,
        purposeSummaryBs?: Array< string | null > | null,
        alsoCreatedAtFrom?: number | null,
        alsoCreatedAtTo?: number | null,
      } | null,
      group?: string | null,
      email?: string | null,
      searchInfo?: string | null,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      authenticatedAt?: string | null,
      companyName?: string | null,
      companyBranchName?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPresignedUrlForAnonymousFQueryVariables = {
  key?: string | null,
};

export type GetPresignedUrlForAnonymousFQuery = {
  getPresignedUrlForAnonymousF?:  {
    __typename: "PresignedUrlResponse",
    url?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type OnNotifyEstateCSubscriptionVariables = {
};

export type OnNotifyEstateCSubscription = {
  onNotifyEstateC?:  {
    __typename: "EstateNotifyResponse",
    id?: string | null,
    infoName?: string | null,
    condominiumId?: string | null,
    confirmed?: boolean | null,
    status?: string | null,
    estateType?: string | null,
    name?: string | null,
    fullAddress?: string | null,
    prefecture?: string | null,
    city?: string | null,
    area?: string | null,
    address?: string | null,
    mediationKind?: string | null,
    currentStatus?: string | null,
    currentStatusFree?: string | null,
    traffic?: string | null,
    eSchool?: Array< string | null > | null,
    jSchool?: Array< string | null > | null,
    remarks?: string | null,
    landAreaType?: string | null,
    squareArea?: number | null,
    tsuboArea?: number | null,
    estatePrice?: number | null,
    unitPrice?: number | null,
    pricedAt?: string | null,
    priceHistory?:  Array< {
      __typename: "Price",
      price?: number | null,
      pricedAt?: string | null,
    } | null > | null,
    completionSchMonth?: number | null,
    completionMonth?: number | null,
    clearingType?: string | null,
    cleaningMonth?: number | null,
    bldConditionType?: string | null,
    landRights?: string | null,
    cityPlanningType?: string | null,
    useDistrict?: Array< string | null > | null,
    floorAreaRatio?: number | null,
    bcr?: number | null,
    roadType?: string | null,
    drivewayPay?: string | null,
    roadDirection?: string | null,
    roadWidth?: number | null,
    water?: string | null,
    sewer?: string | null,
    gas?: string | null,
    groundType?: Array< string | null > | null,
    groundTypeFree?: string | null,
    layout?: string | null,
    totalArea?: number | null,
    structure?: string | null,
    structureFree?: string | null,
    buildingFloors?: string | null,
    balconyArea?: number | null,
    managementType?: string | null,
    pet?: string | null,
    floor?: number | null,
    room?: string | null,
    managementFee?: number | null,
    repairCost?: number | null,
    otherCost?: number | null,
    parking?: string | null,
    carsInParkingN?: number | null,
    parkingTakeOver?: string | null,
    parkingTakeOverFree?: string | null,
    parkingMechOrPlane?: string | null,
    parkingHeight?: number | null,
    parkingFee?: number | null,
    purposeB?: string | null,
    purposeSummaryB?: string | null,
    companyId?: string | null,
    companyName?: string | null,
    companyAddress?: string | null,
    companyTel?: string | null,
    companyFax?: string | null,
    companyUrl?: string | null,
    companyLicense?: string | null,
    companyGuaranter?: string | null,
    subCompanyId?: string | null,
    subCompanyName?: string | null,
    subCompanyAddress?: string | null,
    subCompanyTel?: string | null,
    subCompanyFax?: string | null,
    subCompanyUrl?: string | null,
    subCompanyLicense?: string | null,
    subCompanyGuaranter?: string | null,
    subCompany2Id?: string | null,
    subCompany2Name?: string | null,
    subCompany2Address?: string | null,
    subCompany2Tel?: string | null,
    subCompany2Fax?: string | null,
    subCompany2Url?: string | null,
    subCompany2License?: string | null,
    subCompany2Guaranter?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    tileOnZoom12?: string | null,
    tileOnZoom14?: string | null,
    tileOnZoom16?: string | null,
    layoutImgs?: Array< string | null > | null,
    appearanceImgs?: Array< string | null > | null,
    mapImg?: string | null,
    waterPdf?: string | null,
    sewerPdf?: string | null,
    gasPdf?: string | null,
    cadastralMapPdf?: string | null,
    groundPdf?: string | null,
    url?: string | null,
    license?: string | null,
    recNo?: number | null,
    media?: string | null,
    location?: string | null,
    collectedAt?: string | null,
    floorStructure?: string | null,
    buildingCompany?: string | null,
    notFoundAt?: string | null,
    exactLocation?: number | null,
    nearlyLocation?: number | null,
    unsettledLocation?: number | null,
    isLot?: number | null,
    currentStatusB?: string | null,
    currentStatusG?: string | null,
    companyRecNo?: number | null,
    companyBranchId?: number | null,
    subCompanyRecNo?: number | null,
    completionMonthS?: string | null,
    condominiumName?: string | null,
    condominiumRecNo?: number | null,
    totalHouses?: number | null,
    manager?: string | null,
    seller?: string | null,
    estateUrl?: string | null,
    searchInfo?: string | null,
    owner?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    sold?: boolean | null,
  } | null,
};

export type OnCreateBatchJobSubscriptionVariables = {
  filter?: ModelSubscriptionBatchJobFilterInput | null,
};

export type OnCreateBatchJobSubscription = {
  onCreateBatchJob?:  {
    __typename: "BatchJob",
    id: string,
    jobName: string,
    remarks: string,
    status: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    expiredAt?: number | null,
  } | null,
};

export type OnCreateScrapingSubscriptionVariables = {
  filter?: ModelSubscriptionScrapingFilterInput | null,
};

export type OnCreateScrapingSubscription = {
  onCreateScraping?:  {
    __typename: "Scraping",
    baseUrl: string,
    prefName: string,
    status: string,
    operation?: string | null,
    input?: string | null,
    startedAt?: string | null,
    totalCount?: number | null,
    updating?:  {
      __typename: "ScrapingUpdateDetail",
      newRecord?: number | null,
      updatePrice?: number | null,
      expired?: number | null,
      return?: number | null,
      returnAndUpdatePrice?: number | null,
      scrapingFailed?: number | null,
      requiresAuth?: number | null,
      blocked?: number | null,
      updateFailed?: number | null,
    } | null,
    updated?:  {
      __typename: "ScrapingUpdateDetail",
      newRecord?: number | null,
      updatePrice?: number | null,
      expired?: number | null,
      return?: number | null,
      returnAndUpdatePrice?: number | null,
      scrapingFailed?: number | null,
      requiresAuth?: number | null,
      blocked?: number | null,
      updateFailed?: number | null,
    } | null,
    resultKey?: string | null,
    endedAt?: string | null,
    output?: string | null,
    remarks?: string | null,
    sortKey: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateScrapingSubscriptionVariables = {
  filter?: ModelSubscriptionScrapingFilterInput | null,
};

export type OnUpdateScrapingSubscription = {
  onUpdateScraping?:  {
    __typename: "Scraping",
    baseUrl: string,
    prefName: string,
    status: string,
    operation?: string | null,
    input?: string | null,
    startedAt?: string | null,
    totalCount?: number | null,
    updating?:  {
      __typename: "ScrapingUpdateDetail",
      newRecord?: number | null,
      updatePrice?: number | null,
      expired?: number | null,
      return?: number | null,
      returnAndUpdatePrice?: number | null,
      scrapingFailed?: number | null,
      requiresAuth?: number | null,
      blocked?: number | null,
      updateFailed?: number | null,
    } | null,
    updated?:  {
      __typename: "ScrapingUpdateDetail",
      newRecord?: number | null,
      updatePrice?: number | null,
      expired?: number | null,
      return?: number | null,
      returnAndUpdatePrice?: number | null,
      scrapingFailed?: number | null,
      requiresAuth?: number | null,
      blocked?: number | null,
      updateFailed?: number | null,
    } | null,
    resultKey?: string | null,
    endedAt?: string | null,
    output?: string | null,
    remarks?: string | null,
    sortKey: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};
