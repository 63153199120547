import { AWSCredentials } from "@aws-amplify/core/dist/esm/singleton/Auth/types";
import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser
} from "aws-amplify/auth";
import { UserInfo } from "common/interfaces/UserInfo";
import { sleep } from "./utilities";

// Credentialステートの取得
export const getCredentialStates = async (
  retryCount?: number,
  err?: any
): Promise<
  | {
      credentials: AWSCredentials;
      userInfo: UserInfo;
      groups: string[];
      eventId: string;
    }
  | undefined
> => {
  if (retryCount === undefined) {
    retryCount = 0;
  }

  if (retryCount > 5) {
    throw err ?? new Error("getCredentialStates: retryCount > 5");
  }

  if (retryCount > 0) {
    window.console.log(`getCredentialStates: retryCount=${retryCount}`);
  }

  try {
    const session = await fetchAuthSession();

    if (session) {
      if (session.credentials) {
        const tokens = session.tokens;

        if (
          tokens?.idToken &&
          tokens.idToken.payload["cognito:groups"] &&
          tokens.idToken.payload["event_id"]
        ) {
          const groups = tokens.idToken.payload["cognito:groups"]
            .toString()
            .split(",");
          const eventId = tokens.idToken.payload["event_id"].toString();
          const attributes = await fetchUserAttributes();

          const { username, userId: id } = await getCurrentUser();

          const userInfo: UserInfo = {
            id: id,
            username: username,
            attributes
          };

          return {
            credentials: session.credentials,
            userInfo,
            groups,
            eventId
          };
        }
      }
    }
  } catch (e: any) {
    window.console.log("getCredentialStates", JSON.stringify(e));

    if (e.name === "NetworkError") {
      await sleep(500);
      return await getCredentialStates(retryCount + 1, e);
    }

    throw e;
  }

  return undefined;
};

// SumoraAuth Tokenの取得
export const getSumoraAuthToken = async () =>
  `SumoraAuth ${(await fetchAuthSession()).tokens?.accessToken.toString()}`;
