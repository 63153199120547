import {
  completionYMMax,
  completionYMMin,
  dateMax,
  dateMin,
  estatePriceMax,
  estatePriceMin,
  roomsMax,
  roomsMin,
  tsuboAreaMax,
  tsuboAreaMin
} from "common/consts/estates";
import { format } from "date-fns";
import { BldConditionSelection } from "./BldConditionSelection";
import { EstateType } from "./EstateType";
import { PurposeSummaryB } from "./PurposeSummaryB";

export interface EstateFilterConditions {
  estateTypes: EstateType[];
  bldConditionSelections: BldConditionSelection[];
  purposeSummaryBs: PurposeSummaryB[];
  companyRecNo: number;
  tsuboAreaFrom: number;
  tsuboAreaTo: number;
  estatePriceFrom: number;
  estatePriceTo: number;
  createdAtFrom: number;
  createdAtTo: number;
  pricedAtFrom: number;
  pricedAtTo: number;
  alsoCreatedAtFrom: number;
  alsoCreatedAtTo: number;
  alsoPricedAtFrom: number;
  alsoPricedAtTo: number;
  completionYMFrom: number;
  completionYMTo: number;
  petOk: boolean;
  flat: boolean;
  roomsFrom: number;
  roomsTo: number;
}

export const initialEstateFilterConditions: EstateFilterConditions = {
  estateTypes: [],
  bldConditionSelections: [],
  purposeSummaryBs: [],
  companyRecNo: 0,
  tsuboAreaFrom: tsuboAreaMin,
  tsuboAreaTo: tsuboAreaMax,
  estatePriceFrom: estatePriceMin,
  estatePriceTo: estatePriceMax,
  createdAtFrom: dateMin,
  createdAtTo: dateMax,
  pricedAtFrom: dateMin,
  pricedAtTo: dateMax,
  alsoCreatedAtFrom: dateMin,
  alsoCreatedAtTo: dateMax,
  alsoPricedAtFrom: dateMin,
  alsoPricedAtTo: dateMax,
  completionYMFrom: Number(format(completionYMMin, "yyyyMM")),
  completionYMTo: Number(format(completionYMMax, "yyyyMM")),
  petOk: false,
  flat: false,
  roomsFrom: roomsMin,
  roomsTo: roomsMax
};
