import { DrawingColor } from "common/interfaces/DrawingColor";

// 洪水浸水想定区域カラーチャート
export const shinsuiColors: DrawingColor[] = [
  {
    code: 3,
    name: "0.0〜0.5m",
    strokeColor: "#cccccc",
    fillColor: "#f4e2ad"
  },
  {
    code: 4,
    name: "0.5〜3.0m",
    strokeColor: "#cccccc",
    fillColor: "#f9d9c3"
  },
  {
    code: 5,
    name: "3.0〜5.0m",
    strokeColor: "#cccccc",
    fillColor: "#f4bab9"
  },
  {
    code: 6,
    name: "5.0〜10m",
    strokeColor: "#cccccc",
    fillColor: "#f09694"
  },
  {
    code: 7,
    name: "10〜20m",
    strokeColor: "#cccccc",
    fillColor: "#e38bc6"
  },
  {
    code: 8,
    name: "20m〜",
    strokeColor: "#cccccc",
    fillColor: "#cf7fb9"
  }
];
