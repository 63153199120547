import { DrawingColor } from "common/interfaces/DrawingColor";

// 用途地域カラーチャート
export const youtoColors: DrawingColor[] = [
  {
    code: 1,
    name: "1種低層",
    strokeColor: "#7CCBBE",
    fillColor: "#7CCBBE"
  },
  {
    code: 2,
    name: "2種低層",
    strokeColor: "#B1DDDA",
    fillColor: "#B1DDDA"
  },
  {
    code: 3,
    name: "1種中高",
    strokeColor: "#A3CF76",
    fillColor: "#A3CF76"
  },
  {
    code: 4,
    name: "2種中高",
    strokeColor: "#E4EFD5",
    fillColor: "#E4EFD5"
  },
  {
    code: 5,
    name: "1種住居",
    strokeColor: "#FEF89B",
    fillColor: "#FEF89B"
  },
  {
    code: 6,
    name: "2種住居",
    strokeColor: "#FCD1BF",
    fillColor: "#FCD1BF"
  },
  { code: 7, name: "準住居", strokeColor: "#F9AA8F", fillColor: "#F9AA8F" },
  {
    code: 8,
    name: "近隣商業",
    strokeColor: "#F6C2D9",
    fillColor: "#F6C2D9"
  },
  { code: 9, name: "商業", strokeColor: "#F2879A", fillColor: "#F2879A" },
  {
    code: 10,
    name: "準工業",
    strokeColor: "#AEACD4",
    fillColor: "#AEACD4"
  },
  { code: 11, name: "工業", strokeColor: "#ABDFF9", fillColor: "#ABDFF9" },
  {
    code: 12,
    name: "工業専用",
    strokeColor: "#61A8CD",
    fillColor: "#61A8CD"
  },
  {
    code: 21,
    name: "田園住居",
    strokeColor: "#2FA56C",
    fillColor: "#AED0B9"
  },
  { code: 99, name: "不明", strokeColor: "#CCCCCC", fillColor: "#CCCCCC" }
];
