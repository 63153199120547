const shadowWidth = "1px";
const shadowColor = "#FFF";

export class LengthView extends google.maps.OverlayView {
  private from: google.maps.LatLng;
  private to: google.maps.LatLng;
  private div?: HTMLElement;

  constructor(from: google.maps.LatLng, to: google.maps.LatLng) {
    super();
    this.from = from;
    this.to = to;
  }

  onAdd() {
    try {
      const actualLength =
        Math.floor(
          google.maps.geometry.spherical.computeDistanceBetween(
            this.from,
            this.to
          ) * 100
        ) / 100;

      this.div = document.createElement("div");
      this.div.style.borderStyle = "none";
      this.div.style.borderWidth = "0px";
      this.div.style.position = "absolute";
      this.div.style.backgroundColor = "transparent";
      this.div.style.fontSize = "100%";
      this.div.style.fontFamily =
        "din-2014, zen-maru-gothic, sans-serif";
      this.div.style.opacity = "0.8";
      this.div.style.height = "2em";

      this.div.style.color = "#F00";
      this.div.style.textShadow = `${shadowWidth} ${shadowWidth} 0 ${shadowColor},
        -${shadowWidth} -${shadowWidth} 0 ${shadowColor},
        -${shadowWidth} ${shadowWidth} 0 ${shadowColor},
        ${shadowWidth} -${shadowWidth} 0 ${shadowColor},
        0 ${shadowWidth} 0 ${shadowColor},
        0 -${shadowWidth} 0 ${shadowColor},
        -${shadowWidth} 0 0 ${shadowColor},
        ${shadowWidth} 0 0 ${shadowColor}`;

      this.div.style.textAlign = "center";
      this.div.style.wordBreak = "keep-all";

      this.div.style.zIndex = String(
        Number(google.maps.Marker.MAX_ZINDEX) + 10000
      );

      this.div.innerText = `${actualLength}ｍ`;

      const panes = this.getPanes()!;

      panes.markerLayer.appendChild(this.div);
    } catch (error) {}
  }

  draw() {
    const overlayProjection = this.getProjection();
    const pixelFrom = overlayProjection.fromLatLngToDivPixel(this.from);
    const pixelTo = overlayProjection.fromLatLngToDivPixel(this.to);

    if (pixelFrom && pixelTo) {
      const pixelLength = Math.sqrt(
        (pixelTo.x - pixelFrom.x) ** 2 + (pixelTo.y - pixelFrom.y) ** 2
      );

      if (this.div) {
        const degree = google.maps.geometry.spherical.computeHeading(
          this.from,
          this.to
        );

        if (degree < 0) {
          this.div.style.left = `${pixelTo.x || 0}px`;
          this.div.style.top = `${pixelTo.y || 0}px`;
          this.div.style.transformOrigin = "left top";
          this.div.style.transform = `rotate(${degree + 90}deg)`;
        } else {
          this.div.style.left = `${pixelFrom.x || 0}px`;
          this.div.style.top = `calc(${pixelFrom.y || 0}px - 2em)`;
          this.div.style.transformOrigin = "left bottom";
          this.div.style.transform = `rotate(${degree - 90}deg)`;
        }

        this.div.style.width = `${pixelLength || 0}px`;
      }
    }
  }

  onRemove() {
    if (this.div) {
      (this.div.parentNode as HTMLElement).removeChild(this.div);
      delete this.div;
    }
  }

  hide() {
    if (this.div) {
      this.div.style.visibility = "hidden";
    }
  }

  show() {
    if (this.div) {
      this.div.style.visibility = "visible";
    }
  }

  toggle() {
    if (this.div) {
      if (this.div.style.visibility === "hidden") {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  toggleDOM(map: google.maps.Map) {
    if (this.getMap()) {
      this.setMap(null);
    } else {
      this.setMap(map);
    }
  }

  PRESERVE_COMMENT_ABOVE = 1;
}
