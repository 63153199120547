import { HazardMapType } from "common/functions/classes/HazardMapType";
import { DrawingId } from "common/types/DrawingId";
import { useCallback, useContext, useEffect, useState } from "react";
import { SearchMapContext } from "../../context";

const id: DrawingId = "tsunami";

export const useHooks = (on: boolean) => {
  const { map } = useContext(SearchMapContext);
  const [tsunamiMapType, setTsunamiMapType] = useState<
    HazardMapType | undefined
  >(undefined);

  // マップタイプ構築
  const constructTsunamiMapType = useCallback(() => {
    if (map) {
      const mapType = new HazardMapType(
        `${id}MapType`,
        map,
        [
          "https://disaportaldata.gsi.go.jp/raster/04_tsunami_newlegend_data/{z}/{x}/{y}.png"
        ],
        17,
        0.5
      );

      setTsunamiMapType(mapType);
    }
  }, [map]);

  // 描画
  const drawTsunami = useCallback(async () => {
    if (map && tsunamiMapType) {
      for (let i = 0; i < map.overlayMapTypes.getLength(); i++) {
        const mapType = map.overlayMapTypes.getAt(
          i
        ) as unknown as HazardMapType;

        if (mapType.id === `${id}MapType`) {
          map.overlayMapTypes.removeAt(i);
          break;
        }
      }

      if (on) {
        map.overlayMapTypes.insertAt(
          0,
          tsunamiMapType as unknown as google.maps.MapType
        );
      }
    }
  }, [tsunamiMapType, map, on]);

  useEffect(() => {
    constructTsunamiMapType();
  }, [constructTsunamiMapType]);

  useEffect(() => {
    drawTsunami();
  }, [drawTsunami]);

  return { map } as const;
};
