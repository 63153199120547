import { useTheme } from "@mui/material";
import {
  boundSearchableZoom,
  meshDisplayableZoom
} from "common/consts/estates";
import { stickyNoteSearchableZoom } from "common/consts/stickyNotes";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { GroupEnum } from "common/enums/GroupEnum";
import { MenuTabValueEnum } from "common/enums/MenuTabValueEnum";
import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { subscribeEstates } from "common/functions/api/estates";
import { handleError, useQuery } from "common/functions/utilities";
import {
  EstateFilterConditions,
  initialEstateFilterConditions
} from "common/interfaces/EstateFilterConditions";
import { MinimalEstate } from "common/queries/minimalEstates";
import { MainContextContainer } from "components/Main";
import { useCommonPageHandlers } from "hooks/common/handlers";
import { addMenuCloseHelper } from "hooks/common/helpers";
import { useCommonPageStates } from "hooks/common/states";
import { cloneDeep, debounce } from "lodash";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  initialRouteLocations,
  menuButtonId,
  menuDialogId,
  searchMapDivId
} from "../consts";
import { SearchProps } from "../interfaces";
import { useSearchBehaviorHandlers } from "./handlers/behaviors";
import { useSearchEstateFilterDialogHandlers } from "./handlers/estateFilterDialogs";
import { useSearchEstateLocationInputDialogHandlers } from "./handlers/estateLocationInputDialogs";
import { useSearchMapHandlers } from "./handlers/maps";
import { useSearchModalHandlers } from "./handlers/modals";
import { useSearchMoveHandlers } from "./handlers/moves";
import { useSearchReportInputDialogHandlers } from "./handlers/reportInputDialogs";
import { useSearchSearchRouteDialogHandlers } from "./handlers/searchRouteDialogs";
import { useSearchSelectDrawingDialogHandlers } from "./handlers/selectDrawingDialogs";
import { useSearchStickyNoteInputDialogHandlers } from "./handlers/stickyNoteInputDialogs";
import { useSearchSwitchAreaDialogHandlers } from "./handlers/switchAreaDialogs";
import { useSearchToolHandlers } from "./handlers/tools";
import { useSearchUsageGuideDialogHandlers } from "./handlers/usageGuideDialogs";
import { useSearchBehaviorMethods } from "./methods/behaviors";
import { useSearchCounterMethods } from "./methods/counters";
import { useSearchEstateMethods } from "./methods/estates";
import { useSearchFilterMethods } from "./methods/filters";
import { useSearchMasterMethods } from "./methods/masters";
import { useSearchSchoolAreaMethods } from "./methods/schoolAreas";
import { useSoldMethods } from "./methods/sold";
import { useSearchStickyNoteMethods } from "./methods/stickyNotes";
import { useSearchBehaviorStates } from "./states/behaviors";
import { useSearchDialogStates } from "./states/dialogs";
import { useSearchEstateSearchConditionStates } from "./states/estateSearchConditions";
import { useSearchEstateStates } from "./states/estates";
import { useSearchFilterConditionStates } from "./states/filterConditions";
import { useSearchMapStates } from "./states/maps";
import { useSearchMasterStates } from "./states/masters";
import { useSearchEstateSearchStates } from "./states/searches";
import { useSearchStickyNoteSearchConditionStates } from "./states/stickyNoteSearchConditions";
import { useSearchStickyNoteStates } from "./states/stickyNotes";

export const useSearchHooks = (props: SearchProps) => {
  const initRef = useRef(true);
  const initDefaultPrefecturesRef = useRef(true);
  const initDefaultCitiesRef = useRef(true);

  const setDefaultCenterRef = useRef(false);
  const initialSearchEstateRef = useRef(false);

  const theme = useTheme();

  const params = useQuery();
  const own = params.get("own") !== null;

  const mainContext = useContext(MainContextContainer);

  const { menuOpen, setMenuOpen, tabValue, setTabValue } =
    useCommonPageStates();
  const commonPageHandlers = useCommonPageHandlers(setMenuOpen, setTabValue);

  // filterConditionsの初期値
  const [defaultFilterConditions, setDefaultFilterConditions] =
    useState<EstateFilterConditions>(initialEstateFilterConditions);

  //#region States
  const behaviorStates = useSearchBehaviorStates();
  const dialogStates = useSearchDialogStates();
  const estateStates = useSearchEstateStates();
  const stickyNoteStates = useSearchStickyNoteStates();
  const mapStates = useSearchMapStates(mainContext);
  const estateSearchConditionStates = useSearchEstateSearchConditionStates();
  const stickyNoteSearchConditionStates =
    useSearchStickyNoteSearchConditionStates();
  const masterStates = useSearchMasterStates(
    mainContext,
    estateSearchConditionStates.estateSearchConditions.prefs
  );
  const filterConditionStates = useSearchFilterConditionStates();
  const estateSearchStates = useSearchEstateSearchStates();
  //#endregion

  //#region Methods
  const behaviorMethods = useSearchBehaviorMethods(mainContext, mapStates);
  const counterMethods = useSearchCounterMethods(mainContext);
  const filterMethods = useSearchFilterMethods(mainContext);
  const soldMethods = useSoldMethods(mainContext);
  const searchEstateMethods = useSearchEstateMethods(
    mainContext,
    behaviorStates,
    estateStates,
    mapStates,
    estateSearchConditionStates,
    estateSearchStates,
    counterMethods
  );
  const searchStickyNoteMethods = useSearchStickyNoteMethods(
    mainContext,
    behaviorStates,
    mapStates,
    stickyNoteStates,
    stickyNoteSearchConditionStates
  );
  const masterMethods = useSearchMasterMethods(
    mainContext,
    masterStates,
    behaviorStates,
    estateSearchConditionStates
  );
  const schoolAreaMethods = useSearchSchoolAreaMethods(mainContext, mapStates);
  // const drawingMethods = useDrawingMethods(mapStates, searchConditionStates);
  //#endregion

  //#region Handlers
  const behaviorHandlers = useSearchBehaviorHandlers(dialogStates);
  const estateLocationInputDialogHandlers =
    useSearchEstateLocationInputDialogHandlers(
      props,
      dialogStates,
      estateStates,
      estateSearchConditionStates
    );
  const reportInputDialogHandlers =
    useSearchReportInputDialogHandlers(dialogStates);
  const stickyNoteInputDialogHandlers = useSearchStickyNoteInputDialogHandlers(
    dialogStates,
    stickyNoteStates
  );
  const switchAreaDialogHandlers = useSearchSwitchAreaDialogHandlers(
    props,
    dialogStates,
    estateSearchConditionStates,
    masterMethods,
    searchEstateMethods
  );
  const estateFilterDialogHandlers = useSearchEstateFilterDialogHandlers(
    behaviorStates,
    dialogStates,
    filterConditionStates
  );
  const mapHandlers = useSearchMapHandlers(
    mainContext,
    behaviorStates,
    dialogStates,
    mapStates,
    estateStates
  );
  const modalHandlers = useSearchModalHandlers(estateSearchStates);
  const moveHandlers = useSearchMoveHandlers(mapStates, setMenuOpen);
  const selectDrawingDialogHandlers = useSearchSelectDrawingDialogHandlers(
    dialogStates,
    mapStates
  );
  const toolHandlers = useSearchToolHandlers(
    props,
    mapStates,
    masterStates,
    estateSearchConditionStates,
    filterConditionStates,
    dialogStates,
    estateStates,
    searchEstateMethods,
    behaviorStates,
    setMenuOpen
  );
  const usageGuideDialogHandlers = useSearchUsageGuideDialogHandlers(mapStates);
  const searchRouteDialogHandlers = useSearchSearchRouteDialogHandlers(
    dialogStates,
    mapStates
  );
  //#endregion

  //#region Event hooks

  // 初回
  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;

      const getSize = (ev?: UIEvent) => {
        const mapDiv = document.getElementById(searchMapDivId);
        const size = mapDiv?.getClientRects();

        if (size) {
          behaviorStates.setMapDivSize({
            width: size[0].width,
            height: size[0].height
          });
        }
      };

      window.addEventListener("resize", getSize);

      getSize();

      setTabValue(MenuTabValueEnum.Search);

      return () => {
        params.delete("news");
        params.delete("own");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // マスタと下書きモードの場合は編集モードがデフォルト
  useEffect(() => {
    if (
      [SearchModeEnum.condominium, SearchModeEnum.draft].includes(
        props.searchMode
      )
    ) {
      behaviorStates.setEditMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchMode]);

  // メインコンテクストの会社特定時
  useEffect(() => {
    if (own && mainContext.company?.recNo) {
      filterConditionStates.setFilterConditions.companyRecNo(
        mainContext.company.recNo
      );

      setDefaultFilterConditions((value) => ({
        ...value,
        companyRecNo: mainContext.company?.recNo || 0
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.company]);

  // 会社の位置読み込み完了時、マップ読み込み完了時
  useEffect(() => {
    if (!setDefaultCenterRef.current) {
      setDefaultCenterRef.current = behaviorMethods.setDefaultCenter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mainContext.company?.latitude,
    mainContext.company?.longitude,
    mapStates.map
  ]);

  // 物件種別マスタロード完了時
  useEffect(() => {
    if (masterStates.estateTypes.length !== 0) {
      // 業者マスタの初期表示物件種別がある場合はそれを初期表示とする
      // 初期表示では事業用を除く
      const initialEstateTypes = masterStates.estateTypes.filter(
        (v) =>
          v.name &&
          ((mainContext.company?.estateTypes ?? []).length === 0 ||
            (mainContext.company?.estateTypes ?? []).includes(v.name)) &&
          ((mainContext.company?.estateTypes ?? []).includes(
            EstateTypeNameEnum.事業用
          ) ||
            v.name !== EstateTypeNameEnum.事業用)
      );

      filterConditionStates.setFilterConditions.estateTypes(initialEstateTypes);

      setDefaultFilterConditions((value) => ({
        ...value,
        estateTypes: initialEstateTypes
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterStates.estateTypes, mainContext.company?.estateTypes]);

  // 物件ステータスマスタロード完了時
  useEffect(() => {
    masterMethods.setDefaultStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterStates.statuses]);

  // 建築条件マスタロード完了時、会社の建築条件読み込み完了時
  useEffect(() => {
    filterConditionStates.setFilterConditions.bldConditionSelections(
      masterStates.bldConditionSelections
    );

    if (
      mainContext.company?.bldConditionSelections &&
      mainContext.company.bldConditionSelections.length > 0
    ) {
      const bldConditionSelections = masterStates.bldConditionSelections.filter(
        (bldConditionSelection) =>
          mainContext.company?.bldConditionSelections?.find(
            (x) => x === bldConditionSelection.name
          ) !== undefined
      );

      filterConditionStates.setFilterConditions.bldConditionSelections(
        bldConditionSelections
      );

      setDefaultFilterConditions((value) => ({
        ...value,
        bldConditionSelections
      }));
    } else {
      setDefaultFilterConditions((value) => ({
        ...value,
        bldConditionSelections: masterStates.bldConditionSelections
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    masterStates.bldConditionSelections,
    mainContext.company?.bldConditionSelections
  ]);

  // 種目概要(事業用)マスタロード完了時
  useEffect(() => {
    filterConditionStates.setFilterConditions.purposeSummaryBs(
      masterStates.purposeSummaryBs
    );

    setDefaultFilterConditions((value) => ({
      ...value,
      purposeSummaryBs: masterStates.purposeSummaryBs
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterStates.purposeSummaryBs]);

  // 都道府県マスタロード完了時、会社の担当エリア読み込み完了時
  useEffect(() => {
    if (
      masterMethods.setDefaultPrefectures(initDefaultPrefecturesRef.current)
    ) {
      initDefaultPrefecturesRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterStates.prefectures, mainContext.company?.areas]);

  // 市区町村マスタロード完了時、会社の担当エリア読み込み完了時
  useEffect(() => {
    if (masterMethods.setDefaultCities(initDefaultCitiesRef.current)) {
      initDefaultCitiesRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterStates.cities, mainContext.company?.areas]);

  // 初回検索が可能になった時
  useEffect(() => {
    if (!initialSearchEstateRef.current) {
      if (mapStates.map && behaviorStates.readyToInitialSearch) {
        setTimeout(() => {
          searchEstateMethods.searchEstate(
            props.searchMode,
            estateSearchConditionStates.estateSearchConditions,
            true,
            false,
            false
          );

          initialSearchEstateRef.current = true;
        }, 500);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchMode, mapStates.map, behaviorStates.readyToInitialSearch]);

  // 範囲検索がONになった時
  useEffect(() => {
    if (
      mapStates.map &&
      estateSearchConditionStates.estateSearchConditions.bound &&
      mapStates.zoom < boundSearchableZoom
    ) {
      mapStates.setZoom(boundSearchableZoom);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStates.map, estateSearchConditionStates.estateSearchConditions.bound]);

  // メッシュ表示がONになった時
  useEffect(() => {
    if (
      mapStates.map &&
      behaviorStates.soldMode &&
      mapStates.zoom < meshDisplayableZoom
    ) {
      mapStates.setZoom(meshDisplayableZoom);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStates.map, behaviorStates.soldMode]);

  // メモ表示がONになった時
  useEffect(() => {
    if (
      mapStates.map &&
      behaviorStates.stickyNoteMode &&
      mapStates.zoom < stickyNoteSearchableZoom
    ) {
      mapStates.setZoom(stickyNoteSearchableZoom);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStates.map, behaviorStates.stickyNoteMode]);

  // Zoom値等変更時(物件検索)
  useEffect(() => {
    if (mapStates.map) {
      if (estateSearchConditionStates.estateSearchConditions.bound) {
        searchEstateMethods.boundSearch(props.searchMode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.searchMode,
    mapStates.map,
    mapStates.zoom,
    mapStates.center,
    estateSearchConditionStates.estateSearchConditions.bound
  ]);

  // Zoom値等変更時(メモ検索)
  useEffect(() => {
    if (mapStates.map) {
      searchStickyNoteMethods.searchStickyNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapStates.map,
    mapStates.zoom,
    mapStates.center,
    behaviorStates.stickyNoteMode
  ]);

  // エリア変更時
  useEffect(() => {
    toolHandlers.onDrawingESchool();
    toolHandlers.onDrawingJSchool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estateSearchConditionStates.estateSearchConditions.cities]);

  // 描画対象学校の市区町村変更時
  useEffect(() => {
    schoolAreaMethods.getSchoolAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStates.selectedESchoolCities, mapStates.selectedJSchoolCities]);

  // 物件リスト変更時、フィルタ条件変更時
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterEstatesFunc = useCallback(
    debounce(
      (
        estates: MinimalEstate[] | undefined,
        filterConditions: EstateFilterConditions,
        closedMode: boolean,
        soldMode: boolean,
        searchMode: SearchModeEnum,
        myCompanyId: string | null | undefined
      ) => {
        const forFilteredEstates = cloneDeep(estates);

        const {
          filteredPublicEstates,
          filteredPublicEstatesCount,
          filteredClosedEstates,
          filteredClosedEstatesCount
        } = filterMethods.getFilteredEstates(
          forFilteredEstates as MinimalEstate[],
          filterConditions,
          myCompanyId
        );

        estateStates.setFilteredPublicEstates(filteredPublicEstates);
        estateStates.setFilteredPublicEstatesCount(filteredPublicEstatesCount);

        estateStates.setFilteredClosedEstates(
          closedMode || searchMode === SearchModeEnum.draft
            ? filteredClosedEstates
            : []
        );
        estateStates.setFilteredClosedEstatesCount(
          closedMode || searchMode === SearchModeEnum.draft
            ? filteredClosedEstatesCount
            : 0
        );

        if (soldMode) {
          const forSoldEstates = cloneDeep(estates);

          const { soldEstates, soldEstatesCount } = soldMethods.getSoldEstates(
            forSoldEstates as MinimalEstate[],
            filterConditions
          );

          estateStates.setSoldEstates(soldEstates);
          estateStates.setSoldEstatesCount(soldEstatesCount);
        } else {
          estateStates.setSoldEstates([]);
          estateStates.setSoldEstatesCount(0);
        }
      },
      500
    ) as (
      estates: MinimalEstate[] | undefined,
      filterConditions: EstateFilterConditions,
      closedMode: boolean,
      soldMode: boolean,
      searchMode: SearchModeEnum,
      myCompanyId: string | null | undefined
    ) => void,
    []
  );

  useEffect(
    () =>
      filterEstatesFunc(
        estateStates.estates,
        filterConditionStates.filterConditions,
        behaviorStates.closedMode,
        behaviorStates.soldMode,
        props.searchMode,
        mainContext.company?.id
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      estateStates.estates,
      filterConditionStates.filterConditions,
      behaviorStates.saleMode,
      behaviorStates.closedMode,
      behaviorStates.soldMode,
      props.searchMode,
      mainContext.company?.id
    ]
  );

  // 物件フィルタ条件ダイアログ表示状態変更時、物件フィルタ条件ダイアログアクティブ時
  useEffect(() => {
    estateFilterDialogHandlers.onEstateFilterDialogSignChange(
      dialogStates.showEstateFilterDialog
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dialogStates.showEstateFilterDialog,
    dialogStates.estateFilterDialogSign
  ]);

  // 小学校区描画指示変更時
  useEffect(() => {
    toolHandlers.onDrawingESchool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStates.dspESchools]);

  // 中学校区描画指示変更時
  useEffect(() => {
    toolHandlers.onDrawingJSchool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStates.dspJSchools]);

  // 学校区ダイアログ表示状態変更時、学校区ダイアログアクティブ時
  useEffect(() => {
    selectDrawingDialogHandlers.onSelectDrawingSchoolDialogSignChange(
      dialogStates.showSelectDrawingSchoolDialog
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dialogStates.showSelectDrawingSchoolDialog,
    dialogStates.selectDrawingSchoolDialogSign
  ]);

  // 小中学校区描画ON、範囲検索ON時の自動市区町村取得
  useEffect(() => {
    toolHandlers.onBoundsChangeOnDrawingSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapStates.dspESchools,
    mapStates.dspJSchools,
    estateSearchConditionStates.estateSearchConditions.bound,
    mapStates.zoom,
    mapStates.center
  ]);

  // ダイアログのレイヤー変更時
  useEffect(() => {
    behaviorHandlers.onLayersChange(dialogStates.layers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogStates.layers]);

  // 路線検索モード変化時
  useEffect(() => {
    if (mapStates.searchRouteMode) {
      if (mapStates.measureLengthMode) {
        mapStates.setMeasureLengthMode(false);
      }

      if (mapStates.measureAreaMode) {
        mapStates.setMeasureAreaMode(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStates.searchRouteMode]);

  // 距離計測モード変化時
  useEffect(() => {
    if (mapStates.measureLengthMode) {
      if (mapStates.measureAreaMode) {
        mapStates.setMeasureAreaMode(false);
      }

      if (mapStates.searchRouteMode) {
        mapStates.setRouteLocations(initialRouteLocations);
        mapStates.setRouteLocationsResult(null);
        mapStates.setTravelMode(null);
        mapStates.setSearchRouteMode(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStates.measureLengthMode]);

  // 面積計測モード変化時
  useEffect(() => {
    if (mapStates.measureAreaMode) {
      if (mapStates.measureLengthMode) {
        mapStates.setMeasureLengthMode(false);
      }

      if (mapStates.searchRouteMode) {
        mapStates.setRouteLocations(initialRouteLocations);
        mapStates.setRouteLocationsResult(null);
        mapStates.setTravelMode(null);
        mapStates.setSearchRouteMode(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStates.measureAreaMode]);

  // 物件ステータスモード、ズーム変化時
  useEffect(() => {
    toolHandlers.onStatusModesChange(
      behaviorStates.saleMode,
      behaviorStates.closedMode,
      behaviorStates.soldMode && mapStates.zoom >= meshDisplayableZoom
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    behaviorStates.saleMode,
    behaviorStates.closedMode,
    behaviorStates.soldMode,
    mapStates.zoom
  ]);

  useEffect(() => {
    if (
      mainContext.groups?.includes(GroupEnum.Administrator) &&
      estateStates.estateSubscriber.current === undefined
    ) {
      subscribeEstates(mapHandlers.onSubscribeEstate)
        .then(
          (subscriber) => (estateStates.estateSubscriber.current = subscriber)
        )
        .catch((error) => handleError(error, mainContext.setFatal));

      return () => {
        if (estateStates.estateSubscriber.current) {
          estateStates.estateSubscriber.current?.unsubscribe();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.groups]);

  //#endregion

  addMenuCloseHelper(menuButtonId, menuDialogId, setMenuOpen);

  return {
    mainContext,
    theme,
    menuOpen,
    setMenuOpen,
    tabValue,
    behaviorStates,
    mapStates,
    masterStates,
    estateStates,
    stickyNoteStates,
    estateSearchConditionStates,
    filterConditionStates,
    dialogStates,
    commonPageHandlers,
    estateLocationInputDialogHandlers,
    reportInputDialogHandlers,
    stickyNoteInputDialogHandlers,
    switchAreaDialogHandlers,
    estateFilterDialogHandlers,
    mapHandlers,
    moveHandlers,
    toolHandlers,
    modalHandlers,
    usageGuideDialogHandlers,
    searchRouteDialogHandlers,
    searchEstateMethods,
    defaultFilterConditions
  } as const;
};
