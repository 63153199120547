import { ExpandMore } from "@mui/icons-material";
import { Button, CardContent, Grid2, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { doshasaigaiColors } from "common/consts/doshasaigaiColors";
import { shinsuiColors } from "common/consts/shinsuiColors";
import { youtoColors } from "common/consts/youtoColors";
import { DrawingColor } from "common/interfaces/DrawingColor";
import { BoxShadowCard } from "components/Parts/Atoms/BoxShadowCard";
import React, { TouchEventHandler, useMemo } from "react";
import Draggable from "react-draggable";
import styles from "./UsageGuideDialog.module.scss";
import { Caption } from "./components";
import { useUsageGuideDialogHooks } from "./hooks";
import { UsageGuideDialogProps } from "./interfaces";

export const UsageGuideDialog = React.memo((props: UsageGuideDialogProps) => {
  const { mainRef, expand, setExpand } = useUsageGuideDialogHooks();

  const { doshasaigaiChart, shinsuiChart, youtoChart } = useMemo(() => {
    const caption = (title: string) => (
      <React.Fragment key={title}>
        <Grid2 size={12}>
          <Caption>
            <strong>{title}</strong>
          </Caption>
        </Grid2>
      </React.Fragment>
    );

    const row = (drawingColor: DrawingColor) => (
      <React.Fragment key={drawingColor.code}>
        <Grid2 size={0.5}></Grid2>
        <Grid2 size={9.5}>
          <Caption>{drawingColor.name}</Caption>
        </Grid2>
        <Grid2 size={2}>
          <Box
            className={styles.row}
            sx={{
              borderColor: drawingColor.strokeColor,
              backgroundColor: `${drawingColor.fillColor}4C`
            }}
          />
        </Grid2>
      </React.Fragment>
    );

    const doshasaigaiChart: JSX.Element[] = [];

    if (props.dspDoshasaigai) {
      doshasaigaiChart.push(caption("土砂災害警戒区域"));
      doshasaigaiChart.push(...doshasaigaiColors.map((x) => row(x)));
    }

    const shinsuiChart: JSX.Element[] = [];

    if (props.dspKouzui || props.dspTakashio || props.dspTsunami) {
      shinsuiChart.push(caption("浸水想定区域"));
      shinsuiChart.push(...shinsuiColors.map((x) => row(x)));
    }

    const youtoChart: JSX.Element[] = [];

    if (props.dspYouto) {
      youtoChart.push(caption("用途地域"));
      youtoChart.push(...youtoColors.map((x) => row(x)));
    }
    return {
      doshasaigaiChart,
      shinsuiChart,
      youtoChart
    };
  }, [
    props.dspDoshasaigai,
    props.dspKouzui,
    props.dspTakashio,
    props.dspTsunami,
    props.dspYouto
  ]);

  if (props.show) {
    return (
      <Draggable
        nodeRef={mainRef}
        handle=".draggable-div"
        onMouseDown={props.onMouseDown}
      >
        <Box ref={mainRef} className={styles.cardWrapper}>
          <BoxShadowCard
            className={`${styles.usageGuideDialog} ${
              expand ? styles.expand : ""
            }`}
          >
            <CardContent className={styles.contentWrapper}>
              <Box
                textAlign="right"
                className={`draggable-div ${styles.content}`}
              >
                <Button
                  className={styles.onOffButton}
                  size="small"
                  variant="contained"
                  onClick={props.onOffClick}
                  onTouchStart={
                    props.onOffClick as TouchEventHandler<HTMLButtonElement>
                  }
                >
                  表示OFF
                </Button>
                <IconButton
                  size="small"
                  color="inherit"
                  className={styles.expandButton}
                  onClick={() => setExpand((x) => !x)}
                  onTouchStart={() => setExpand((x) => !x)}
                >
                  <ExpandMore
                    className={`${styles.expandIcon} ${
                      expand ? styles.expand : ""
                    }`}
                  />
                </IconButton>
              </Box>
              <Box
                className={`${styles.chartWrapper} ${
                  expand ? styles.expand : ""
                }`}
              >
                <Grid2
                  container
                  rowSpacing={0.5}
                  columnSpacing={0.2}
                  className={styles.chart}
                >
                  {doshasaigaiChart}
                  {shinsuiChart}
                  {youtoChart}
                </Grid2>
              </Box>
            </CardContent>
          </BoxShadowCard>
        </Box>
      </Draggable>
    );
  } else {
    return null;
  }
});
