import { Box, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { applyJapanese } from "common/functions/utilities";
import { Main } from "components/Main";
import { ja } from "date-fns/locale/ja";
import styles from "./App.module.scss";
import { globalSx, globalTheme } from "./themes";
export const App = () => {
  const appTheme = createTheme(globalTheme);

  return (
    <ThemeProvider theme={appTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <Box className={styles.app} sx={globalSx}>
          <Main />
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

applyJapanese();
